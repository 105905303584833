import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';

import {BehaviorSubject, EMPTY, map, merge, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {AntragTicketDTO} from "../../../models/antrag-ticket/AntragTicketDTO";
import {AntragTicketUpdateDTO} from "../../../models/antrag-ticket/AntragTicketUpdateDTO";
import {AntragTicketCreateDTO} from "../../../models/antrag-ticket/AntragTicketCreateDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";
import {AntragTicketMinimalDTO} from "../../../models/antrag-ticket/AntragTicketMinimalDTO";
import {catchError, takeUntil} from "rxjs/operators";
import {CustomToastService} from "../../utils/custom-toast.service";
import {AntragAttachmentAdjustDto} from "../../../models/antrag-ticket/AntragAttachmentAdjustDto";
import {AntragAttachmentCreateDto} from "../../../models/antrag-ticket/AntragAttachmentCreateDto";

@Injectable({
  providedIn: 'root'
})
export class AntragTicketService {

  configUrl = 'api/antrag-ticket';

  private antragObs$$: BehaviorSubject<AntragTicketDTO> = new BehaviorSubject(null);
  public antragObs$: Observable<AntragTicketDTO> = this.antragObs$$.asObservable();

  public setAntragID$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService,
    private customToastService: CustomToastService
  ) {
    this.setAntragID$.pipe(
      takeUntil(this.destroy$),
      switchMap((val) =>
            merge(
              this.loadAntrag(val).pipe(
                tap((antrag) => this.antragObs$$.next(antrag))
              )
            )
      )
    ).subscribe();
  }

  getAllAntraegeMinimal(): Observable<Array<AntragTicketMinimalDTO>> {
    return this.http.get<Array<AntragTicketMinimalDTO>>(
      this.configUrl+'/bulk-minimal',
      { headers: this.headerService.getAuthHeaderApplicationJson()}
    );
  }

  private loadAntrag(id: string): Observable<AntragTicketDTO> {
    if(!id){
      return of(null);
    }
    return this.http.get<AntragTicketDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  updateAntrag(id: number, dto: AntragTicketUpdateDTO): Observable<any> {
    let formData = new FormData();
    formData.append('dto', new Blob([JSON.stringify(dto)], { type: 'application/json'}));
    return this.http.patch<AntragTicketDTO>(
      this.configUrl+'/'+id,
      formData,
      { headers: this.headerService.getAuthHeader() }
    )
      .pipe( map(antrag => {
        this.setAntragID$.next(antrag.id.toString());
        return antrag;
      }));
  }

  createAntrag(dto: AntragTicketCreateDTO): Observable<any> {
    let formData = new FormData();
    formData.append('dto', new Blob([JSON.stringify(dto)], { type: 'application/json'}));
    return this.http.post<AntragTicketDTO>(
      this.configUrl,
      formData,
      { headers: this.headerService.getAuthHeader() }
    )
      .pipe(map(antrag => {
        this.setAntragID$.next(antrag.id.toString());
        return antrag;
      }));
  }

  deleteAntrag(id: number): Observable<any> {
    return this.http.delete(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeader(), responseType: 'text' }
    );
  }

  getVorgaenger(schuelerId: number): Observable<any> {
    if (schuelerId !== null) {
      return this.http.get<AntragTicketDTO>(
        this.configUrl+'/vorgaenger?schueler-id='+schuelerId,
        { headers: this.headerService.getAuthHeaderApplicationJson() }
      );
    } else {
      return this.http.get<AntragTicketDTO>(
        this.configUrl+'/vorgaenger',
        { headers: this.headerService.getAuthHeaderApplicationJson() }
      );
    }
  }

  downloadAttachment(antragUuid: string, attachmentUuid: string) {
    let downloadFileName = "";
    this.http.get<Blob>(this.configUrl+'/'+antragUuid+'/download-attachment/'+attachmentUuid, {headers: this.headerService.getAuthHeader(), observe: 'response', responseType: 'blob' as 'json'})
      .pipe(
        catchError(()  => {
          this.customToastService.showError("Der Anhang konnte nicht heruntergeladen werden.");
          return EMPTY;
        })
      )
      .subscribe(response => {
        let contentDisposition = response.headers.get("Content-Disposition")
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            downloadFileName = matches[1].replace(/['"]/g, '');
          }
        }
        let blob = (response as HttpResponse<Blob>).body as Blob;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = downloadFileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
      });
  }

  removeAttachment(antragUuid: string, attachmentUuid: string): void {
    this.http.post<AntragTicketDTO>(this.configUrl+'/'+antragUuid+'/removeAttachment/'+attachmentUuid, null, {headers: this.headerService.getAuthHeader()})
      .subscribe(antrag => {
        this.setAntragID$.next(antrag.id.toString());
      })
  }

  addAttachment(antragUuid: string,  file: File, dto: AntragAttachmentCreateDto): void{
    let formData = new FormData();
    formData.append('file',file);
    formData.append('dto', new Blob([JSON.stringify(dto)], {type: "application/json"}));
    this.http.post<AntragTicketDTO>(this.configUrl+'/'+antragUuid+'/addAttachment', formData, {headers: this.headerService.getAuthHeader()})
      .subscribe(antrag => {
        this.setAntragID$.next(antrag.id.toString());
      })
  }

  adjustAttachmentType(antragUuid: string, dto: AntragAttachmentAdjustDto): void{
    this.http.post<AntragTicketDTO>(this.configUrl+'/'+antragUuid+'/adjustAttachment', dto, {headers: this.headerService.getAuthHeader()})
      .subscribe(antrag => {
        this.setAntragID$.next(antrag.id.toString());
      })
  }

}
