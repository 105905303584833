@if (antragTicket?.attachments?.length > 0) {
  @for (attachment of filter(antragTicket?.attachments); track attachment.attachmentId) {
    <div class="card mt-1">
      <div class="card-body p-2">
        <div class="card-header d-flex justify-content-center">
            <span class="badge badge-pill " [ngClass]="getBadgeClass(attachment.attachmentType)">
                                  {{ attachment.attachmentType }}
                                  </span>
        </div>
        <div class="d-flex align-items-center mt-1">
          <div id="title" class="card-title mb-0">
            <h6 class="mb-0">
              {{ attachment.fileName }}
            </h6>
          </div>
          <div class="ml-auto" style="margin-left: auto !important">
            <button class="btn btn-primary mx-1" style="max-width:100px"
                    (click)="downloadAttachment(attachment.attachmentId, attachment.antragId)">
              <fa-icon
                class="fa btn-mini-square-icon"
                [icon]="faDownload"
              ></fa-icon>
            </button>
            @if(appendeable){
            <button class="btn btn-primary mx-1" style="max-width:100px"
                    (click)="adjustType(attachment.attachmentId)">
              <fa-icon
                class="fa btn-mini-square-icon"
                [icon]="faEdit"
              ></fa-icon>

            </button>
            }
            @if (deleteable) {
              <button class="btn btn-primary mx-1" style="max-width:100px"
                      (click)="removeAttachment(attachment.attachmentId, attachment.antragId)">
                <fa-icon
                  class="fa btn-mini-square-icon"
                  [icon]="faTrash"
                ></fa-icon>
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  }
} @else {
  <p> Keine Anhänge vorhanden</p>
}
@if (antragTicket?.antragId && appendeable) {
  <div class="button-container ">
    <button class="btn btn-secondary "
            (click)="addAttachment()">
      <fa-icon
        class="fa btn-mini-square-icon"
        [icon]="faAdd"
      ></fa-icon>
    </button>
  </div>
}
