<main role="main" class="container-fluid" aria-label="Antrag">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="frame frame-full">
        <div id="edit-antragTicket" class="content scaffold-edit" role="main" aria-label="Antrag bearbeiten">
          <div class="row justify-content-between">
            <!--anfang: statusmeldung-->
            <div class="col-md-2">
              <div class="status-display">

                <div class="pb-3">
                  <div class="id-info text-break">
                    <h4 class="m-2"
                        *ngIf="antragTicketId && antragTicketId !== undefined && antragTicketId !== null; else newAntragTicket">
                      {{ "STANDARD.TICKET_ANTRAG" | translate }}s-<br>nummer
                      <div class="text-end default-text-size" data-cy="antragticket-id-no-sb">{{ this.antragTicketId }}</div>
                    </h4>
                    <ng-template #newAntragTicket>
                      <h4 class="m-2">
                        {{ "STANDARD.TICKET_ANTRAG" | translate }}
                      </h4>
                    </ng-template>
                  </div>
                </div>

                <h4 class="text-break" *ngIf="this.postkorbReference && this.postkorbReference !== '' && this.postkorbReference !== null">
                  {{ "TICKETDETAIL.POSTKORB_REFERENCE" | translate }} {{ postkorbReference }}
                </h4>

                <h4 class="text-break" *ngIf="antragTicket?.status == draftStatus || !antragTicketId">
                  {{"TICKETDETAIL.ANTRAGSTATUS" | translate}}
                </h4>
                <div *ngIf="antragTicket?.status == draftStatus || !antragTicketId" class="alert alert-info antrag-status text-break" role="alert">
                  <p><fa-icon [icon]="faInfo"></fa-icon>
                    {{"TICKETDETAIL.STATUS.ENTWURF" | translate}}
                  </p>
                  <span *ngIf="!antragTicketId" class="alert warning"> (nicht gespeichert) </span>
                </div>
                <div *ngIf="antragTicket?.antragsdatum !== null" class="alert alert-info antrag-status text-break" role="alert">
                  <p><i class="fa fa-info-circle"></i>
                    {{"TICKETDETAIL.STATUS.EINGEREICHT" | translate}} {{antragTicket?.antragsdatum | date:'dd.MM.yyyy'}}.
                  </p>
                </div>
                <div *ngIf="antragTicket?.bestelldatum !== null" class="alert alert-info antrag-status text-break" role="alert">
                  <p><i class="fa fa-info-circle"></i>
                    {{"TICKETDETAIL.STATUS.BESTELLT" | translate}} {{antragTicket?.bestelldatum | date:'dd.MM.yyyy'}}.
                  </p>
                </div>
                <div *ngIf="antragTicket?.ausgabedatum !== null" class="alert alert-info antrag-status text-break" role="alert">
                  <p><i class="fa fa-info-circle"></i>
                    {{"TICKETDETAIL.STATUS.AUSGEGEBEN" | translate}} {{antragTicket?.ausgabedatum | date:'dd.MM.yyyy'}}.
                  </p>
                </div>
              </div>
            </div>
            <!--ende: statusmeldung-->

            <!--anfang: form container-->
            <div class="col-md-10">
              <div class="well well-lg">
                <form [formGroup]="antragTicketForm" id="antragForm"
                      class="form-horizontal form-antragTicket" accept-charset="UTF-8">
                  <div class="row" *ngIf="benutzer">
                    <fieldset>
                      <legend>
                        {{existsSessionStorageTextbausteine?
                        textbausteinService.printTextbausteinByKey( KeyString.BEANTRAGENDEPERSON_TITEL_TICKET, ("TICKETDETAIL.BEANTRAGENDE"|translate) ):("TICKETDETAIL.BEANTRAGENDE"|translate)}}
                      </legend>
                      <div class="col-md-2"></div>
                      <div class="col-md-6">
                        <p>{{benutzer.vorname}} {{benutzer.name}}, {{benutzer.geburtsdatum}}, {{benutzer.strasse}}, {{benutzer.plz}} {{benutzer.ort}}, {{benutzer.schule?.name}}</p>
                      </div>
                    </fieldset>
                  </div>
                  <hr>
                  <!--auswahl antragsteller-->
                  <div class="row align-items-center">
                    <div class="col-md-3">
                      <app-custom-select id="schuelerId"
                                         class="flex-grow-1"
                                         [customFormGroup]="antragTicketForm"
                                         placeholder="-"
                                         [items]="antragstellerList"
                                         [multiple]="false"
                                         [clearable]="false"
                                         bindLabel="fullName"
                                         bindValue="id"
                                         [label]="''+(this.readonly? '':this.pflichtFeldMarker)+'Ich stelle diesen Antrag für ..'"
                                         [readonly] = readonly
                                         (onchange)="checkIfVorgaengerExists()"
                      ></app-custom-select>
                    </div>
                    <div  *ngIf="!this.readonly"
                          class="col-md-2">
                      <input type="button"
                             class="btn btn-secondary btn-add-student"
                             value="{{existsSessionStorageTextbausteine?
                                    textbausteinService.printTextbausteinByKey( KeyString.PERSON_SCHUELER_SINGULAR, ('STANDARD.SCHUELER_IN'|translate) ):('STANDARD.SCHUELER_IN'|translate)}} {{ 'SCHUELER-TABLE.ADD_BUTTON' | translate }}"
                             (click)="addSchueler()"
                      >
                    </div>
                  </div>
                  <!--anfang: Formular-->
                  <hr *ngIf="antragTicket">
                  <div class="row">

                    <!--LINKE SEITE-->
                    <div class="col-md-6">

                      <!--anfang: Antragsteller-->

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('name', fieldConfig)?.visible" class="form-group group-for-name col mb-4">
                      <div class="d-flex flex-row" style="margin-bottom: -15px">
                        <div class="flex-grow-1 mr-2">
                          <app-custom-input id="name"
                                            [customFormGroup]="antragTicketForm"
                                            [label]="''+(this.effectiveValidators.includes('name')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('name', fieldConfig, 'STANDARD.NAME' | translate)"
                                            infoText="{{existsSessionStorageTextbausteine?
                                            textbausteinService.printTextbausteinByKey( KeyString.NAME_INFO, ('STANDARD.NAME'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                            [readonly]="true"
                                            data-cy="name"
                          ></app-custom-input>
                        </div>
                      </div>
                    </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vorname', fieldConfig)?.visible" class="form-group group-for-vorname col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2" >
                            <app-custom-input id="vorname"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('vorname')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vorname', fieldConfig, 'STANDARD.VORNAME' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                              textbausteinService.printTextbausteinByKey( KeyString.VORNAME_INFO, ('STANDARD.VORNAME'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                              [readonly]="true"
                                              data-cy="vorname"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('strasse', fieldConfig)?.visible" class="form-group group-for-strasse col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="strasse"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('strasse')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('strasse', fieldConfig, 'STANDARD.STRASSE' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                              textbausteinService.printTextbausteinByKey( KeyString.STRASSE_INFO, ('STANDARD.STRASSE'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                              [readonly]="true"
                                              noteText="Bitte geben Sie die Straße und die Hausnummer in Ihrem Profil an."
                                              data-cy="strasse"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('plz', fieldConfig)?.visible" class="form-group group-for-plz col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="plz"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('plz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('plz', fieldConfig, 'STANDARD.PLZ' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                              textbausteinService.printTextbausteinByKey( KeyString.PLZ_INFO, ('STANDARD.PLZ'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                              [readonly]="true"
                                              data-cy="plz"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ort', fieldConfig)?.visible" class="form-group group-for-ort col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="ort"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('ort')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('ort', fieldConfig, 'STANDARD.WOHNORT' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                              textbausteinService.printTextbausteinByKey( KeyString.WOHNORT_INFO, ('STANDARD.WOHNORT'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                              [readonly]="true"
                                              data-cy="ort"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('adresszusatz', fieldConfig)?.visible" class="form-group group-for-adresszusatz col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="adresszusatz"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('adresszusatz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('adresszusatz', fieldConfig, 'STANDARD.ADRESSZUSATZ' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.ADRESSZUSATZ_INFO, ('STANDARD.ADRESSZUSATZ_INFO'|translate) ):''}}"
                                              [readonly]="readonly"
                                              data-cy="adresszusatz"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('land', fieldConfig)?.visible" class="form-group group-for-land col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-select
                              id="land"
                              class="flex-grow-1"
                              [customFormGroup]="antragTicketForm"
                              [items]="landList"
                              [multiple]="false"
                              [clearable]="false"
                              bindLabel="value"
                              bindValue="name"
                              [label]="''+(this.effectiveValidators.includes('land')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('land', fieldConfig, 'STANDARD.LAND' | translate)"
                              infoText="{{existsSessionStorageTextbausteine?
                              textbausteinService.printTextbausteinByKey( KeyString.LAND_INFO, ('STANDARD.LAND'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                              [readonly]="true"
                              data-cy="land"
                            ></app-custom-select>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geburtsdatum', fieldConfig)?.visible" class="form-group group-for-geburtsdatum col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="geburtsdatum"
                                              type="date"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('geburtsdatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geburtsdatum', fieldConfig, 'STANDARD.GEBURTSDATUM' | translate)"
                                              [readonly]="true"
                                              infoText="{{existsSessionStorageTextbausteine?
                                              textbausteinService.printTextbausteinByKey( KeyString.GEBURTSDATUM_INFO, ('STANDARD.GEBURTSDATUM'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                              data-content="dd.mm.yyyy"
                                              [max]="this.today | date:'yyyy-MM-dd'"
                                              [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                              (onchange)="vormundChange()"
                                              data-cy="geburtsdatum"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschlecht', fieldConfig)?.visible" class="form-group group-for-geschlecht col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-select id="geschlecht"
                                               class="flex-grow-1"
                                               [customFormGroup]="antragTicketForm"
                                               placeholder="-"
                                               [items]="optionGeschlecht"
                                               [multiple]="false"
                                               [clearable]="false"
                                               bindLabel="name"
                                               bindValue="id"
                                               label="{{''+(this.effectiveValidators.includes('geschlecht')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geschlecht', fieldConfig, 'STANDARD.GESCHLECHT.TITEL' | translate )}}"
                                               infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.GESCHLECHT_INFO, ('STANDARD.GESCHLECHT_INFO'|translate) ):''}}"
                                               [readonly]="readonly"
                                               data-cy="geschlecht"
                            ></app-custom-select>
                          </div>
                        </div>
                      </div>
                      <div  *ngIf="effectivelyVisible.includes('email')"
                            class="form-group group-for-email col mb-4" style="display: none">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="email"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('email')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('email', fieldConfig, 'STANDARD.EMAILADRESSE' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.EMAIL_INFO, ('STANDARD.EMAIL_INFO'|translate) ):''}}"
                                              [readonly]="readonly"
                                              data-cy="email"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="effectivelyVisible.includes('emailSchueler')"
                           class="form-group group-for-name col mt-0">
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="emailSchueler"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('emailSchueler')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('emailSchueler', fieldConfig, 'STANDARD.EMAILADRESSE-SCHUELER' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.EMAIL_SCHUELER_INFO, ('STANDARD.EMAIL_INFO'|translate) ):''}}"
                                              [readonly]="readonly"
                                              data-cy="emailSchueler"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="effectivelyVisible.includes('telefonnummer')"
                           class="form-group group-for-name col mt-0">
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="telefonnummer"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('telefonnummer')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('telefonnummer', fieldConfig, 'STANDARD.TELEFONNUMMER-SCHUELER' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.TELEFONNUMMER_INFO, ('STANDARD.TELEFONNUMMER_INFO'|translate) ):''}}"
                                              [readonly]="readonly"
                                              data-cy="telefonnummer"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>

                      <!--anfang: Vormund-->
                      <div *ngIf="isUnderAge && !allVormundFieldsInvisible"
                           class="flex-row sub-paragraph">
                        <h3>
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.VORMUND_TITLE_TICKET, ('STANDARD.VORMUND'|translate) ):('STANDARD.VORMUND'|translate)}}
                        </h3>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundName', fieldConfig)?.visible" class="form-group group-for-vormundName col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundName"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundName')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundName', fieldConfig, 'STANDARD.NAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDNAME_INFO, ('STANDARD.NAME'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="vormundName"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundVorname', fieldConfig)?.visible" class="form-group group-for-vormundVorname col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundVorname"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundVorname')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundVorname', fieldConfig, 'STANDARD.VORNAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDVORNAME_INFO, ('STANDARD.VORNAME'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="vormundVorname"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('isVormundadresseAbweichend')"
                             class="form-group group-for-name col mt-2">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2"
                                 [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                              <label class="checkbox-container"  style="margin-bottom:3ch;">
                                {{'FAHRKOSTEN-DETAIL.VORMUNDADRESSE-ABWEICHEND' | translate}}
                                <input type="checkbox" class="control control-checkbox custom-control-input"
                                       formControlName="isVormundadresseAbweichend"
                                       id="isVormundadresseAbweichend"
                                       name="isVormundadresseAbweichend"
                                >
                                <span class="checkmark" data-cy="isVormundadresseAbweichend"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundStrasse')"
                         class="form-group group-for-vormundStrasse col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundStrasse"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundStrasse')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundStrasse', fieldConfig, 'STANDARD.STRASSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDSTRASSE_INFO, ('STANDARD.STRASSE'|translate)):''}}"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                                                data-cy="vormundStrasse"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundPlz')" class="form-group group-for-vormundPlz col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundPlz"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundPlz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundPlz', fieldConfig, 'STANDARD.PLZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDPLZ_INFO, ('STANDARD.PLZ'|translate)):''}}"
                                                [readonly]="readonly"
                                                [maxlength]="getMaxLength('vormundPlz')"
                                                [pattern]="getPattern('vormundPlz')"
                                                data-cy="vormundPlz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundOrt')" class="form-group group-for-vormundOrt col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundOrt"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundOrt')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundOrt', fieldConfig, 'STANDARD.WOHNORT' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDORT_INFO, ('STANDARD.WOHNORT'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundAdresszusatz')" class="form-group group-for-vormundAdresszusatz col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundAdresszusatz"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('vormundAdresszusatz', fieldConfig, 'STANDARD.ADRESSZUSATZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.VORMUNDADRESSZUSATZ_INFO, ('STANDARD.ADRESSZUSATZ_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                data-cy="vormundAdresszusatz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundLand')" class="form-group group-for-vormundLand col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                             <app-custom-select
                                id="vormundLand"
                                class="flex-grow-1"
                                [customFormGroup]="antragTicketForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="''+(this.effectiveValidators.includes('vormundLand')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundLand', fieldConfig, 'STANDARD.LAND' | translate)"
                                [readonly]="readonly"
                                infoText="{{existsSessionStorageTextbausteine?
                                      textbausteinService.printTextbausteinByKey( KeyString.VORMUNDLAND_INFO, ('STANDARD.LAND_INFO'|translate) ):''}}"
                                (onchange)="this.setPLZValidatorForControl('vormundPlz')"
                                data-cy="vormundLand"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundGeburtsdatum', fieldConfig)?.visible"
                             class="form-group group-for-vormundGeburtsdatum col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundGeburtsdatum"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundGeburtsdatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundGeburtsdatum', fieldConfig, 'STANDARD.GEBURTSDATUM' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDGEBURTSTAG_INFO, ('STANDARD.GEBURTSDATUM_VORMUND_INFO'|translate) ):''}}"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.maxDateVormundGeburtsdatum | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                                data-cy="vormundGeburtsdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundGeschlecht', fieldConfig)?.visible" class="form-group group-for-vormundGeschlecht col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="vormundGeschlecht"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="optionGeschlecht"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 label="{{''+(this.effectiveValidators.includes('vormundGeschlecht')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundGeschlecht', fieldConfig, 'STANDARD.GESCHLECHT.TITEL' |translate)}} "
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.VORMUNDGESCHLECHT_INFO, ('STANDARD.GESCHLECHT_INFO'|translate) ):''}}"
                                                 [readonly]="readonly"
                                                 data-cy="vormundGeschlecht"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundEmail', fieldConfig)?.visible" class="form-group group-for-vormundEmail col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundEmail"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundEmail')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundEmail', fieldConfig, 'STANDARD.EMAIL' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.VORMUNDEMAIL_INFO, ('STANDARD.EMAIL_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                data-cy="vormundEmail"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundTelefonnummer', fieldConfig)?.visible" class="form-group group-for-vormundTelefonnummer col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundTelefonnummer"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundTelefonnummer')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundTelefonnummer', fieldConfig, 'STANDARD.TELEFONNUMMER' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.VORMUNDTELEFONNUMMER_INFO, ('STANDARD.TELEFONNUMMER_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                data-cy="vormundTelefonnummer"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende: Vormund-->

                      <!--weiter: Antragsteller-->
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('typ', fieldConfig)?.visible" class="form-group group-for-typ col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-select id="typ"
                                               [customFormGroup]="antragTicketForm"
                                               placeholder="-"
                                               [items]="optionTyp"
                                               [multiple]="false"
                                               [clearable]="false"
                                               bindLabel="name"
                                               bindValue="id"
                                               label="{{''+(this.effectiveValidators.includes('typ')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('typ', fieldConfig, 'TICKETDETAIL.ANTRAGSART' |translate) }} "
                                               [readonly]="readonly"
                                               infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.ANTRAGSART_INFO, ('TICKETDETAIL.TYP_INFO'|translate) ):''}}"
                                               data-cy="typ"
                            ></app-custom-select>
                          </div>
                        </div>
                      </div>


                      <!--anfang: Fahrweg-Angaben-->
                      <div class="flex-row sub-paragraph" *ngIf="fieldConfigService.getFieldFromFieldConfig('einstieghaltestelle', fieldConfig)?.visible || fieldConfigService.getFieldFromFieldConfig('ausstieghaltestelleHinweg', fieldConfig)?.visible">
                        <h4>
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.INFO_KOSTENBERECHNUNG_TITEL_TICKET, ('TICKETDETAIL.INFORMATIONEN_KOSTENBERECHNUNG'|translate) ):('TICKETDETAIL.INFORMATIONEN_KOSTENBERECHNUNG'|translate)}}
                        </h4>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('einstieghaltestelle', fieldConfig)?.visible" class="form-group group-for-einstieghaltestelle col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="einstieghaltestelle"
                                                type="text"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('einstieghaltestelle')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('einstieghaltestelle', fieldConfig, 'TICKETDETAIL.EINSTIEGHALTE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.EINSTIEGHALTESTELLE_INFO, ('TICKETDETAIL.EINSTIEGHALTE'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="einstieghaltestelle"
                              ></app-custom-input>
                            </div>
                            <div>
                            <span data-original-title="Im Textbaustein bearbeiten" data-placement="top" rel="tooltip"
                                  class="fa fa-info-circle" href="javascript:void(0);"></span>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ausstieghaltestelleHinweg', fieldConfig)?.visible" class="form-group group-for-ausstieghaltestelleHinweg col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="ausstieghaltestelleHinweg"
                                                type="text"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('ausstieghaltestelleHinweg')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('ausstieghaltestelleHinweg', fieldConfig, 'TICKETDETAIL.AUSSTIEGHALTE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.AUSSTIEGHALTESTELLE_INFO, ('TICKETDETAIL.AUSSTIEGHALTE'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="ausstieghaltestelleHinweg"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende: fahrweg-->

                      <!--anfang kostenberechnung info-->
                      <div class="flex-row sub-paragraph">
                        <h4>
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.INFO_KOSTENBERECHNUNG_TITEL_TICKET, ('TICKETDETAIL.INFORMATIONEN_KOSTENBERECHNUNG'|translate) ):('TICKETDETAIL.INFORMATIONEN_KOSTENBERECHNUNG'|translate)}}
                        </h4>

                        <!--anfang geschwisterkind1-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind1', fieldConfig)?.visible" class="form-group group-for-geschwisterkind1 col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind1"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 [multiple]="false"
                                                 bindLabel="fullName"
                                                 bindValue="id"
                                                 label="{{''+(this.effectiveValidators.includes('geschwisterkind1')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geschwisterkind1', fieldConfig, 'STANDARD.GESCHWISTERKIND1' |translate) }} "
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.GESCHWISTERKIND1_INFO, ('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate) ):('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate)}}"
                                                 (onchange) = setGeschwisterkind(1)
                                                 [readonly]="readonly">
                                                 data-cy="geschwisterkind1"
                              </app-custom-select>
                            </div>
                          </div>
                          <div *ngIf=" !(!getFormItem('geschwisterkind1').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind1', fieldConfig)?.visible" class="row">
                            <span>
                              <div class="geschwisterInput-label">Name:</div>
                              <input id="geschwisterkind1name" type="text"
                                     formControlName="geschwisterkind1name"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1name')"
                              />,
                              <input id="geschwisterkind1vorname" type="text"
                                     formControlName="geschwisterkind1vorname"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1vorname')"
                              />
                            </span>
                            <br>
                            <span>
                              <div class="geschwisterInput-label">Geburtsdatum:</div>
                              <input id="geschwisterkind1geburtsdatum" type="text"
                                     formControlName="geschwisterkind1geburtsdatum"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1geburtsdatum')"
                              />
                            </span>
                            <br>
                            <span>
                              <div class="geschwisterInput-label">Adresse:</div>
                              <input type="text" id="geschwisterkind1strasse"
                                     formControlName="geschwisterkind1strasse"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1strasse')"
                              />,
                              <input type="text" id="geschwisterkind1plz"
                                     formControlName="geschwisterkind1plz"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1plz')"
                              />
                              <input type="text" id="geschwisterkind1ort"
                                     formControlName="geschwisterkind1ort"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1ort')"
                              />
                            </span>
                            <br>
                            <span>
                              <div class="geschwisterInput-label">Schule:</div>
                              <input type="text" id="geschwisterkind1schule"
                                     formControlName="geschwisterkind1schule"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1schule')"
                              />
                            </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind1-->

                        <!--anfang geschwisterkind2-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind2', fieldConfig)?.visible" class="form-group group-for-geschwisterkind2 col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind2"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 bindLabel="fullName"
                                                 [multiple]="false"
                                                 bindValue="id"
                                                 label="{{''+(this.effectiveValidators.includes('geschwisterkind2')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geschwisterkind2', fieldConfig, 'STANDARD.GESCHWISTERKIND2' |translate) }} "
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.GESCHWISTERKIND2_INFO, ('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate) ):('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate)}}"
                                                 [readonly]="readonly"
                                                 (onchange) = setGeschwisterkind(2)
                                                 data-cy="geschwisterkind2"
                              ></app-custom-select>
                            </div>
                          </div>

                          <div *ngIf=" !(!getFormItem('geschwisterkind2').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind2', fieldConfig)?.visible" class="row">
                              <span>
                                <div class="geschwisterInput-label">Name:</div>
                                <input id="geschwisterkind2name" type="text"
                                       formControlName="geschwisterkind2name"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2name')"
                                />,
                                <input id="geschwisterkind2vorname" type="text"
                                       formControlName="geschwisterkind2vorname"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2vorname')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Geburtsdatum:</div>
                                <input id="geschwisterkind2geburtsdatum" type="text"
                                       formControlName="geschwisterkind2geburtsdatum"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2geburtsdatum')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Adresse:</div>
                                <input type="text" id="geschwisterkind2strasse"
                                       formControlName="geschwisterkind2strasse"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2strasse')"
                                />,
                                <input type="text" id="geschwisterkind2plz"
                                       formControlName="geschwisterkind2plz"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2plz')"
                                />
                                <input type="text" id="geschwisterkind2ort"
                                       formControlName="geschwisterkind2ort"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2ort')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Schule:</div>
                                <input type="text" id="geschwisterkind2schule"
                                       formControlName="geschwisterkind2schule"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2schule')"
                                />
                              </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind2-->

                        <!--anfang geschwisterkind3-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind3', fieldConfig)?.visible" class="form-group group-for-geschwisterkind3 col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind3"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 bindLabel="fullName"
                                                 [multiple]="false"
                                                 bindValue="id"
                                                 label="{{''+(this.effectiveValidators.includes('geschwisterkind3')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geschwisterkind3', fieldConfig, 'STANDARD.GESCHWISTERKIND3' |translate) }} "
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.GESCHWISTERKIND3_INFO, ('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate) ):('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate)}}"
                                                 [readonly]="readonly"
                                                 (onchange) = setGeschwisterkind(3)
                                                 data-cy="geschwisterkind3"
                              ></app-custom-select>
                            </div>
                          </div>

                          <div *ngIf=" !(!getFormItem('geschwisterkind3').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind3', fieldConfig)?.visible" class="row">
                              <span>
                                <div class="geschwisterInput-label">Name:</div>
                                <input id="geschwisterkind3name" type="text"
                                       formControlName="geschwisterkind3name"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3name')"
                                />,
                                <input id="geschwisterkind3vorname" type="text"
                                       formControlName="geschwisterkind3vorname"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3vorname')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Geburtsdatum:</div>
                                <input id="geschwisterkind3geburtsdatum" type="text"
                                       formControlName="geschwisterkind3geburtsdatum"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3geburtsdatum')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Adresse:</div>
                                <input type="text" id="geschwisterkind3strasse"
                                       formControlName="geschwisterkind3strasse"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3strasse')"
                                />,
                                <input type="text" id="geschwisterkind3plz"
                                       formControlName="geschwisterkind3plz"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3plz')"
                                />
                                <input type="text" id="geschwisterkind3ort"
                                       formControlName="geschwisterkind3ort"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3ort')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Schule:</div>
                                <input type="text" id="geschwisterkind3schule"
                                       formControlName="geschwisterkind3schule"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3schule')"
                                />
                              </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind3-->

                        <!--anfang geschwisterkind4-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind4', fieldConfig)?.visible" class="form-group group-for-geschwisterkind4 col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind4"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 bindLabel="fullName"
                                                 [multiple]="false"
                                                 bindValue="id"
                                                 label="{{''+(this.effectiveValidators.includes('geschwisterkind4')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geschwisterkind4', fieldConfig, 'STANDARD.GESCHWISTERKIND4' |translate) }} "
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.GESCHWISTERKIND4_INFO, ('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate) ):('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate)}}"
                                                 [readonly]="readonly"
                                                 (onchange) = setGeschwisterkind(4)
                                                 data-cy="geschwisterkind4"
                              ></app-custom-select>
                            </div>
                          </div>

                          <div *ngIf=" !(!getFormItem('geschwisterkind4').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind4', fieldConfig)?.visible" class="row">
                              <span>
                                <div class="geschwisterInput-label">Name:</div>
                                <input id="geschwisterkind4name" type="text"
                                       formControlName="geschwisterkind4name"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4name')"
                                />,
                                <input id="geschwisterkind4vorname" type="text"
                                       formControlName="geschwisterkind4vorname"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4vorname')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Geburtsdatum:</div>
                                <input id="geschwisterkind4geburtsdatum" type="text"
                                       formControlName="geschwisterkind4geburtsdatum"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4geburtsdatum')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Adresse:</div>
                                <input type="text" id="geschwisterkind4strasse"
                                       formControlName="geschwisterkind4strasse"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4strasse')"
                                />,
                                <input type="text" id="geschwisterkind4plz"
                                       formControlName="geschwisterkind4plz"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4plz')"
                                />
                                <input type="text" id="geschwisterkind4ort"
                                       formControlName="geschwisterkind4ort"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4ort')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Schule:</div>
                                <input type="text" id="geschwisterkind4schule"
                                       formControlName="geschwisterkind4schule"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4schule')"
                                />
                              </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind4-->

                        <!--anfang geschwisterkind5-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind5', fieldConfig)?.visible" class="form-group group-for-geschwisterkind5 col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind5"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 bindLabel="fullName"
                                                 [multiple]="false"
                                                 bindValue="id"
                                                 label="{{''+(this.effectiveValidators.includes('geschwisterkind5')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geschwisterkind5', fieldConfig, 'STANDARD.GESCHWISTERKIND5' |translate) }} "
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.GESCHWISTERKIND5_INFO, ('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate) ):('TICKETDETAIL.GESCHWISTERKIND_INFO'|translate)}}"
                                                 [readonly]="readonly"
                                                 (onchange) = setGeschwisterkind(5)
                                                 data-cy="geschwisterkind5"
                              ></app-custom-select>
                            </div>
                          </div>

                          <div *ngIf=" !(!getFormItem('geschwisterkind5').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind5', fieldConfig)?.visible" class="row">
                              <span>
                                <div class="geschwisterInput-label">Name:</div>
                                <input id="geschwisterkind5name" type="text"
                                       formControlName="geschwisterkind5name"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5name')"
                                />,
                                <input id="geschwisterkind5vorname" type="text"
                                       formControlName="geschwisterkind5vorname"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5vorname')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Geburtsdatum:</div>
                                <input id="geschwisterkind5geburtsdatum" type="text"
                                       formControlName="geschwisterkind5geburtsdatum"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5geburtsdatum')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Adresse:</div>
                                <input type="text" id="geschwisterkind5strasse"
                                       formControlName="geschwisterkind5strasse"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5strasse')"
                                />,
                                <input type="text" id="geschwisterkind5plz"
                                       formControlName="geschwisterkind5plz"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5plz')"
                                />
                                <input type="text" id="geschwisterkind5ort"
                                       formControlName="geschwisterkind5ort"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5ort')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Schule:</div>
                                <input type="text" id="geschwisterkind5schule"
                                       formControlName="geschwisterkind5schule"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5schule')"
                                />
                              </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind5-->

                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('bezugAsylbewerberleistungsgesetz', fieldConfig)?.visible" class="form-group group-for-bezugAsylbewerberleistungsgesetz col mb-4">
                          <div class="d-flex flex-row"
                            [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                            <label class="checkbox-container">{{fieldConfigService.getFieldLabel('bezugAsylbewerberleistungsgesetz', fieldConfig, 'TICKETDETAIL.BEZUG_ASYLBEWERBERLEISTUNGSGESETZ' | translate)}}
                              <input type="checkbox" class="control control-checkbox custom-control-input"
                                     formControlName="bezugAsylbewerberleistungsgesetz"
                                     id="bezugAsylbewerberleistungsgesetz"
                                     name="bezugAsylbewerberleistungsgesetz"
                              >
                              <span class="checkmark" data-cy="bezugAsylbewerberleistungsgesetz"></span>
                            </label>
                            <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                        textbausteinService.printTextbausteinByKey( KeyString.ASYL_INFO, ('STANDARD.ASYL_INFO'|translate) ):'')"
                              class="infoIcon"
                                     [icon]="faInfo"
                                     title="{{existsSessionStorageTextbausteine?
                                        textbausteinService.printTextbausteinByKey( KeyString.ASYL_INFO, ('STANDARD.ASYL_INFO'|translate) ):''}}"
                            ></fa-icon>
                          </div>
                        </div>
                        <div *ngIf="getFormItem('bezugAsylbewerberleistungsgesetz')?.value === true && fieldConfigService.getFieldFromFieldConfig('bezugNachweis', fieldConfig)?.visible"
                             class="form-group group-for-bezugNachweis col mb-4">
                          <div *ngIf="!readonly"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="bezugNachweis"
                                                [customFormGroup]="antragTicketForm"
                                                name="bezugNachweis"
                                                [label]="''+(this.effectiveValidators.includes('bezugNachweis')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('bezugNachweis', fieldConfig, 'TICKETDETAIL.BEZUG_NACHWEIS' | translate)"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('bezugNachweis')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [readonly]="readonly"
                                                data-cy="bezugNachweis">
                              </app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!bezugNachweis)"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="bezugNachweisThumb">
                                {{ fieldConfigService.getFieldLabel('bezugNachweis', fieldConfig, "TICKETDETAIL.BEZUG_NACHWEIS" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2" id="bezugNachweisThumb">
                                <img *ngIf="!bezugNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(bezugNachweis)"
                                     [src]="sanitize(bezugNachweis)"
                                     alt="img"
                                     data-cy="bezugNachweisThumb"/>
                                <pdf-viewer *ngIf="bezugNachweisIsPDF"
                                            [src]="bezugNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(bezugNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.antragTicket"
                                       [icon]=faDelete
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('bezugNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende kostenberechnung info-->
                    </div>
                    <!--ende linke Seite-->

                    <!--RECHTE SEITE-->
                    <div class="col-md-6">

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('wegtyp', fieldConfig)?.visible" class="form-group group-for-wegtyp col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-select id="wegtyp"
                                               class="flex-grow-1"
                                               [customFormGroup]="antragTicketForm"
                                               placeholder="-"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [items]="optionWegtyp"
                                               bindLabel="name"
                                               bindValue="id"
                                               label="{{''+(this.effectiveValidators.includes('wegtyp')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('wegtyp', fieldConfig, 'TICKETDETAIL.WEGTYP.TITEL' |translate) }} "
                                               infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.WEGTYP_INFO, ('STANDARD.WEGTYP_INFO'|translate) ):''}}"
                                               [readonly]="readonly"
                                               data-cy="wegtyp"
                            ></app-custom-select>
                          </div>
                        </div>
                      </div>

                      <!--anfang SCHULE-->
                      <div class="flex-row sub-paragraph">
                        <h4>
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.ANGABEN_SCHULE_TITEL_TICKET, ('STANDARD.ANGABEN_SCHULE'|translate) ):('STANDARD.ANGABEN_SCHULE'|translate)}}
                        </h4>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('schule', fieldConfig)?.visible"
                             class="form-group group-for-schule col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="schule"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 [placeholder]="(!this.antragTicket||this.antragTicket?.status == draftStatus)?'-':this.antragTicket?.schule?.toString()"
                                                 [items]="schuleList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="name"
                                                 label="{{''+pflichtFeldMarker+fieldConfigService.getFieldLabel('schule', fieldConfig, 'STANDARD.SCHULE' |translate) }} "
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                   textbausteinService.printTextbausteinByKey( KeyString.SCHULE_INFO, ''):''}}"
                                                 [readonly]="readonly"
                                                 data-cy="schule"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div  *ngIf="getFormItem('isBerufskolleg')?.value===true &&
                                fieldConfigService.getFieldFromFieldConfig('bildungsgang', fieldConfig)?.visible">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2"
                                 style="margin-top:-15px;margin-bottom:1ch;"
                                 [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                              <label class="checkbox-container" style="margin-bottom:2ch;">
                                {{''+(this.effectiveValidators.includes('schule')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('schule', fieldConfig, 'STANDARD.BERUFSKOLLEG' | translate)}}
                                <input type="checkbox" class="control control-checkbox custom-control-input"
                                       formControlName="isBerufskolleg"
                                       id="isBerufskolleg"
                                       name="isBerufskolleg"
                                       onclick="return false;"
                                       checked
                                >
                                <span class="checkmark"></span>
                              </label>
                              <app-custom-input *ngIf="effectivelyVisible.includes('bildungsgang')"
                                                id="bildungsgang"
                                                type="text"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('bildungsgang')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('bildungsgang', fieldConfig, 'STANDARD.BILDUNGSGANG' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.BILDUNGSGANG_INFO, ('STANDARD.BILDUNGSGANG'|translate)):''}}"
                                                [infoTextBelowInput]="moduleConfigService.isModuleEnabled('patrisExport') ? 'Der Bildungsgang wird von dem Sachbearbeiter für den Schüler ausgefüllt werden.' : ''"
                                                [readonly]="readonly"
                                                data-cy="bildungsgang"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="effectivelyVisible.includes('bildungsgangdatum')"
                             class="form-group group-for-bildungsgangdatum col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="bildungsgangdatum"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('bildungsgangdatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('bildungsgangdatum', fieldConfig, 'STANDARD.BILDUNGSGANG_DATUM' | translate)"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.BILDUNGSGANGDATUM_INFO, ''):''}}"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDate | date:'yyyy-MM-dd'"
                                                data-cy="bildungsgangdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('fachbereich')"
                             class="form-group group-for-fachbereich col mt-0  mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="fachbereich"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('fachbereich')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('fachbereich', fieldConfig, 'STANDARD.FACHBEREICH' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.FACHBEREICH_INFO, ('STANDARD.FACHBEREICH'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="fachbereich"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>


                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('schuljahr', fieldConfig)?.visible" class="form-group group-for-schuljahr col mb-4">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2" style="margin-bottom:-15px;">
                              <app-custom-input id="schuljahr"
                                                type="text"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('schuljahr')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('schuljahr', fieldConfig, 'STANDARD.SCHULJAHR' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.SCHULJAHR_INFO, ('STANDARD.SCHULJAHR'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="schuljahr"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="getFormItem('isBerufskolleg').value===false && fieldConfigService.getFieldFromFieldConfig('klasse', fieldConfig)?.visible" class="form-group group-for-schuljahr col mb-4">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2" style="margin-bottom:-15px;">
                              <app-custom-input id="klasse"
                                                type="text"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('klasse')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('klasse', fieldConfig, 'STANDARD.SCHULKLASSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KLASSE_INFO, ('STANDARD.SCHULKLASSE'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="klasse"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="getFormItem('wegtyp')?.value === wegtypBEIDES && fieldConfigService.getFieldFromFieldConfig('schultageJeWoche', fieldConfig)?.visible"
                             class="form-group group-for-schultageJeWoche col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <label>
                                {{''+(this.effectiveValidators.includes('schultageJeWoche')?pflichtFeldMarker:'')+ fieldConfigService.getFieldLabel('schultageJeWoche', fieldConfig, "TICKETDETAIL.PRAKTIKUM.SCHULTAGE_JE_WOCHE" | translate) }}
                              </label>
                              <br>
                              <input id="schultageJeWoche" type="number" min="0" max="7" class="form-control"
                                     formControlName="schultageJeWoche" [readonly]="readonly"
                                     data-cy="schultageJeWoche"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende SCHULE-->

                      <!--Anfang Ticket-->
                      <div class="flex-row sub-paragraph">
                        <h4>
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.ANGABEN_TICKET_TITEL_TICKET, ('TICKETDETAIL.ANGABEN_TICKET_TITEL_TICKET'|translate) ):('TICKETDETAIL.ANGABEN_TICKET_TITEL_TICKET'|translate)}}
                        </h4>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('abonnementnummerAntrag', fieldConfig)?.visible" class="form-group group-for-abonnementnummerAntrag col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="abonnementnummerAntrag"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('abonnementnummerAntrag')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('abonnementnummerAntrag', fieldConfig, 'TICKETDETAIL.ABONNEMENTNUMMER' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.ABONNEMENTNUMMER_INFO, ('TICKETDETAIL.ABONNEMENTNUMMER_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                data-cy="abonnementnummerAntrag"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('aboGueltigkeit', fieldConfig)?.visible" class="form-group group-for-aboGueltigkeit col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <h6 *ngIf="this.isMonthSelection">{{this.gueltigAbInfoText}}</h6>
                              <app-custom-input id="aboGueltigkeit"
                                                [type]="this.isMonthSelection? 'month' : 'date'"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('aboGueltigkeit')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('aboGueltigkeit', fieldConfig, 'TICKETDETAIL.ABO_GUELTIGKEIT' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.ABO_GUELTIG_AB_INFO, ('TICKETDETAIL.ABO_GUELTIG_AB_INFO'|translate) ):''}}"
                                                data-content="dd.mm.yyyy"
                                                [min]="this.minDateTicketGueltigAb | date:this.isMonthSelection? 'yyyy-MM' : 'yyyy-MM-dd'"
                                                [max]="this.maxDateTicketGueltigAb | date:this.isMonthSelection? 'yyyy-MM' : 'yyyy-MM-dd'"
                                                data-cy="aboGueltigkeit"
                                                [pattern]="this.isMonthSelection? '[0-9]{4}-[0-9]{2}' : '[0-9]{4}-[0-9]{2}-[0-9]{2}'"
                                                [placeholder]="this.isMonthSelection? 'yyyy-MM' : 'yyyy-MM-dd'"
                              ></app-custom-input>
                              <h6 class="text-warning" *ngIf="this.isMonthSelection
                                                              && fieldConfigService.getFieldFromFieldConfig('aboGueltigkeitWarntext', fieldConfig)?.visible">{{this.gueltigAbInfoText}}</h6>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ticket', fieldConfig)?.visible" class="form-group group-for-ticket col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="ticket"
                                                type="text"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('ticket')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('ticket', fieldConfig, 'Ticketart' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.TICKET_INFO, ('Ticketart'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="ticket"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>



                      </div>
                      <!--Ende Ticket -->

                      <!--PRAKTIKUM-->
                      <div *ngIf="getFormItem('wegtyp')?.value === wegtypPRAKTIKUMSWEG || getFormItem('wegtyp')?.value === 'BEIDES'" class="flex-row sub-paragraph"> <!-- group for Praktikum -->
                        <h4>{{'TICKETDETAIL.PRAKTIKUM.TITEL' |translate }}</h4>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumHinweis', fieldConfig)?.visible"
                             class="form-group group-for-praktikumHinweis col mt-2">
                          <div class="d-flex flex-row" >
                            <div class="flex-grow-1 mr-2">
                              <label for="praktikumHinweis">
                                {{''+(this.effectiveValidators.includes('praktikumHinweis')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumHinweis', fieldConfig, 'TICKETDETAIL.PRAKTIKUM.HINWEIS' | translate)}}
                              </label>
                              <div class="alert alert-warning" id="praktikumHinweis">
                                {{existsSessionStorageTextbausteine?
                                textbausteinService.printTextbausteinByKey( KeyString.ANTRAGTICKET_PRAKTIKUM_HINWEIS,
                                  ('STANDARD.PRAKTIKUM_INFO'|translate)):('STANDARD.PRAKTIKUM_INFO'|translate)}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumStelle', fieldConfig)?.visible"
                             class="form-group group-for-name col mt-0  mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStelle"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumStelle')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumStelle', fieldConfig, 'FAHRKOSTEN-DETAIL.PRAKTIKUMSTELLE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSTELLE_INFO, ('STANDARD.PRAKTIKUMSTELLE_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                data-cy="praktikumStelle"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumStrasse', fieldConfig)?.visible"
                             class="form-group group-for-praktikumStrasse col mt-2">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStrasse"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumStrasse')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumStrasse', fieldConfig, 'STANDARD.STRASSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSTRASSE_INFO, ('STANDARD.STRASSE'|translate)):''}}"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                                                data-cy="praktikumStrasse"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumPlz', fieldConfig)?.visible"
                             class="form-group group-for-praktikumPlz col mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumPlz"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumPlz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumPlz', fieldConfig, 'STANDARD.PLZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMPLZ_INFO, ('STANDARD.PLZ'|translate)):''}}"
                                                [readonly]="readonly"
                                                [maxlength]="getMaxLength('praktikumPlz')"
                                                [pattern]="getPattern('praktikumPlz')"
                                                data-cy="praktikumPlz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumOrt', fieldConfig)?.visible"
                             class="form-group group-for-praktikumOrt col mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumOrt"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumOrt')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumOrt', fieldConfig, 'STANDARD.ORT' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMORT_INFO, ('STANDARD.ORT'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="praktikumOrt"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumAdresszusatz', fieldConfig)?.visible"
                             class="form-group group-for-praktikumAdresszusatz col mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumAdresszusatz"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumAdresszusatz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumAdresszusatz', fieldConfig, 'STANDARD.ADRESSZUSATZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMADRESSZUSATZ_INFO, ('STANDARD.ADRESSZUSATZ_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                data-cy="praktikumAdresszusatz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumLand', fieldConfig)?.visible"
                             class="form-group group-for-praktikumLand col mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="praktikumLand"
                                class="flex-grow-1"
                                [customFormGroup]="antragTicketForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="''+(this.effectiveValidators.includes('praktikumLand')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumLand', fieldConfig, 'STANDARD.LAND' | translate)"
                                [readonly]="readonly"
                                infoText="{{existsSessionStorageTextbausteine?
                                    textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMLAND_INFO, ('STANDARD.LAND_INFO'|translate) ):''}}"
                                (onchange)="this.setPLZValidatorForControl('praktikumPlz')"
                                data-cy="praktikumLand"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumStartdatum', fieldConfig)?.visible"
                             class="form-group group-for-praktikumStartdatum col mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStartdatum"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumStartdatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumStartdatum', fieldConfig, 'TICKETDETAIL.PRAKTIKUM.STARTDATUM' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSTARTDATUM_INFO, ''):''}}"
                                                data-content="dd.mm.yyyy"
                                                [min]="this.minDate | date:'yyyy-MM-dd'"
                                                data-cy="praktikumStartdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumEnddatum', fieldConfig)?.visible"
                             class="form-group group-for-praktikumEnddatum col mb-0">
                          <div class="d-flex flex-row" >
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumEnddatum"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumEnddatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumEnddatum', fieldConfig, 'TICKETDETAIL.PRAKTIKUM.ENDDATUM' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMENDDATUM_INFO, ''):''}}"
                                                data-content="dd.mm.yyyy"
                                                [min]="this.minDatePraktikumEnddatum | date:'yyyy-MM-dd'"
                                                data-cy="praktikumEnddatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumstageJeWoche', fieldConfig)?.visible"
                             class="form-group group-for-praktikumstageJeWoche col mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <label>
                                {{''+(this.effectiveValidators.includes('praktikumstageJeWoche')?pflichtFeldMarker:'')+ fieldConfigService.getFieldLabel('praktikumstageJeWoche', fieldConfig, "TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSTAGE_JE_WOCHE" | translate) }}
                              </label>
                              <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                        textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSTAGE_JE_WOCHE_INFO, ('TICKETDETAIL.PRAKTIKUMSVERTRAG-INFO'|translate) ):'')"
                                class="infoIcon"
                                       [icon]="faInfo"
                                       title="{{existsSessionStorageTextbausteine?
                                        textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSTAGE_JE_WOCHE_INFO, ('TICKETDETAIL.PRAKTIKUMSVERTRAG-INFO'|translate) ):''}}"
                              ></fa-icon>
                              <input id="praktikumstageJeWoche" type="number" min="0" max="7" class="form-control"
                                     formControlName="praktikumstageJeWoche" [readonly]="readonly"
                                     data-cy="praktikumstageJeWoche"/>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumsvertrag', fieldConfig)?.visible"
                             class="form-group group-for-praktikumsvertrag col mb-2 mt-3">
                          <div *ngIf="!readonly" class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumsvertrag"
                                                [customFormGroup]="antragTicketForm"
                                                name="praktikumsvertrag"
                                                [label]="''+(this.effectiveValidators.includes('praktikumsvertrag')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumsvertrag', fieldConfig, 'TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSVERTRAG' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSVERTRAG_INFO, ('TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSVERTRAG'|translate)):''}}"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('praktikumsvertrag')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [readonly]="readonly"
                                                data-cy="praktikumsvertrag">
                              </app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!praktikumsvertrag)"
                               class="d-flex flex-row mt-0">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="praktikumsvertragThumb">
                                {{ fieldConfigService.getFieldLabel('praktikumsvertrag', fieldConfig, "TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSVERTRAG" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2"
                                   id="praktikumsvertragThumb">
                                <img *ngIf="!praktikumsvertragIsPDF"
                                     data-ot-ignore
                                     [src]="sanitize(praktikumsvertrag)"
                                     (click)="previewImg(praktikumsvertrag)"
                                     alt="img"/>
                                <pdf-viewer *ngIf="praktikumsvertragIsPDF"
                                            [src]="praktikumsvertrag"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(praktikumsvertrag)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.antragTicket"
                                       [icon]=faDelete
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('praktikumsvertrag')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende PRAKTIKUM-->

                      <!--SOnderfall-->
                      <div class="flex-row sub-paragraph" *ngIf="effectivelyVisible.includes('sonderfall')">
                        <h4>
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.ERSTATTUNG_GRUENDE_TITEL_TICKET, ('TICKETDETAIL.SONDERFALL.TITEL'|translate) ):('TICKETDETAIL.SONDERFALL.TITEL'|translate)}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('sonderfall')"
                             class="form-group group-for-sonderfall col mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-radio label="{{''+(this.effectiveValidators.includes('sonderfall')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('sonderfall', fieldConfig, 'TICKETDETAIL.SONDERFALL.SONDERFAELLE' |translate) }} "
                                                id="sonderfall"
                                                [options]="optionSonderfall"
                                                [customFormGroup]="antragTicketForm"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.SONDERFALL_INFO, ('STANDARD.SONDERFALL_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                [ngClass]="{'form-check-input-disabled' : readonly, 'form-check-input-active' : !readonly}"
                                                data-cy="sonderfall"
                              ></app-custom-radio>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('sonderfallBegruendung')"
                             class="form-group group-for-sonderfallBegruendung col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-textarea
                                class="flex-grow-1"
                                [label]="''+(this.effectiveValidators.includes('sonderfallBegruendung')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('sonderfallBegruendung', fieldConfig, 'TICKETDETAIL.SONDERFALL.BEGRUENDUNG' | translate)"
                                [rows]="5"
                                [customFormGroup]="antragTicketForm"
                                id="sonderfallBegruendung"
                                infoText="{{existsSessionStorageTextbausteine?
                                  textbausteinService.printTextbausteinByKey( KeyString.SONDERFALLBEGRUENDUNG_INFO, ''):''}}"
                                [readonly]="readonly"
                                data-cy="sonderfallBegruendung">
                              </app-textarea>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('sonderfallNachweis')"
                             class="form-group group-for-sonderfallNachweis col mb-4">
                          <div *ngIf="!readonly"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="sonderfallNachweis"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('sonderfallNachweis')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('sonderfallNachweis', fieldConfig, 'TICKETDETAIL.SONDERFALL.NACHWEIS' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.SONDERFALLNACHWEIS_INFO, ('TICKETDETAIL.SONDERFALL.NACHWEIS'|translate)):''}}"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('sonderfallNachweis')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [readonly]="readonly"
                                                data-cy="sonderfallNachweis">
                              </app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!sonderfallNachweis)"
                               class="d-flex flex-row mt-2">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="sonderfallNachweisThumb">
                                {{ fieldConfigService.getFieldLabel('sonderfallNachweis', fieldConfig, "TICKETDETAIL.SONDERFALL.NACHWEIS" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2" id="sonderfallNachweisThumb">
                                <img *ngIf="!sonderfallNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(sonderfallNachweis)"
                                     [src]="sanitize(sonderfallNachweis)"
                                     alt="img"
                                     data-cy="sonderfallNachweisThumb"/>
                                <pdf-viewer *ngIf="sonderfallNachweisIsPDF"
                                            [src]="sonderfallNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(sonderfallNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.antragTicket"
                                       [icon]=faDelete
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('sonderfallNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('referenzFuerZahlungen', fieldConfig)?.visible
            && antragTicket?.status !== 'ENTWURF' && antragTicketId && antragTicketId !== ''
            && antragTicketId !== null" class="col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="referenzFuerZahlungen"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="fieldConfigService.getFieldLabel('referenzFuerZahlungen', fieldConfig, 'TICKETDETAIL.REFERENZZAHLUNG' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.REFERENZZAHLUNG_INFO, ''):''}}"
                                              [readonly]="true"
                                              data-cy="referenzFuerZahlungen"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('bemerkungAntragsteller', fieldConfig)?.visible"
                           class="form-group group-for-bemerkungAntragsteller col mb-4">
                        <div class="d-flex flex-row" style="margin-bottom: -15px">
                          <div class="flex-grow-1 mr-2">
                            <app-textarea
                              class="flex-grow-1"
                              label="{{'TICKETDETAIL.BEMANTRAGSTELLER' |translate }}"
                              [rows]="5"
                              [customFormGroup]="antragTicketForm"
                              id="bemerkungAntragsteller"
                              infoText="{{existsSessionStorageTextbausteine?
                                  textbausteinService.printTextbausteinByKey( KeyString.BEMERKUNG_ANTRAGSTELLER_INFO, ('TICKETDETAIL.BEMANTRAGSTELLER-INFO'|translate) ):''}}"
                              [readonly]="readonly"
                              data-cy="bemerkungAntragsteller">
                            </app-textarea>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('einverstaendnisErklaerung', fieldConfig)?.visible" class="form-group group-for-einverstaendnisErklaerung col mb-4">
                        <div class="custom-control-inline custom-checkbox group-for-einverstaendnisErklaerung d-flex flex-row"
                             [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                          <label class="checkbox-container">
                            {{''+(this.effectiveValidators.includes('einverstaendnisErklaerung')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('einverstaendnisErklaerung', fieldConfig, 'TICKETDETAIL.EINVERSTAENDNISERKLAERUNG' | translate)}}
                            <input type="checkbox" class="control control-checkbox custom-control-input"
                                   formControlName="einverstaendnisErklaerung"
                                   id="einverstaendnisErklaerung"
                                   name="einverstaendnisErklaerung"
                            >
                            <span class="checkmark" data-cy="einverstaendnisErklaerung"></span>
                          </label>
                          <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                      textbausteinService.printTextbausteinByKey( KeyString.EINVERSTAENDNISERKLAERUNG_INFO, ('TICKETDETAIL.EINVERSTAENDNIS_INFO'|translate)):'')"
                            class="infoIcon"
                                   [icon]="faInfo"
                                   title="{{existsSessionStorageTextbausteine?
                                      textbausteinService.printTextbausteinByKey( KeyString.EINVERSTAENDNISERKLAERUNG_INFO, ('TICKETDETAIL.EINVERSTAENDNIS_INFO'|translate)):''}}"
                          ></fa-icon>
                        </div>
                      </div>

                    </div>
                    <!--ende rechte Seite-->
                  </div>

                  <div *ngIf="fieldConfigService.getFieldFromFieldConfig('marketingfreigabe', fieldConfig)?.visible" class="form-group group-for-marketingfreigabe col mb-4">
                    <div class="custom-control-inline custom-checkbox group-for-marketingfreigabe d-flex flex-row"
                         [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                      <label class="checkbox-container">
                        {{''+(this.effectiveValidators.includes('marketingfreigabe')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('marketingfreigabe', fieldConfig, 'TICKETDETAIL.MARKETINGFREIGABE' | translate)}}
                        <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="marketingfreigabe"
                               id="marketingfreigabe"
                               name="marketingfreigabe"
                        >
                        <span class="checkmark" data-cy="marketingfreigabe"></span>
                      </label>
                      <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                  textbausteinService.printTextbausteinByKey( KeyString.MARKETINGFREIGABE_INFO,('STANDARD.MARKETINGFREIGABE_INFO'|translate)):'')"
                               class="infoIcon"
                               [icon]="faInfo"
                               title="{{existsSessionStorageTextbausteine?
                                  textbausteinService.printTextbausteinByKey( KeyString.MARKETINGFREIGABE_INFO,('STANDARD.MARKETINGFREIGABE_INFO'|translate)):''}}"
                      ></fa-icon>
                    </div>
                  </div>

                  <!--SEPA TEIL-->
                  <hr *ngIf="moduleConfigService.isModuleEnabled('antragTicketSepa')">
                  <div *ngIf="moduleConfigService.isModuleEnabled('antragTicketSepa')" class="row">
                    <legend>
                      {{existsSessionStorageTextbausteine?
                      textbausteinService.printTextbausteinByKey( KeyString.ERTEILUNG_SEPA_MANDAT_TITEL_TICKET,('TICKETDETAIL.SEPA.TITEL'|translate) ):('TICKETDETAIL.SEPA.TITEL'|translate)}}
                    </legend>
                    <div class="row">
                      <div class="form-group group-for-isAntragstellerNotMandatsgeber col mb-4">
                        <div class="custom-control-inline custom-checkbox group-for-isAntragstellerNotMandatsgeber d-flex flex-row"
                             [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                          <label class="checkbox-container">
                            {{''+fieldConfigService.getFieldLabel('isAntragstellerNotMandatsgeber',
                            fieldConfig, 'TICKETDETAIL.SEPA.ANTRAGSTELLER_NICHT_MANDATSGEBER' | translate)}}
                            <input type="checkbox" class="control control-checkbox custom-control-input"
                                   formControlName="isAntragstellerNotMandatsgeber"
                                   id="isAntragstellerNotMandatsgeber"
                                   name="isAntragstellerNotMandatsgeber"
                                   (change)="sepaChange()"
                                   [attr.disabled]="readonly ? true : null"
                            >
                            <span class="checkmark" data-cy="isAntragstellerNotMandatsgeber"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!--ANTRAGSTELLER != MANDATSGEBER-->
                    <div *ngIf="getFormItem('isAntragstellerNotMandatsgeber')?.value" class="row">

                      <!--SEPA LINKS-->
                      <div class="col-md-6">
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('nameSepa', fieldConfig)?.visible" class="form-group group-for-nameSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="nameSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('nameSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('nameSepa', fieldConfig, 'STANDARD.NACHNAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.NAMESEPA_INFO, ('STANDARD.NACHNAME'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="nameSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vornameSepa', fieldConfig)?.visible" class="form-group group-for-vornameSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vornameSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('vornameSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vornameSepa', fieldConfig, 'STANDARD.VORNAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORNAMESEPA_INFO, ('STANDARD.VORNAME'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="vornameSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('strasseSepa', fieldConfig)?.visible" class="form-group group-for-strasseSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="strasseSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('strasseSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('strasseSepa', fieldConfig, 'TICKETDETAIL.SEPA.STRASSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.STRASSESEPA_INFO, ('TICKETDETAIL.SEPA.STRASSE'|translate)):''}}"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                                                data-cy="strasseSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('plzSepa', fieldConfig)?.visible" class="form-group group-for-plzSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="plzSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('plzSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('plzSepa', fieldConfig, 'TICKETDETAIL.SEPA.PLZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PLZSEPA_INFO, ('TICKETDETAIL.SEPA.PLZ'|translate)):''}}"
                                                [readonly]="readonly"
                                                [maxlength]="getMaxLength('plzSepa')"
                                                [pattern]="getPattern('plzSepa')"
                                                data-cy="plzSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ortSepa', fieldConfig)?.visible" class="form-group group-for-ortSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="ortSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('ortSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('ortSepa', fieldConfig, 'TICKETDETAIL.SEPA.ORT' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.ORTSEPA_INFO, ('TICKETDETAIL.SEPA.ORT'|translate)):''}}"
                                                [readonly]="readonly"
                                                data-cy="ortSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('adresszusatzSepa', fieldConfig)?.visible" class="form-group group-for-adresszusatzSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="adresszusatzSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('adresszusatzSepa', fieldConfig, 'STANDARD.ADRESSZUSATZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.ADRESSZUSATZSEPA_INFO, ('STANDARD.ADRESSZUSATZ_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                data-cy="adresszusatzSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('landSepa', fieldConfig)?.visible" class="form-group group-for-landSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="landSepa"
                                class="flex-grow-1"
                                [customFormGroup]="antragTicketForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="''+(this.effectiveValidators.includes('landSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('landSepa', fieldConfig, 'STANDARD.LAND' | translate)"
                                [readonly]="readonly"
                                infoText="{{existsSessionStorageTextbausteine?
                                  textbausteinService.printTextbausteinByKey( KeyString.LANDSEPA_INFO, ('STANDARD.LAND_INFO'|translate) ):''}}"
                                (onchange)="this.setPLZValidatorForControl('plzSepa')"
                                data-cy="landSepa"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--SEPA RECHTS-->
                      <div class="col-md-6">
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geburtsdatumSepa', fieldConfig)?.visible" class="form-group group-for-geburtsdatumSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="geburtsdatumSepa"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('geburtsdatumSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geburtsdatumSepa', fieldConfig, 'TICKETDETAIL.SEPA.GEBURTSDATUM' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.GEBURTSDATUMSEPA_INFO, ('STANDARD.GEBURTSDATUM_KONTO_INFO'|translate) ):''}}"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschlechtSepa', fieldConfig)?.visible" class="form-group group-for-geschlecht col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschlechtSepa"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="optionGeschlecht"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.GESCHLECHTSEPA_INFO, ('STANDARD.GESCHLECHT_INFO'|translate) ):''}}"
                                                 label="{{''+(this.effectiveValidators.includes('geschlechtSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geschlechtSepa', fieldConfig, 'STANDARD.GESCHLECHT.TITEL' | translate )}}"
                                                 [readonly]="readonly"
                                                 data-cy="geschlechtSepa">
                              </app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('emailSepa', fieldConfig)?.visible" class="form-group group-for-emailSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="emailSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('emailSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('emailSepa', fieldConfig, 'TICKETDETAIL.SEPA.EMAIL' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.EMAILSEPA_INFO, ('STANDARD.EMAIL_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                data-cy="emailSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('telefonnummerSepa', fieldConfig)?.visible" class="form-group group-for-telefonnummerSepa col mb-4">
                          <div class="d-flex flex-row" style="margin-bottom: -15px">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="telefonnummerSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="''+(this.effectiveValidators.includes('telefonnummerSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('telefonnummerSepa', fieldConfig, 'TICKETDETAIL.SEPA.TELEFONNUMMER' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.TELEFONNUMMERSEPA_INFO, ('STANDARD.TELEFONNUMMER_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                data-cy="telefonnummerSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('nameKreditinstitutSepa', fieldConfig)?.visible" class="d-flex flex-row" >
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="nameKreditinstitutSepa"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('nameKreditinstitutSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('nameKreditinstitutSepa', fieldConfig, 'TICKETDETAIL.SEPA.KREDITINSTITUT_SEPA' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KREDITINSTITUTSEPA_INFO, ('TICKETDETAIL.SEPA.KREDITINSTITUT_SEPA'|translate)):''}}"
                                              [readonly]="readonly"
                                              data-cy="nameKreditinstitutSepa"
                            ></app-custom-input>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ibanSepa', fieldConfig)?.visible" class="d-flex flex-row" >
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="ibanSepa"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('ibanSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('ibanSepa', fieldConfig, 'TICKETDETAIL.SEPA.IBAN_SEPA' | translate)"
                                              [readonly]="readonly"
                                              infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.SEPAIBAN_INFO, ''):''}}"
                                              data-cy="ibanSepa"
                            ></app-custom-input>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('bicSepa', fieldConfig)?.visible" class="d-flex flex-row" >
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="bicSepa"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="''+(this.effectiveValidators.includes('bicSepa')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('bicSepa', fieldConfig, 'TICKETDETAIL.SEPA.BIC_SEPA' | translate)"
                                              infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.SEPABIC_INFO, ('STANDARD.BIC_INFO'|translate) ):''}}"
                                              [readonly]="readonly"
                                              data-cy="bicSepa">
                            </app-custom-input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--ANTRAGSTELLER == MANDATSGEBER-->
                    <div *ngIf="!(getFormItem('isAntragstellerNotMandatsgeber')?.value)" class="col-md-6">
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('nameKreditinstitut', fieldConfig)?.visible" class="d-flex flex-row" >
                        <div class="flex-grow-1 mr-2">
                          <app-custom-input id="nameKreditinstitut"
                                            [customFormGroup]="antragTicketForm"
                                            [label]="''+(this.effectiveValidators.includes('nameKreditinstitut')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('nameKreditinstitut', fieldConfig, 'TICKETDETAIL.SEPA.KREDITINSTITUT' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.NAMEKREDITINSTITUT_INFO, ('TICKETDETAIL.SEPA.KREDITINSTITUT'|translate)):''}}"
                                            [readonly]="readonly"
                                            data-cy="nameKreditinstitut"
                          ></app-custom-input>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('iban', fieldConfig)?.visible" class="d-flex flex-row" >
                        <div class="flex-grow-1 mr-2">
                          <app-custom-input id="iban"
                                            [customFormGroup]="antragTicketForm"
                                            [label]="''+(this.effectiveValidators.includes('iban')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('iban', fieldConfig, 'TICKETDETAIL.SEPA.IBAN' | translate)"
                                            [readonly]="readonly"
                                            infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.IBAN_INFO, ''):''}}"
                                            data-cy="iban"
                          ></app-custom-input>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('bic', fieldConfig)?.visible" class="d-flex flex-row" >
                        <div class="flex-grow-1 mr-2">
                          <app-custom-input id="bic"
                                            [customFormGroup]="antragTicketForm"
                                            [label]="''+(this.effectiveValidators.includes('bic')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('bic', fieldConfig, 'TICKETDETAIL.SEPA.BIC' | translate)"
                                            infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.BIC_INFO, ('STANDARD.BIC_INFO'|translate) ):''}}"
                                            [readonly]="readonly"
                                            data-cy="bic"
                          ></app-custom-input>
                        </div>
                      </div>
                    </div>
                    <div class="form-group group-for-datenschutz col mb-4 mt-4">
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('datenschutz', fieldConfig)?.visible" class="custom-control-inline custom-checkbox group-for-datenschutz d-flex flex-row"
                           [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                        <label class="checkbox-container">
                          <p [innerHTML]="''+(this.effectiveValidators.includes('datenschutz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('datenschutz', fieldConfig, 'TICKETDETAIL.SEPA.DATENSCHUTZ' | translate)"></p>
                          <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="datenschutz"
                                 id="datenschutz"
                                 name="datenschutz"
                          >
                          <span class="checkmark" data-cy="datenschutz"></span>
                        </label>
                        <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                    textbausteinService.printTextbausteinByKey( KeyString.DATENSCHUTZ_INFO, ('STANDARD.DATENSCHUTZ_INFO'|translate)):'')"
                          class="infoIcon"
                                 [icon]="faInfo"
                                 title="{{existsSessionStorageTextbausteine?
                                    textbausteinService.printTextbausteinByKey( KeyString.DATENSCHUTZ_INFO, ('STANDARD.DATENSCHUTZ_INFO'|translate)):''}}"
                        ></fa-icon>
                      </div>
                    </div>
                  </div>
                  <!-- ende SEPA TEIL-->

                  <!--finally-->
                  <div class="form-group group-for-richtigkeitDerAngaben col mb-4">
                    <div *ngIf="fieldConfigService.getFieldFromFieldConfig('richtigkeitDerAngaben', fieldConfig)?.visible"
                         class="custom-control-inline custom-checkbox group-for-richtigkeitDerAngaben d-flex flex-row"
                         [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                      <label class="checkbox-container">
                        <p [innerHTML]="''+(fieldConfigService.getFieldFromFieldConfig('richtigkeitDerAngaben', fieldConfig)?.required?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('richtigkeitDerAngaben', fieldConfig, 'TICKETDETAIL.RICHTIGKEIT_DER_ANGABEN' | translate)"></p>
                        <input type="checkbox" class="control control-checkbox custom-control-input"
                               formControlName="richtigkeitDerAngaben"
                               id="richtigkeitDerAngaben"
                               name="richtigkeitDerAngaben"
                        >
                        <span class="checkmark" data-cy="richtigkeitDerAngaben"></span>
                      </label>
                      <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                    textbausteinService.printTextbausteinByKey( KeyString.RICHTIGKEIT_DER_ANGABEN_INFO, ('TICKETDETAIL.RICHTIGKEIT_DER_ANGABEN_INFO'|translate)):'')"
                        class="infoIcon"
                               [icon]="faInfo"
                               title="{{existsSessionStorageTextbausteine?
                                    textbausteinService.printTextbausteinByKey( KeyString.RICHTIGKEIT_DER_ANGABEN_INFO, ('TICKETDETAIL.RICHTIGKEIT_DER_ANGABEN_INFO'|translate)):''}}"
                      ></fa-icon>
                    </div>
                  </div>


                  <!--BUTTONS-->
                  <div class="d-flex flex-row-reverse">
                    <input *ngIf="isDeleteDraftButtonVisible" type="button" value="Entwurf löschen"
                           class="btn btn-secondary save btn-block" (click)="deleteDraft()">
                    <span style="width: 1em"></span>
                    <input *ngIf="isUpdateEntwurfButtonVisible" type="button" value="Entwurf speichern"
                           class="btn btn-secondary save btn-block"
                           (click)="saveOrUpdate(draftStatus,'Entwurf wurde erfolgreich gespeichert.', 'ticket-detail')">
                    <span style="width: 1em"></span>
                    <input *ngIf="isEinreichenButtonVisible" type="button" value="Antrag einreichen"
                           class="btn btn-primary save btn-block"
                           (click)="saveOrUpdate(eingereichtStatus,'Antrag wurde erfolgreich eingereicht.','ticket-table')">
                  </div>

                </form>
              </div>
            </div>
            <!--ende: form container-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen] = "true"
  >
  </ngx-spinner>
</main>
