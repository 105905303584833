<main role="main" class="container-fluid" aria-label="Erstattungsantrag">
  <div class="row justify-content-center">
    <main class="col-12">
      <div class="frame frame-full">
        <div id="edit-fahrkosten" class="content scaffold-edit" role="main" aria-label="Erstattungsantrag bearbeiten">
          <div class="row justify-content-between">

            <!--anfang: statusmeldung-->
            <div class="col-md-2">
              <div class="status-display">

                <div class="pb-3">
                  <div class="id-info text-break">
                    <h4 class="m-2"
                        *ngIf="!this.isEntwurf && this.fahrkosten?.id && this.fahrkosten?.id !== undefined && this.fahrkosten?.id !== null; else newFahrkosten">
                      {{('STANDARD.FAHRKOSTEN_ANTRAG' | translate)}}s-<br>nummer
                      <div class="text-end default-text-size" data-cy="fahrkosten-id-no-sb">{{ this.fahrkosten?.id }}</div>
                    </h4>
                    <ng-template #newFahrkosten>
                      <h4 class="m-2" data-cy="fahrkosten-id">
                        {{ 'STANDARD.FAHRKOSTEN_ANTRAG' | translate }}
                      </h4>
                    </ng-template>
                  </div>
                </div>

                <h4 class="text-break" *ngIf="this.postkorbReference && this.postkorbReference !== '' && this.postkorbReference !== null">
                  {{ "FAHRKOSTEN-DETAIL.POSTKORB_REFERENCE" | translate }} {{ postkorbReference }}
                </h4>
                <h4 class="text-break" *ngIf="this.isEntwurf">
                  {{existsSessionStorageTextbausteine?
                  textbausteinService.printTextbausteinByKey( KeyString.SUBTITLE_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.SUBTITLE'|translate) ):('FAHRKOSTEN-DETAIL.SUBTITLE'|translate)}}
                </h4>
                <h5 class="text-break">
                  {{ 'FAHRKOSTEN-DETAIL.STATUS' | translate }}:
                  {{ 'FAHRKOSTEN-DETAIL.FAHRKOSTENSTATUS.'+ statusVisibleToUser() | translate }}
                </h5>
                <div class="alert alert-primary mt-4" role="alert">
                  <p class="text-break">
                    {{ 'FAHRKOSTEN-DETAIL.EINREICHUNG' | translate }}: <br>
                    {{this.fahrkosten?.antragsdatum}}
                  </p>
                  <p class="text-break">
                    {{ 'FAHRKOSTEN-DETAIL.AKTUELL' | translate }}: <br>
                    {{ 'FAHRKOSTEN-DETAIL.FAHRKOSTENSTATUS.'+ statusVisibleToUser() | translate }}
                  </p>
                </div>
                <div *ngIf="this.isFinalized" class="alert alert-warning" role="alert" >
                  <p class="text-break">
                    <fa-icon [icon]="faCheck"></fa-icon>
                    {{ 'FAHRKOSTEN-DETAIL.ABSCHLUSS' | translate }}
                  </p>
                  <p class="text-break">
                    {{ 'FAHRKOSTEN-DETAIL.ERSTATTUNG' | translate }}: <br>
                    {{this.fahrkosten?.gesamtErstattung}}
                  </p>
                </div>
                <div *ngIf="!this.isFinalized"  class="alert alert-danger" role="alert" >
                  <p class="text-break">
                    <fa-icon [icon]="faNope"></fa-icon>
                    {{ 'FAHRKOSTEN-DETAIL.ABSCHLUSS_KEIN' | translate }}
                  </p>
                  <p class="text-break">
                    {{ 'FAHRKOSTEN-DETAIL.ERSTATTUNG' | translate }}: <br>
                    [{{ 'STANDARD.UNBEKANNT' | translate }}]
                  </p>
                </div>
                <div class="changelog" *ngIf="fahrkostenHistorie" >
                  <h4 class="text-break">
                    {{ 'STANDARD.HISTORY' | translate }}
                  </h4>
                  <p>
                  </p>
                  <table class="table table-striped table-hover table-revisions">
                    <caption></caption>
                    <th></th>
                    <tbody>
                    <tr  *ngFor="let historie of fahrkostenHistorie">
                      <td>
                   <span>
                     <fa-icon [icon]="faClock"></fa-icon>
                   </span>
                        <span>
                      {{historie?.bearbeitungszeitpunkt | date}}
                    </span>
                        <span class="label label-info"> {{ 'FAHRKOSTEN-DETAIL.FAHRKOSTENSTATUS.'+historie?.status | translate }}</span>
                      </td>
                    </tr>
                    </tbody></table>
                  <p></p>
                </div>
              </div>
            </div>
            <!--ende: statusmeldung-->

            <!--anfang form-->
            <div class="col-md-10">
              <div class="well well-lg">
                <form [formGroup]="fahrkostenForm"
                      id="fahrkostenForm" class="form-horizontal form-fahrkosten" accept-charset="UTF-8">

                  <div class="row" *ngIf="benutzer">
                    <fieldset>
                      <legend>
                        {{existsSessionStorageTextbausteine?
                        textbausteinService.printTextbausteinByKey( KeyString.BEANTRAGENDEPERSON_TITEL_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.ANTRAGSTELLENDE_PERSON'|translate) ):('FAHRKOSTEN-DETAIL.ANTRAGSTELLENDE_PERSON'|translate)}}
                      </legend>
                      <div class="col-md-2"></div>
                      <div class="col-md-6">
                        <p>{{benutzer.vorname}} {{benutzer.name}}, {{benutzer.geburtsdatum}}, {{benutzer.strasse}},{{benutzer.plz}} {{benutzer.ort}}, {{benutzer.schule?.name}}</p>
                      </div>
                    </fieldset>
                  </div>

                  <hr>

                  <!--auswahl antragsteller-->
                  <div class="row align-items-center">
                    <div class="col-md-3">
                      <app-custom-select id="schuelerId"
                                         class="flex-grow-1"
                                         [customFormGroup]="fahrkostenForm"
                                         placeholder="-"
                                         [items]="antragstellerList"
                                         [multiple]="false"
                                         [clearable]="false"
                                         bindLabel="fullName"
                                         bindValue="id"
                                         [label]="''+(this.readonly? '':this.pflichtFeldMarker)+'Ich stelle diesen Antrag für ..'"
                                         [readonly]="readonly"
                                         (onchange)="checkIfVorgaengerExists()"
                      ></app-custom-select>
                    </div>
                    <div  *ngIf="!this.readonly"
                          class="col-md-2">
                      <input type="button"
                             class="btn btn-secondary btn-add-student"
                             value="{{existsSessionStorageTextbausteine?
                                    textbausteinService.printTextbausteinByKey( KeyString.PERSON_SCHUELER_SINGULAR, ('STANDARD.SCHUELER_IN'|translate) ):('STANDARD.SCHUELER_IN'|translate)}} {{ 'SCHUELER-TABLE.ADD_BUTTON' | translate }}"
                             (click)="addSchueler()"
                      >
                    </div>
                  </div>

                  <!--anfang teil angaben-->
                  <div class="row justify-content-evenly">

                    <!--LINKS-->
                    <div class="col-md-6">

                      <!--angaben schueler-->
                      <div class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.ANGABEN_SCHUELER_TITEL_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.ANGABEN_SCHUELER'|translate) ):('FAHRKOSTEN-DETAIL.ANGABEN_SCHUELER'|translate)}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('name')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="name"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('name')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('name', fieldConfig, 'STANDARD.NAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.NAME_INFO_FAHRKOSTEN, ('STANDARD.NAME'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                                [readonly]="true"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vorname')"
                             class="form-group group-for-vorname col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2" >
                              <app-custom-input id="vorname"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vorname')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vorname', fieldConfig, 'STANDARD.VORNAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.VORNAME_INFO_FAHRKOSTEN, ('STANDARD.VORNAME'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                                [readonly]="true"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('strasse')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="strasse"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('strasse')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('strasse', fieldConfig, 'STANDARD.STRASSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.STRASSE_INFO_FAHRKOSTEN, ('STANDARD.STRASSE'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                                [readonly]="true"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('plz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="plz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('plz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('plz', fieldConfig, 'STANDARD.PLZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.PLZ_INFO_FAHRKOSTEN, ('STANDARD.PLZ'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                                [readonly]="true"
                                                [maxlength]="10"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('ort')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="ort"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('ort')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('ort', fieldConfig, 'STANDARD.WOHNORT' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.WOHNORT_INFO_FAHRKOSTEN, ('STANDARD.WOHNORT'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                                [readonly]="true"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('adresszusatz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="adresszusatz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('adresszusatz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('adresszusatz', fieldConfig, 'STANDARD.ADRESSZUSATZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.ADRESSZUSATZ_INFO_FAHRKOSTEN, ('STANDARD.ADRESSZUSATZ_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('land')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="land"
                                class="flex-grow-1"
                                [customFormGroup]="fahrkostenForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="''+(this.effectiveValidators.includes('land')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('land', fieldConfig, 'STANDARD.LAND' | translate)"
                                infoText="{{existsSessionStorageTextbausteine?
                                textbausteinService.printTextbausteinByKey( KeyString.LAND_INFO_FAHRKOSTEN, ('STANDARD.LAND'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                [readonly]="true"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('geburtsdatum')"
                             class="form-group group-for-geburtsdatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="geburtsdatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('geburtsdatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geburtsdatum', fieldConfig, 'STANDARD.GEBURTSDATUM' | translate)"
                                                [readonly]="true"
                                                data-content="dd.mm.yyyy"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.GEBURTSDATUM_INFO_FAHRKOSTEN, ('STANDARD.GEBURTSDATUM'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('geschlecht')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschlecht"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [items]="optionGeschlecht"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.GESCHLECHT_INFO_FAHRKOSTEN, ('STANDARD.GESCHLECHT_INFO'|translate) ):''}}"
                                                 label="{{''+(this.effectiveValidators.includes('geschlecht')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('geschlecht', fieldConfig, 'STANDARD.GESCHLECHT.TITEL' | translate )}}"
                                                 [readonly]="readonly"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('email')"
                             class="form-group group-for-name col mt-0" style="display:none">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="email"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('email')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('email', fieldConfig, 'STANDARD.EMAILADRESSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                textbausteinService.printTextbausteinByKey( KeyString.EMAIL_INFO_FAHRKOSTEN, ('STANDARD.EMAIL_INFO'|translate), ('STANDARD.CHANGE_IN_PROFILE' | translate) ):''}}"
                                                [readonly]="true"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('emailSchueler')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="emailSchueler"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('emailSchueler')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('emailSchueler', fieldConfig, 'STANDARD.EMAILADRESSE-SCHUELER' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.EMAIL_SCHUELER_INFO_FAHRKOSTEN, ('STANDARD.EMAIL_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('telefonnummer')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="telefonnummer"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('telefonnummer')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('telefonnummer', fieldConfig, 'STANDARD.TELEFONNUMMER-SCHUELER' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.TELEFONNR_SCHUELER_INFO_FAHRKOSTEN, ('STANDARD.TELEFONNUMMER_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende angaben schueler-->

                      <!--anfang vormund-->
                      <div  *ngIf="(getFormItem('isUnderAge')?.value)===true"
                            class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.ANGABEN_VORMUND_TITLE_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.ANGABEN_VORMUND'|translate) ):('FAHRKOSTEN-DETAIL.ANGABEN_VORMUND'|translate) }}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('vormundName')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundName"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundName')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundName', fieldConfig, 'STANDARD.NAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDNAME_INFO_FAHRKOSTEN, ('STANDARD.NAME'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundVorname') "
                             class="form-group group-for-vorname col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2" >
                              <app-custom-input id="vormundVorname"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundVorname')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundVorname', fieldConfig, 'STANDARD.VORNAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDVORNAME_INFO_FAHRKOSTEN, ('STANDARD.VORNAME'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('isVormundadresseAbweichend')"
                             class="form-group group-for-name col mt-2">
                          <div class="d-flex flex-row"
                               [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                              <label class="checkbox-container"  style="margin-bottom:3ch;">
                                {{'FAHRKOSTEN-DETAIL.VORMUNDADRESSE-ABWEICHEND' | translate}}
                                <input type="checkbox" class="control control-checkbox custom-control-input"
                                       formControlName="isVormundadresseAbweichend"
                                       id="isVormundadresseAbweichend"
                                       name="isVormundadresseAbweichend"
                                >
                                <span class="checkmark"></span>
                              </label>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundStrasse')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundStrasse"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundStrasse')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundStrasse', fieldConfig, 'STANDARD.STRASSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDSTRASSE_INFO_FAHRKOSTEN, ('STANDARD.STRASSE'|translate)):''}}"
                                                [readonly]="readonly"
                                                noteText="{{ 'STANDARD.STRASSE_NOTE'|translate }}"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundPlz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundPlz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundPlz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundPlz', fieldConfig, 'STANDARD.PLZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDPLZ_INFO_FAHRKOSTEN, ('STANDARD.PLZ'|translate)):''}}"
                                                [readonly]="readonly"
                                                [maxlength]="getMaxLength('vormundPlz')"
                                                [pattern]="getPattern('vormundPlz')"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundOrt')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundOrt"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundOrt')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundOrt', fieldConfig, 'STANDARD.WOHNORT' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDORT_INFO_FAHRKOSTEN, ('STANDARD.WOHNORT'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundAdresszusatz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundAdresszusatz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundAdresszusatz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundAdresszusatz', fieldConfig, 'STANDARD.ADRESSZUSATZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.VORMUNDADRESSZUSATZ_INFO_FAHRKOSTEN, ('STANDARD.ADRESSZUSATZ_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundLand')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="vormundLand"
                                class="flex-grow-1"
                                [customFormGroup]="fahrkostenForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="''+(this.effectiveValidators.includes('vormundLand')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundLand', fieldConfig, 'STANDARD.LAND' | translate)"
                                [readonly]="readonly"
                                infoText="{{existsSessionStorageTextbausteine?
                                      textbausteinService.printTextbausteinByKey( KeyString.VORMUNDLAND_INFO_FAHRKOSTEN, ('STANDARD.LAND_INFO'|translate) ):''}}"
                                (onchange)="this.setPLZValidatorForControl('vormundPlz')"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundGeburtsdatum')"
                             class="form-group group-for-vormundGeburtsdatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundGeburtsdatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundGeburtsdatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundGeburtsdatum', fieldConfig, 'STANDARD.GEBURTSDATUM' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.VORMUNDGEBURTSDATUM_INFO_FAHRKOSTEN, ('STANDARD.GEBURTSDATUM_VORMUND_INFO'|translate) ):''}}"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.maxDateVormundGeburtsdatum | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundGeschlecht')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="vormundGeschlecht"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [items]="optionGeschlecht"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.VORMUNDGESCHLECHT_INFO_FAHRKOSTEN, ('STANDARD.GESCHLECHT_INFO'|translate) ):''}}"
                                                 label="{{''+(this.effectiveValidators.includes('vormundGeschlecht')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundGeschlecht', fieldConfig, 'STANDARD.GESCHLECHT.TITEL' | translate )}}"
                                                 [readonly]="readonly"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundEmail')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundEmail"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundEmail')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundEmail', fieldConfig, 'STANDARD.EMAILADRESSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.VORMUNDEMAIL_INFO_FAHRKOSTEN, ('STANDARD.EMAIL_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundTelefonnummer')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundTelefonnummer"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('vormundTelefonnummer')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('vormundTelefonnummer', fieldConfig, 'TICKETDETAIL.SEPA.TELEFONNUMMER' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.VORMUNDTELEFONNUMMER_INFO_FAHRKOSTEN, ('STANDARD.TELEFONNUMMER_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende vormund-->

                      <!--konto-->
                      <div class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.KONTOANGABEN_TITEL_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.ANGABEN_KONTO'|translate) ):('FAHRKOSTEN-DETAIL.ANGABEN_KONTO'|translate)}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('iban')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="iban"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('iban')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('iban', fieldConfig, 'FAHRKOSTEN-DETAIL.IBAN' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.IBAN_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.IBAN'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('bic')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="bic"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('bic')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('bic', fieldConfig, 'FAHRKOSTEN-DETAIL.BIC' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.BIC_INFO_FAHRKOSTEN, ('STANDARD.BIC_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('isKontoinhaberAbweichend')"
                             class="form-group group-for-name col mt-2">
                          <div class="d-flex flex-row"
                               [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                              <label  class="checkbox-container"  style="margin-bottom:3ch;">
                                {{''+fieldConfigService.getFieldLabel('isKontoinhaberAbweichend',
                                fieldConfig, 'FAHRKOSTEN-DETAIL.KONTOINHABER_ABWEICHEND' | translate)}}
                                <input type="checkbox" class="control control-checkbox custom-control-input"
                                       formControlName="isKontoinhaberAbweichend"
                                       id="isKontoinhaberAbweichend"
                                       name="isKontoinhaberAbweichend"
                                >
                                <span class="checkmark"></span>
                              </label>
                            <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                      textbausteinService.printTextbausteinByKey( KeyString.KONTOINHABER_ABWEICHEND_INFO, ('FAHRKOSTEN-DETAIL.KONTOINHABER_ABWEICHEND_INFO'|translate)):'')"
                              class="infoIcon"
                                     [icon]="faInfo"
                                     title="{{existsSessionStorageTextbausteine?
                                      textbausteinService.printTextbausteinByKey( KeyString.KONTOINHABER_ABWEICHEND_INFO, ('FAHRKOSTEN-DETAIL.KONTOINHABER_ABWEICHEND_INFO'|translate)):''}}"
                            ></fa-icon>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberName')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberName"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('kontoinhaberName')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kontoinhaberName', fieldConfig, 'STANDARD.NACHNAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KONTOINHABERNAME_INFO_FAHRKOSTEN, ('STANDARD.NACHNAME'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberVorname')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberVorname"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('kontoinhaberVorname')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kontoinhaberVorname', fieldConfig, 'STANDARD.VORNAME' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KONTOINHABERVORNAME_INFO_FAHRKOSTEN, ('STANDARD.VORNAME'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberStrasse')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberStrasse"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('kontoinhaberStrasse')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kontoinhaberStrasse', fieldConfig, 'STANDARD.STRASSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KONTOINHABERSTRASSE_INFO_FAHRKOSTEN, ('STANDARD.STRASSE'|translate)):''}}"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberPlz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberPlz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('kontoinhaberPlz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kontoinhaberPlz', fieldConfig, 'STANDARD.PLZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KONTOINHABERPLZ_INFO_FAHRKOSTEN, ('STANDARD.PLZ'|translate)):''}}"
                                                [readonly]="readonly"
                                                [maxlength]="getMaxLength('kontoinhaberPlz')"
                                                [pattern]="getPattern('kontoinhaberPlz')"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberOrt')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberOrt"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('kontoinhaberOrt')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kontoinhaberOrt', fieldConfig, 'STANDARD.WOHNORT' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KONTOINHABERORT_INFO_FAHRKOSTEN, ('STANDARD.WOHNORT'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberAdresszusatz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberAdresszusatz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('kontoinhaberAdresszusatz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kontoinhaberAdresszusatz', fieldConfig, 'STANDARD.ADRESSZUSATZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KONTOINAHBERADRESSZUSATZ_INFO_FAHRKOSTEN, ('STANDARD.ADRESSZUSATZ_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberLand')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="kontoinhaberLand"
                                class="flex-grow-1"
                                [customFormGroup]="fahrkostenForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="''+(this.effectiveValidators.includes('kontoinhaberLand')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kontoinhaberLand', fieldConfig, 'STANDARD.LAND' | translate)"
                                [readonly]="readonly"
                                infoText="{{existsSessionStorageTextbausteine?
                                  textbausteinService.printTextbausteinByKey( KeyString.KONTOINHABERLAND_INFO_FAHRKOSTEN, ('STANDARD.LAND_INFO'|translate) ):''}}"
                                (onchange)="this.setPLZValidatorForControl('kontoinhaberPlz')"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberGeburtsdatum')"
                             class="form-group group-for-kontoinhaberGeburtsdatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberGeburtsdatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('kontoinhaberGeburtsdatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kontoinhaberGeburtsdatum', fieldConfig, 'STANDARD.GEBURTSDATUM' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KONTOINHABERGEBURTSDATUM_INFO_FAHRKOSTEN, ('STANDARD.GEBURTSDATUM_KONTO_INFO'|translate) ):''}}"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende konto-->

                      </div>
                    <!--ENDE LINKS-->

                    <!--RECHTS-->
                    <div class="col-md-6">

                      <!--anfang erstattung-->
                      <div class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.ANGABEN_ERSTATTUNG_TITEL_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.ERSTATTUNGSANLASS'|translate) ):('FAHRKOSTEN-DETAIL.ERSTATTUNGSANLASS'|translate)}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('typ')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="typ"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 [items]="optionTyp"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.ANTRAGSART_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.TYP_INFO'|translate) ):''}}"
                                                 label="{{''+(this.effectiveValidators.includes('typ')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('typ', fieldConfig, 'FAHRKOSTEN-DETAIL.ANTRAGSART' | translate )}}"
                                                 [readonly]="readonly||readonlyTyp">
                              </app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('wegtyp')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="wegtyp"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 [items]="optionWegtyp"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.WEGTYP_INFO_FAHRKOSTEN, ('STANDARD.WEGTYP_INFO'|translate) ):''}}"
                                                 label="{{''+(this.effectiveValidators.includes('wegtyp')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('wegtyp', fieldConfig, 'FAHRKOSTEN-DETAIL.WEGTYP.TITEL' | translate )}}"
                                                 [readonly]="readonly">
                              </app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('befoerderung')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="befoerderung"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 [items]="optionBefoerderung"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.BEFOERDERUNG_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.BEFOERDERUNG_INFO'|translate) ):''}}"
                                                 label="{{''+(this.effectiveValidators.includes('befoerderung')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('befoerderung', fieldConfig, 'BEFOERDERUNG.TITEL' | translate )}}"
                                                 [readonly]="readonly"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('mitfahrerBei')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="mitfahrerBei"
                                                [customFormGroup]="fahrkostenForm"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.MITFAHRER_BEI_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.MITFAHRER_BEI_INFO'|translate) ):''}}"
                                                [label]="''+(this.effectiveValidators.includes('mitfahrerBei')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('mitfahrerBei', fieldConfig, 'FAHRKOSTEN-DETAIL.MITFAHRER' | translate)"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kennzeichen')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kennzeichen"
                                                [customFormGroup]="fahrkostenForm"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KENNZEICHEN_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.KENNZEICHEN_INFO'|translate) ):''}}"
                                                [label]="''+(this.effectiveValidators.includes('kennzeichen')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kennzeichen', fieldConfig, 'FAHRKOSTEN-DETAIL.KENNZEICHEN' | translate)"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('erstattungszeitraumVon')"
                             class="form-group group-for-erstattungszeitraumVon col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="erstattungszeitraumVon"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('erstattungszeitraumVon')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('erstattungszeitraumVon', fieldConfig, 'FAHRKOSTEN-DETAIL.ERSTATTUNG_VON' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.ERSTATTUNGSZEITRAUM_VON_INFO_FAHRKOSTEN, ''):''}}"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDate | date:'yyyy-MM-dd'"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('erstattungszeitraumBis')"
                             class="form-group group-for-erstattungszeitraumBis col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="erstattungszeitraumBis"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('erstattungszeitraumBis')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('erstattungszeitraumBis', fieldConfig, 'FAHRKOSTEN-DETAIL.ERSTATTUNG_BIS' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.ERSTATTUNGSZEITRAUM_BIS_INFO_FAHRKOSTEN, ''):''}}"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDateErstattungszeitraumBis | date:'yyyy-MM-dd'"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('krankheitstage')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="krankheitstage"
                                                type="number"
                                                min="0"
                                                max="31"
                                                [customFormGroup]="fahrkostenForm"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KRANKHEITSTAGE_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.KRANKHEITSTAGE_INFO'|translate) ):''}}"
                                                [label]="''+(this.effectiveValidators.includes('krankheitstage')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('krankheitstage', fieldConfig, 'FAHRKOSTEN-DETAIL.KRANKHEITSTAGE' | translate)"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('fehltage')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="fehltage"
                                                type="number"
                                                min="0"
                                                max="31"
                                                [customFormGroup]="fahrkostenForm"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.FEHLTAGE_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.FEHLTAGE_INFO'|translate) ):''}}"
                                                [label]="''+(this.effectiveValidators.includes('fehltage')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('fehltage', fieldConfig, 'FAHRKOSTEN-DETAIL.FEHLTAGE' | translate)"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('entfernung')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input-german-decimal id="entfernung"
                                                               [customFormGroup]="fahrkostenForm"
                                                               [label]="''+(this.effectiveValidators.includes('entfernung')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('entfernung', fieldConfig, 'FAHRKOSTEN-DETAIL.STRECKE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.ENTFERNUNG_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.STRECKE'|translate)):''}}"
                                                               [readonly] = "readonly"
                                                               [placeholder]="'0,000'"
                                                               [extra_field_text]="'STANDARD.METRIC'  | translate"
                                                               [numDigits]="3"
                              ></app-custom-input-german-decimal>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kosten')"
                             class="form-group group-for-kosten col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input-german-decimal id="kosten"
                                                               [customFormGroup]="fahrkostenForm"
                                                               [label]="''+(this.effectiveValidators.includes('kosten')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('kosten', fieldConfig, 'FAHRKOSTEN-DETAIL.KOSTEN' | translate)"
                                                               infoText="{{existsSessionStorageTextbausteine?
                                                                  textbausteinService.printTextbausteinByKey( KeyString.KOSTEN_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.KOSTEN_INFO'|translate) ):''}}"
                                                               [readonly] = "readonly"
                                                               [placeholder]="'0,00'"
                                                               [extra_field_text]="'STANDARD.Currency' | translate"
                                                               [numDigits]="2"
                              ></app-custom-input-german-decimal>
                            </div>
                          </div>
                        </div>
                        <!--upload-->
                        <div *ngIf="effectivelyVisible.includes('fahrkostenNachweis')"
                             class="form-group group-for-fahrkostenNachweis col mb-4">
                          <div *ngIf="!readonly"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="fahrkostenNachweis"
                                                [customFormGroup]="fahrkostenForm"
                                                name="fahrkostenNachweis"
                                                [label]="''+(this.effectiveValidators.includes('fahrkostenNachweis')?pflichtFeldMarker:'')+ fieldConfigService.getFieldLabel('fahrkostenNachweis', fieldConfig, 'FAHRKOSTEN-DETAIL.FAHRKOSTEN-NACHWEIS' | translate)"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('fahrkostenNachweis')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [readonly]="readonly"
                                                data-cy="fahrkostenNachweis">
                              </app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!fahrkostenNachweis)"
                               class="d-flex flex-row mt-2">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="fahrkostenNachweisThumb">
                                {{ fieldConfigService.getFieldLabel('fahrkostenNachweis', fieldConfig, "FAHRKOSTEN-DETAIL.FAHRKOSTEN-NACHWEIS" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2" id="fahrkostenNachweisThumb">
                                <img *ngIf="!fahrkostenNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(fahrkostenNachweis)"
                                     [src]="sanitize(fahrkostenNachweis)"
                                     alt="img"/>
                                <pdf-viewer *ngIf="fahrkostenNachweisIsPDF"
                                            [src]="fahrkostenNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(fahrkostenNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.fahrkosten"
                                       [icon]=faNope
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('fahrkostenNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--ende erstattung-->

                      <!--anfang schule-->
                      <div class="flex-row sub-paragraph ml-2 mr-2">
                        <h4 class="mt-1 mb-3">
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.ANGABEN_SCHULE_TITEL_FAHRKOSTEN, ('STANDARD.ANGABEN_SCHULE'|translate) ):('STANDARD.ANGABEN_SCHULE'|translate)}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('schule')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="schule"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="fahrkostenForm"
                                                 [placeholder]="(!this.fahrkosten||this.fahrkosten?.status == draftStatus)?'-':this.fahrkosten?.schule?.toString()"
                                                 [items]="schuleList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="name"
                                                 infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.SCHULE_INFO_FAHRKOSTEN, ''):''}}"
                                                 label="{{''+(this.effectiveValidators.includes('schule')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('schule', fieldConfig, 'STANDARD.SCHULE' |translate) }} "
                                                 [readonly]="readonly"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div  *ngIf="getFormItem('isBerufskolleg').value==true && arrayContainsAnyFrom(effectivelyVisible,['bildungsgang','bildungsgangdatum','fachbereich'])"
                              [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                            <label class="checkbox-container"  style="margin-bottom:3ch;">
                              <strong>{{'STANDARD.BERUFSKOLLEG' | translate}}</strong>
                              <fa-icon *ngIf="existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.BERUFSKOLLEG_INFO_FAHRKOSTEN,('FAHRKOSTEN-DETAIL.BERUFSKOLLEG_INFO_FAHRKOSTEN'|translate)):''"
                                class="infoIcon"
                                       [icon]="faInfo"
                                       title="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.BERUFSKOLLEG_INFO_FAHRKOSTEN,('FAHRKOSTEN-DETAIL.BERUFSKOLLEG_INFO_FAHRKOSTEN'|translate)):''}}">
                              </fa-icon>
                              <input type="checkbox" class="control control-checkbox custom-control-input"
                                     formControlName="isBerufskolleg"
                                     id="isBerufskolleg"
                                     name="isBerufskolleg"
                                     onclick="return false;"
                              >
                              <span class="checkmark"></span>
                            </label>
                        </div>

                        <div *ngIf="effectivelyVisible.includes('schuljahr')"
                             class="form-group group-for-schuljahr  col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="schuljahr"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('schuljahr')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('schuljahr', fieldConfig, 'STANDARD.SCHULJAHR' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.SCHULJAHR_INFO_FAHRKOSTEN, ('STANDARD.SCHULJAHR'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('klasse')"
                             class="form-group group-for-name col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="klasse"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('schule')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('klasse', fieldConfig, 'STANDARD.SCHULKLASSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.KLASSE_INFO_FAHRKOSTEN, ('STANDARD.SCHULKLASSE'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>

                        <!--bildungsgang-->
                        <div  *ngIf="arrayContainsAnyFrom(effectivelyVisible,['bildungsgang','bildungsgangdatum','fachbereich'])"
                              class="form-group group-for-bildungsgangFields col mt-0  mb-0">
                          <div *ngIf="effectivelyVisible.includes('bildungsgang')"
                               class="form-group group-for-bildungsgang col mt-0 mb-0">
                            <div class="d-flex flex-row">
                              <div class="flex-grow-1 mr-2">
                                <app-custom-input id="bildungsgang"
                                                  [customFormGroup]="fahrkostenForm"
                                                  [label]="''+(this.effectiveValidators.includes('bildungsgang')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('bildungsgang', fieldConfig, 'STANDARD.BILDUNGSGANG' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.BILDUNGSGANG_INFO_FAHRKOSTEN, ('STANDARD.BILDUNGSGANG'|translate)):''}}"
                                                  [readonly]="readonly"
                                ></app-custom-input>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="effectivelyVisible.includes('bildungsgangdatum')"
                               class="form-group group-for-bildungsgangdatum col mt-0 mb-0">
                            <div class="d-flex flex-row">
                              <div class="flex-grow-1 mr-2">
                                <app-custom-input id="bildungsgangdatum"
                                                  type="date"
                                                  [customFormGroup]="fahrkostenForm"
                                                  [label]="''+(this.effectiveValidators.includes('bildungsgangdatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('bildungsgangdatum', fieldConfig, 'FAHRKOSTEN-DETAIL.BILDUNGSGANG_DATUM' | translate)"
                                                  [readonly]="readonly"
                                                  infoText="{{existsSessionStorageTextbausteine?
                                                      textbausteinService.printTextbausteinByKey( KeyString.BILDUNGSGANGDATUM_INFO_FAHRKOSTEN, ''):''}}"
                                                  data-content="dd.mm.yyyy"
                                                  [max]="this.today | date:'yyyy-MM-dd'"
                                                  [min]="this.minDate | date:'yyyy-MM-dd'"
                                ></app-custom-input>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="effectivelyVisible.includes('fachbereich')"
                               class="form-group group-for-name col mt-0  mb-0">
                            <div class="d-flex flex-row">
                              <div class="flex-grow-1 mr-2">
                                <app-custom-input id="fachbereich"
                                                  [customFormGroup]="fahrkostenForm"
                                                  [label]="''+(this.effectiveValidators.includes('fachbereich')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('fachbereich', fieldConfig, 'FAHRKOSTEN-DETAIL.FACHBEREICH' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.FACHBEREICH_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.FACHBEREICH'|translate)):''}}"
                                                  [readonly]="readonly"
                                ></app-custom-input>
                              </div>
                            </div>
                          </div>

                        </div>
                        <!--ende bildungsgang-->

                        <div *ngIf="effectivelyVisible.includes('schultageJeWoche')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="schultageJeWoche"
                                                type="number"
                                                min="0"
                                                max="6"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('schultageJeWoche')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('schultageJeWoche', fieldConfig, 'FAHRKOSTEN-DETAIL.SCHULTAGEJEWOCHE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.SCHULTAGEJEWOCHE_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.SCHULTAGEJEWOCHE'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('unterrichtszeitVon')"
                             class="form-group group-for-name col mt-0  mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="unterrichtszeitVon"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('unterrichtszeitVon')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('unterrichtszeitVon', fieldConfig, 'FAHRKOSTEN-DETAIL.UNTERRICHT_VON' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.UNTERRICHTSZEITENVON_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.UNTERRICHT_VON'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('unterrichtszeitBis')"
                             class="form-group group-for-name col mt-0  mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="unterrichtszeitBis"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('unterrichtszeitBis')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('unterrichtszeitBis', fieldConfig, 'FAHRKOSTEN-DETAIL.UNTERRICHT_BIS' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.UNTERRICHTSZEITENBIS_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.UNTERRICHT_BIS'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--ende schule-->

                      <!--anfang praktikum-->
                      <div *ngIf="arrayContainsAnyFrom(effectivelyVisible,praktikumOnOff)===true"
                        class="flex-row sub-paragraph ml-2 mr-2">
                        <h4 class="mt-1 mb-3">
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.ANGABEN_PRAKTIKUM_TITLE_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.ANGABEN_ZUM_PRAKTIKUM'|translate) ):('FAHRKOSTEN-DETAIL.ANGABEN_ZUM_PRAKTIKUM'|translate) }}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('praktikumHinweis')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <label for="praktikumHinweis">
                                {{''+fieldConfigService.getFieldLabel('praktikumHinweis', fieldConfig, 'FAHRKOSTEN-DETAIL.PRAKTIKUM_HINWEIS' | translate)}}
                              </label>
                              <div class="alert alert-warning" id="praktikumHinweis">
                                {{existsSessionStorageTextbausteine?
                                textbausteinService.printTextbausteinByKey( KeyString.FAHRKOSTEN_PRAKTIKUM_HINWEIS,
                                  ('STANDARD.PRAKTIKUM_INFO'|translate)):('STANDARD.PRAKTIKUM_INFO'|translate)}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumStelle')"
                             class="form-group group-for-name col mt-0  mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStelle"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumStelle')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumStelle', fieldConfig, 'FAHRKOSTEN-DETAIL.PRAKTIKUMSTELLE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSTELLE_INFO_FAHRKOSTEN, ('STANDARD.PRAKTIKUMSTELLE_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumStrasse')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStrasse"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumStrasse')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumStrasse', fieldConfig, 'STANDARD.STRASSE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSTRASSE_INFO_FAHRKOSTEN, ('STANDARD.STRASSE'|translate)):''}}"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumPlz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumPlz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumPlz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumPlz', fieldConfig, 'STANDARD.PLZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUM_PLZ_INFO_FAHRKOSTEN, ('STANDARD.PLZ'|translate)):''}}"
                                                [readonly]="readonly"
                                                [maxlength]="getMaxLength('praktikumPlz')"
                                                [pattern]="getPattern('praktikumPlz')"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumOrt')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumOrt"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumOrt')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumOrt', fieldConfig, 'STANDARD.ORT' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMORT_INFO_FAHRKOSTEN, ('STANDARD.ORT'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumAdresszusatz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumAdresszusatz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumAdresszusatz')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumAdresszusatz', fieldConfig, 'STANDARD.ADRESSZUSATZ' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                    textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUM_ADRESSZUSATZ_INFO_FAHRKOSTEN, ('STANDARD.ADRESSZUSATZ_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumLand')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="praktikumLand"
                                class="flex-grow-1"
                                [customFormGroup]="fahrkostenForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="''+(this.effectiveValidators.includes('praktikumLand')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumLand', fieldConfig, 'STANDARD.LAND' | translate)"
                                [readonly]="readonly"
                                infoText="{{existsSessionStorageTextbausteine?
                                    textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUM_LAND_INFO_FAHRKOSTEN, ('STANDARD.LAND_INFO'|translate) ):''}}"
                                (onchange)="this.setPLZValidatorForControl('praktikumPlz')"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumStartdatum')"
                             class="form-group group-for-praktikumStartdatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStartdatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumStartdatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumStartdatum', fieldConfig, 'FAHRKOSTEN-DETAIL.PRAKTIKUM.STARTDATUM' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSTARTDATUM_INFO_FAHRKOSTEN, ''):''}}"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDate | date:'yyyy-MM-dd'"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumEnddatum')"
                             class="form-group group-for-praktikumEnddatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumEnddatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumEnddatum')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumEnddatum', fieldConfig, 'FAHRKOSTEN-DETAIL.PRAKTIKUM.ENDDATUM' | translate)"
                                                [readonly]="readonly"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMENDDATUM_INFO_FAHRKOSTEN, ''):''}}"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDatePraktikumEnddatum | date:'yyyy-MM-dd'"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumstageJeWoche')"
                             class="form-group group-for-praktikumstageJeWoche col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumstageJeWoche"
                                                type="number"
                                                min="0"
                                                max="6"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="''+(this.effectiveValidators.includes('praktikumstageJeWoche')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('praktikumstageJeWoche', fieldConfig, 'FAHRKOSTEN-DETAIL.PRAKTIKUMSTAGEJEWOCHE' | translate)"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.PRAKTIKUMSTAGEJEWOCHE_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.PRAKTIKUMSTAGEJEWOCHE'|translate)):''}}"
                                                [readonly]="readonly"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <!--upload-->
                        <div *ngIf="effectivelyVisible.includes('praktikumsvertrag')"
                             class="form-group group-for-upload col mt-0">
                          <div *ngIf="!readonly" class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumsvertrag"
                                                [customFormGroup]="fahrkostenForm"
                                                name="praktikumsvertrag"
                                                [label]="''+(this.effectiveValidators.includes('praktikumsvertrag')?pflichtFeldMarker:'')+ fieldConfigService.getFieldLabel('praktikumsvertrag', fieldConfig, 'TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSVERTRAG' | translate)"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('praktikumsvertrag')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [readonly]="readonly"
                                                data-cy="praktikumsvertrag"
                              ></app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!praktikumsvertrag)"
                               class="d-flex flex-row mt-0">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="praktikumsvertragThumb">
                                {{ fieldConfigService.getFieldLabel('praktikumsvertrag', fieldConfig, "TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSVERTRAG" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2"
                                   id="praktikumsvertragThumb">
                                <img *ngIf="!praktikumsvertragIsPDF"
                                     data-ot-ignore
                                     [src]="sanitize(praktikumsvertrag)"
                                     (click)="previewImg(praktikumsvertrag)"
                                     alt="img"/>
                                <pdf-viewer *ngIf="praktikumsvertragIsPDF"
                                            [src]="praktikumsvertrag"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(praktikumsvertrag)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.fahrkosten"
                                       [icon]=faNope
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('praktikumsvertrag')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--ende praktikum-->

                      <!--anfang Sonderfall -->
                      <div class="flex-row sub-paragraph ml-2 mr-2" *ngIf="effectivelyVisible.includes('sonderfall')">
                        <h4 class="mt-1 mb-3">
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.GRUENDE_UEBERNAHME_TITEL_FAHRKOSTEN,
                            ('FAHRKOSTEN-DETAIL.SONDERFALL.TITEL'|translate) ):('FAHRKOSTEN-DETAIL.SONDERFALL.TITEL'|translate)}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('sonderfall')"
                             class="form-group group-for-name col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-radio label="{{''+(this.effectiveValidators.includes('sonderfall')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('sonderfall', fieldConfig, 'FAHRKOSTEN-DETAIL.SONDERFALL.SONDERFAELLE' |translate) }} "
                                                id="sonderfall"
                                                [options]="optionSonderfall"
                                                [customFormGroup]="fahrkostenForm"
                                                infoText="{{existsSessionStorageTextbausteine?
                                                  textbausteinService.printTextbausteinByKey( KeyString.SONDERFALL_INFO_FAHRKOSTEN, ('STANDARD.SONDERFALL_INFO'|translate) ):''}}"
                                                [readonly]="readonly"
                                                [ngClass]="{'form-check-input-disabled' : readonly, 'form-check-input-active' : !readonly}"
                              ></app-custom-radio>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('sonderfallBegruendung')"
                             class="form-group group-for-name col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-textarea
                                class="flex-grow-1"
                                [label]="''+(this.effectiveValidators.includes('sonderfallBegruendung')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('sonderfallBegruendung', fieldConfig, 'FAHRKOSTEN-DETAIL.SONDERFALL.BEGRUENDUNG' | translate)"
                                [rows]="5"
                                [customFormGroup]="fahrkostenForm"
                                infoText="{{existsSessionStorageTextbausteine?
                                  textbausteinService.printTextbausteinByKey( KeyString.SONDERFALL_BEGRUEDUNG_INFO_FAHRKOSTEN, ''):''}}"
                                id="sonderfallBegruendung"
                                [readonly]="readonly">
                              </app-textarea>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="arrayContainsAnyFrom(effectivelyVisible,['sonderfallSchuleAusserhalb','sonderfallSchulungsmoeglichkeit','sonderfallFamilienheimfahrten'])===true"
                             class="form-group group-for-name col mt-0 mb-0">
                          <label for="sonderfallAusserhalbContent">
                            {{''+fieldConfigService.getFieldLabel('sonderfallAusserhalbContent',
                            fieldConfig, 'FAHRKOSTEN-DETAIL.SONDERFALL.NOTNRW' | translate)}}
                          </label>
                          <div class="d-flex flex-row sub-paragraph ml-2 mr-2" id="sonderfallAusserhalbContent">
                            <div class="flex-grow-1 mr-2">
                              <div class="d-flex flex-row" style="margin-top:2ch;margin-bottom:2ch;" *ngIf="effectivelyVisible.includes('sonderfallSchuleAusserhalb')"
                                   [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                                <label class="checkbox-container">
                                  {{''+fieldConfigService.getFieldLabel('sonderfallSchuleAusserhalb',
                                  fieldConfig, 'FAHRKOSTEN-DETAIL.SONDERFALL.SCHULE_AUSSERHALB' | translate)}}
                                  <input type="checkbox" class="control control-checkbox custom-control-input"
                                         formControlName="sonderfallSchuleAusserhalb"
                                         id="sonderfallSchuleAusserhalb"
                                         name="sonderfallSchuleAusserhalb"
                                         (change)="sonderfallTypChange()"
                                  >
                                  <span class="checkmark"></span>
                                </label>
                                <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                        textbausteinService.printTextbausteinByKey( KeyString.SONDERFALL_SCHULE_AUSSERHALB_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.SONDERFALL.SCHULE_AUSSERHALB_INFO'|translate) ):'')"
                                  class="infoIcon"
                                         [icon]="faInfo"
                                         title="{{existsSessionStorageTextbausteine?
                                        textbausteinService.printTextbausteinByKey( KeyString.SONDERFALL_SCHULE_AUSSERHALB_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.SONDERFALL.SCHULE_AUSSERHALB_INFO'|translate) ):''}}">
                                </fa-icon>
                              </div>
                              <div class="d-flex flex-row" style="margin-top:2ch;margin-bottom:2ch;" *ngIf="effectivelyVisible.includes('sonderfallSchulungsmoeglichkeit')"
                                   [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                                <label class="checkbox-container">
                                  {{''+fieldConfigService.getFieldLabel('sonderfallSchulungsmoeglichkeit',
                                  fieldConfig, 'FAHRKOSTEN-DETAIL.SONDERFALL.SCHULUNGSMOEGLICHKEIT' | translate)}}
                                  <input type="checkbox" class="control control-checkbox custom-control-input"
                                         formControlName="sonderfallSchulungsmoeglichkeit"
                                         id="sonderfallSchulungsmoeglichkeit"
                                         name="sonderfallSchulungsmoeglichkeit"
                                         (change)="sonderfallTypChange()"
                                  >
                                  <span class="checkmark"></span>
                                </label>
                                <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                          textbausteinService.printTextbausteinByKey( KeyString.SONDERFALL_SCHULUNGSMOEGLICHKEIT_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.SONDERFALL.SCHULUNGSMOEGLICHKEIT_INFO'|translate) ):'')"
                                  class="infoIcon" [icon]="faInfo"
                                         title="{{existsSessionStorageTextbausteine?
                                          textbausteinService.printTextbausteinByKey( KeyString.SONDERFALL_SCHULUNGSMOEGLICHKEIT_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.SONDERFALL.SCHULUNGSMOEGLICHKEIT_INFO'|translate) ):''}}">
                                </fa-icon>
                              </div>
                              <div class="d-flex flex-row" style="margin-top:2ch;margin-bottom:2ch;" *ngIf="effectivelyVisible.includes('sonderfallFamilienheimfahrten')"
                                   [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                                <label class="checkbox-container">
                                  {{''+fieldConfigService.getFieldLabel('sonderfallFamilienheimfahrten',
                                  fieldConfig, 'FAHRKOSTEN-DETAIL.SONDERFALL.FAMILIENHEIMFAHRTEN' | translate)}}
                                  <input type="checkbox" class="control control-checkbox custom-control-input"
                                         formControlName="sonderfallFamilienheimfahrten"
                                         id="sonderfallFamilienheimfahrten"
                                         name="sonderfallFamilienheimfahrten"
                                         (change)="sonderfallTypChange()"
                                  >
                                  <span class="checkmark"></span>
                                </label>
                                <fa-icon *ngIf="existsSessionStorageTextbausteine?
                                          textbausteinService.printTextbausteinByKey( KeyString.SONDERFALL_FAMILIENHEIMFAHRTEN_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.SONDERFALL.FAMILIENHEIMFAHRTEN_INFO'|translate) ):''"
                                  class="infoIcon" [icon]="faInfo"
                                         title="{{existsSessionStorageTextbausteine?
                                          textbausteinService.printTextbausteinByKey( KeyString.SONDERFALL_FAMILIENHEIMFAHRTEN_INFO_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.SONDERFALL.FAMILIENHEIMFAHRTEN_INFO'|translate) ):''}}">
                                </fa-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--upload-->
                        <div *ngIf="effectivelyVisible.includes('sonderfallNachweis')"
                             class="form-group group-for-sonderfallNachweis col mb-4">
                          <div *ngIf="!readonly"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="sonderfallNachweis"
                                                [customFormGroup]="fahrkostenForm"
                                                name="sonderfallNachweis"
                                                [label]="''+(this.effectiveValidators.includes('sonderfallNachweis')?pflichtFeldMarker:'')+ fieldConfigService.getFieldLabel('sonderfallNachweis', fieldConfig, 'TICKETDETAIL.SONDERFALL.NACHWEIS' | translate)"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('sonderfallNachweis')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [readonly]="readonly"
                                                data-cy="sonderfallNachweis"
                              ></app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!!sonderfallNachweis"
                               class="d-flex flex-row mt-2">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="sonderfallNachweisThumb">
                                {{ fieldConfigService.getFieldLabel('sonderfallNachweis', fieldConfig, "TICKETDETAIL.SONDERFALL.NACHWEIS" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2" id="sonderfallNachweisThumb">
                                <img *ngIf="!sonderfallNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(sonderfallNachweis)"
                                     [src]="sanitize(sonderfallNachweis)"
                                     alt="img"/>
                                <pdf-viewer *ngIf="sonderfallNachweisIsPDF"
                                            [src]="sonderfallNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(sonderfallNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.fahrkosten"
                                       [icon]=faNope
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('sonderfallNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--ende Sonderfall-->

                      <!--anfang Asyl-->
                      <div *ngIf="effectivelyVisible.includes('bezugAsylbewerberleistungsgesetz')" class="flex-row sub-paragraph ml-2 mr-2">
                        <h4 class="mt-1 mb-3">
                          {{existsSessionStorageTextbausteine?
                          textbausteinService.printTextbausteinByKey( KeyString.GRUENDE_ERHOEHTE_TITEL_FAHRKOSTEN, ('FAHRKOSTEN-DETAIL.BESONDERE_GRUENDE_KOSTEN'|translate) ):('FAHRKOSTEN-DETAIL.BESONDERE_GRUENDE_KOSTEN'|translate)}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('bezugAsylbewerberleistungsgesetz')"
                             class="form-group group-for-name col mt-2">
                          <div class="d-flex flex-row"
                             [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                            <label class="checkbox-container">{{fieldConfigService.getFieldLabel('bezugAsylbewerberleistungsgesetz', fieldConfig, 'TICKETDETAIL.BEZUG_ASYLBEWERBERLEISTUNGSGESETZ' | translate)}}
                              <input type="checkbox" class="control control-checkbox custom-control-input"
                                     formControlName="bezugAsylbewerberleistungsgesetz"
                                     id="bezugAsylbewerberleistungsgesetz"
                                     name="bezugAsylbewerberleistungsgesetz"
                              >
                              <span class="checkmark"></span>
                            </label>
                            <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                        this.textbausteinService.printTextbausteinByKey( KeyString.ASYL_INFO_FAHRKOSTEN, ('STANDARD.ASYL_INFO'|translate) ):'')"
                              class="infoIcon"
                                     [icon]="faInfo"
                                     title="{{existsSessionStorageTextbausteine?
                                        this.textbausteinService.printTextbausteinByKey( KeyString.ASYL_INFO_FAHRKOSTEN, ('STANDARD.ASYL_INFO'|translate) ):''}}"
                            ></fa-icon>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('bezugNachweis')"
                             class="form-group group-for-bezugNachweis col mb-4 mt-4">
                          <div *ngIf="!readonly"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="bezugNachweis"
                                                [customFormGroup]="fahrkostenForm"
                                                name="bezugNachweis"
                                                [label]="''+(this.effectiveValidators.includes('bezugNachweis')?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('bezugNachweis', fieldConfig, 'TICKETDETAIL.BEZUG_NACHWEIS' | translate)"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('bezugNachweis')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [readonly]="readonly"
                                                data-cy="bezugNachweis"
                              ></app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!bezugNachweis)"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="bezugNachweisThumb">
                                {{ fieldConfigService.getFieldLabel('bezugNachweis', fieldConfig, "TICKETDETAIL.BEZUG_NACHWEIS" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2" id="bezugNachweisThumb">
                                <img *ngIf="!bezugNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(bezugNachweis)"
                                     [src]="sanitize(bezugNachweis)"
                                     alt="img"/>
                                <pdf-viewer *ngIf="bezugNachweisIsPDF"
                                            [src]="bezugNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(bezugNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.fahrkosten"
                                       [icon]=faNope
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('bezugNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>
                        <!--ende bezugnachweis-->

                      </div>
                      <!--ende Asyl-->

                    </div>
                    <!--ENDE RECHTS-->

                  </div>
                  <!--ende teil Angaben -->

                  <!-- anfang belegupload -->
                  <div *ngIf="cardsFkkUniversalOn===true">

                    <hr class="hr-fahrkosten">
                    <legend>
                      {{existsSessionStorageTextbausteine?
                      textbausteinService.printTextbausteinByKey( KeyString.KOSTEN_NACHWEISE_TITEL_FAHRKOSTEN, ("FAHRKOSTEN-DETAIL.LEGEND-NACHWEISE"|translate) ):("FAHRKOSTEN-DETAIL.LEGEND-NACHWEISE" | translate)}}
                    </legend>
                    <div class="row">
                      <span style="margin: 0 0 20px 15px" *ngIf="!readonly">
                          {{existsSessionStorageTextbausteine?
                        textbausteinService.printTextbausteinByKey( KeyString.NACHWEISHINZUFUEGEN_INFO_FAHRKOSTEN, ("FAHRKOSTEN-DETAIL.LEGEND-NACHWEISE-INFO"|translate) ):("FAHRKOSTEN-DETAIL.LEGEND-NACHWEISE-INFO"|translate)}}
                      </span>
                    </div>
                    <div class="row justify-content-evenly">
                      <div class="row ml-2 cardAdd mb-3" *ngIf="!readonly">
                        <h5 (click)="addFahrkostenKostenUniversalCard()">
                          <fa-icon  id="nachweisAddBtn"
                                    type="btn btn-secondary-icon" [icon]="faCirclePlus"
                                    class="fa-xl custom-card-btn-nachweisAdd mr-2"
                                    title = "{{existsSessionStorageTextbausteine?
                                      textbausteinService.printTextbausteinByKey( KeyString.NACHWEISHINZUFUEGEN_INFO_FAHRKOSTEN, '' ):''}}"
                          ></fa-icon>
                          <label for="nachweisAddBtn" style="cursor: pointer;">
                            {{ 'FAHRKOSTEN-DETAIL.NACHWEIS-HINZUFUEGEN' | translate }}
                          </label>
                        </h5>
                      </div>
                      <div  class="col-lg-4 col-md-6 col-sm-8 mb-1"
                            *ngFor="let fkKosten of getFormItem('fahrkostenKostenUniversalList').value" style="padding-bottom:1rem">
                        <app-fahrkosten-universal-sb-false [fahrkostenId]="this.fahrkostenId? this.fahrkostenId : undefined"
                                                  [kostenId] = "fkKosten.kostenId"
                                                  [fkkUniversal]="fkKosten"
                                                  [readonly]="readonly"
                                                  [hasSB]="false"
                                                  [arrayIndex]="getArrayIndexOf(fkKosten)"
                                                  [fieldConfig]="fieldConfigFkkUniversal"
                                                  [visibleFields]="visibleFieldsFkkUniversal"
                                                  [requiredFields]="requiredFieldsFkkUniversal"
                                                  [isSubmitted]="isSubmitted"
                                                  [flipFlop]="flipFlop"
                                                  (removeFromList)="removeFahrkostenKostenUniversalCard($event)"
                        ></app-fahrkosten-universal-sb-false>
                      </div>
                    </div>
                  </div>
                  <!-- ende belegupload -->

                  <hr class="hr-fahrkosten">

                  <!--ACCEPT-->
                  <div *ngIf="effectivelyVisible.includes('acceptTerms')"
                       class="form-group group-for-name col mt-2">
                    <label for="acceptTermsGroup">
                      <strong>{{'FAHRKOSTEN-DETAIL.BEACHTEN' | translate}}</strong>
                    </label>
                    <div class="d-flex flex-row" id="acceptTermsGroup"
                         [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                        <label class="checkbox-container"  style="margin-bottom:3ch;">
                          {{'FAHRKOSTEN-DETAIL.DATENSCHUTZ' | translate}}
                          <input type="checkbox" class="control control-checkbox custom-control-input"
                                 formControlName="acceptTerms"
                                 id="acceptTerms"
                                 name="acceptTerms"
                          >
                          <span class="checkmark"></span>
                        </label>
                      <fa-icon *ngIf="!!(existsSessionStorageTextbausteine?
                                      textbausteinService.printTextbausteinByKey( KeyString.DATENSCHUTZ_INFO_FAHRKOSTEN, ('STANDARD.DATENSCHUTZ_INFO'|translate)):'')"
                        class="infoIcon"
                               [icon]="faInfo"
                               title="{{existsSessionStorageTextbausteine?
                                      textbausteinService.printTextbausteinByKey( KeyString.DATENSCHUTZ_INFO_FAHRKOSTEN, ('STANDARD.DATENSCHUTZ_INFO'|translate)):''}}"
                      ></fa-icon>
                    </div>
                  </div>

                  <!--BUTTONS-->
                  <div class="d-flex flex-row-reverse">
                    <input *ngIf="isDeleteDraftButtonVisible" type="button" value="Entwurf löschen"
                           class="btn btn-secondary save btn-block" (click)="deleteDraft()">
                    <span style="width: 1em"></span>
                    <input *ngIf="isUpdateEntwurfButtonVisible" type="button" value="Entwurf speichern"
                           class="btn btn-secondary save btn-block"
                           (click)="saveOrUpdate('ENTWURF','Entwurf wurde erfolgreich gespeichert.', 'fahrkosten-detail')">
                    <span style="width: 1em"></span>
                    <input *ngIf="isEinreichenButtonVisible" type="button" value="Antrag einreichen"
                           class="btn btn-primary save btn-block"
                           (click)="saveOrUpdate('EINGEREICHT','Antrag wurde erfolgreich eingereicht.','fahrkosten')">
                  </div>

                </form>
              </div>
            </div>
            <!--ende form-->

          </div>
        </div>
      </div>
    </main>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen] = "true"
  >
  </ngx-spinner>
</main>
