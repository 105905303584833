import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AttachmentType} from "../../../../../models/antrag-ticket/AttachmentType";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AntragTicketService} from "../../../../../@core/services/antrag-ticket/antrag-ticket.service";
import {CustomToastService} from "../../../../../@core/utils/custom-toast.service";
import {AntragAttachmentCreateDto} from "../../../../../models/antrag-ticket/AntragAttachmentCreateDto";
import {CompositMessageService} from "../../../../../@core/services/compositMessage/compositMessage.service";
import {ImageHelperService} from "../../../../../@core/utils/forms/image-helper.service";

@Component({
  selector: 'app-attachment-upload',
  templateUrl: './ticket-antrag-attachment-upload.component.html',
  styleUrl: './ticket-antrag-attachment-upload.component.scss'
})
export class TicketAntragAttachmentUploadComponent implements OnInit{

  @Input()
  antragId: string;
  @Input()
  attachmentTypeSelection:AttachmentType = null;

  allowedNachweisFormats : string = ImageHelperService.ALLOWED_NACHWEIS_FILE_FORMATS;
  public dateiForm: UntypedFormGroup;
  fileUpload: File;
  private entityNameTranslationKey = 'ANHANG';

  attachmentTypes: AttachmentType[] = Object.values(AttachmentType);
  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbActiveModal,
    private antragTicketService: AntragTicketService,
    private customToastService: CustomToastService,
    private compositMessageService: CompositMessageService
  ){
  }

  ngOnInit() {
    this.dateiForm = this.fb.group({
      datei: ['',[Validators.required]],
      type: [this.attachmentTypeSelection? this.attachmentTypeSelection: '',[Validators.required]],
      name: ['']
    });
  }

  reject(){
    this.modalService.close();
  }

  accept(){
    if(!this.dateiForm.valid){
      this.customToastService.showError(this.compositMessageService.createIncompleteMessage(this.entityNameTranslationKey));
      return;
    }
    let dto: AntragAttachmentCreateDto = {
      attachmentType: this.dateiForm.get('type')?.value.length !== 0 ? this.dateiForm.get('type')?.value : null,
      fileName: this.dateiForm.get('name')?.value.length !== 0 ? this.dateiForm.get('name')?.value : null
    };
    this.antragTicketService.addAttachment(this.antragId, this.fileUpload, dto );
    this.modalService.close();
  }

  onFileSelected({currentTarget}){
    if(currentTarget.files != null && currentTarget.files.length > 0) {
      this.fileUpload = currentTarget.files[0];
    } else {
      this.fileUpload = null;
      this.dateiForm.patchValue({datei: null});
      this.dateiForm?.get("datei")?.updateValueAndValidity();
    }

  }

  onFileDeleted(){
    this.fileUpload = null;
    this.dateiForm.patchValue({datei: null});
    this.dateiForm?.get("datei")?.updateValueAndValidity();
  }
}
