import {Component, Input, OnInit} from '@angular/core';
import {AttachmentType} from "../../../../../models/antrag-ticket/AttachmentType";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AntragTicketService} from "../../../../../@core/services/antrag-ticket/antrag-ticket.service";
import {AntragAttachmentAdjustDto} from "../../../../../models/antrag-ticket/AntragAttachmentAdjustDto";

@Component({
  selector: 'app-attachment-type-adjust',
  templateUrl: './attachment-type-adjust.component.html',
  styleUrl: './attachment-type-adjust.component.scss'
})
export class AttachmentTypeAdjustComponent implements OnInit{
  @Input()
  antragId: string;
  @Input()
  attachmentId: string;
  public dateiForm: UntypedFormGroup;

  attachmentTypes: AttachmentType[] = Object.values(AttachmentType);
  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbActiveModal,
    private antragTicketService: AntragTicketService
  ){
  }

  ngOnInit() {
    this.dateiForm = this.fb.group({
      type: ['',[Validators.required]],
    });
  }

  reject(){
    this.modalService.close();
  }

  accept(){

    let dto: AntragAttachmentAdjustDto = {
      attachmentType: this.dateiForm.get('type')?.value.length !== 0 ? this.dateiForm.get('type')?.value : null,
      attachmentId: this.attachmentId
    };
    this.antragTicketService.adjustAttachmentType(this.antragId, dto );
    this.modalService.close();
  }

}
