import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FahrkostenBelegDTO} from "../../../models/fahrkosten/FahrkostenBelegDTO";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {TranslateService} from '@ngx-translate/core';
import {DateiFahrkostenFullDTO} from "../../../models/fahrkosten/DateiFahrkostenFullDTO";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {FahrkostenUniversalComponent} from '../fahrkosten-universal/fahrkosten-universal.component';
import {PictureFileComponent} from "../../picture-file/picture-file/picture-file.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser";
import {ImageHelperService} from "../../../@core/utils/forms/image-helper.service";

@Component({
  selector: 'app-fahrkosten-belege',
  templateUrl: './fahrkosten-belege.component.html',
  styleUrls: ['./fahrkosten-belege.component.scss']
})
export class FahrkostenBelegeComponent implements OnInit {

  @Input() beleg: FahrkostenBelegDTO;
  @Input() readonly: boolean;
  @Output() deleteThisBeleg: EventEmitter<number> = new EventEmitter();
  deleteBelegIcon = faMinusCircle;
  moreBelegIcon   = faPlusCircle;
  allowedNachweisFormats : string = ImageHelperService.ALLOWED_NACHWEIS_FILE_FORMATS

  constructor(
    public translateService: TranslateService,
    private customToastService: CustomToastService,
    private parent: FahrkostenUniversalComponent,
    public modalService: NgbModal,
    public sanitizer: DomSanitizer,
    private imageHelper: ImageHelperService
  ) { }

  ngOnInit(): void {
    /* comment because: sonar complains about ngOnInit being empty */
  }

  sanitize(url: string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  previewImg(src){
    const modalRef = this.modalService.open(PictureFileComponent);
    modalRef.componentInstance.image = src;
  }

  previewPDF(src){
    const modalRef = this.modalService.open(PictureFileComponent);
    modalRef.componentInstance.width = 500;
    modalRef.componentInstance.height = 600;
    modalRef.componentInstance.pdf = src;
  }

  deleteBeleg(){
    this.deleteThisBeleg.emit(this.beleg.belegId);
  }

  sourceToDateiFahrkostenFullDto(filetype: string, data: any) {
    let dateiFahrkostenFullDTO : DateiFahrkostenFullDTO = {} as DateiFahrkostenFullDTO;
    dateiFahrkostenFullDTO.id           = undefined;
    dateiFahrkostenFullDTO.contentType  = filetype;
    dateiFahrkostenFullDTO.erstellt     = new Date();
    dateiFahrkostenFullDTO.fahrkostenId = this.beleg.fahrkostenId || undefined;
    dateiFahrkostenFullDTO.denied       = false;
    dateiFahrkostenFullDTO.kostenId     = this.beleg.kostenId || 1;
    dateiFahrkostenFullDTO.belegId      = this.beleg.belegId  || 1;
    dateiFahrkostenFullDTO.seiteId      = this.beleg.seiten?.length+1 || 1;
    dateiFahrkostenFullDTO.imgBytes     = data.split('base64,')[1];
    return dateiFahrkostenFullDTO;
  }

  addBelegSeite({currentTarget}){
    let file : File = currentTarget.files[0];
    this.imageHelper.handleBelegFile(file)
      .then((processed)=>{
        if(!!(processed.error)) {
          this.customToastService.showError(processed.error);
          return;
        }
        let dateiFkDto = this.sourceToDateiFahrkostenFullDto(file.type,processed.image);
        setTimeout(() => {
          this.parent.belegeList.get(this.beleg.belegId).seiten.push(dateiFkDto);
          this.parent.belegeList.get(this.beleg.belegId).sources.push(processed.image);
          let dFkList = this.parent.getFormItem('dateiFahrkostenList').value;
          dFkList.push(dateiFkDto);
          this.parent.fahrkostenKostenUniversalForm.patchValue({dateiFahrkostenList: dFkList});
        });
      }).catch(()=>{});
  }


}
