import {Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {faAdd, faDownload, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {AntragTicketService} from "../../../../../@core/services/antrag-ticket/antrag-ticket.service";
import {AttachmentType} from "../../../../../models/antrag-ticket/AttachmentType";
import {AntragTicketDTO} from "../../../../../models/antrag-ticket/AntragTicketDTO";
import {ConfirmComponent} from "../../../../../shared/confirm/confirm.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {TicketAntragAttachmentUploadComponent} from "../attachment-upload/ticket-antrag-attachment-upload.component";
import {AntragAttachmentDto} from "../../../../../models/antrag-ticket/AntragAttachmentDto";
import {AttachmentTypeAdjustComponent} from "../attachment-type-adjust/attachment-type-adjust.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-ticket-antrag-sidebar-attachments',
  templateUrl: './ticket-antrag-sidebar-attachments.component.html',
  styleUrl: './ticket-antrag-sidebar-attachments.component.scss'
})
export class TicketAntragSidebarAttachmentsComponent implements OnInit, OnChanges{

  @Input()
  antragTicketId: string;
  @Input()
  attachmentTypeFilter: AttachmentType;
  @Input()
  deleteable: boolean= false;
  @Input()
  appendeable: boolean = false;

  readonly destroyRef = inject(DestroyRef);

  antragTicket: AntragTicketDTO;
  constructor(
    private antragTicketService: AntragTicketService,
    private modalService: NgbModal,
    private translateService: TranslateService,
  ){

  }

  ngOnInit() {
    if(this.antragTicketId && this.antragTicketService.setAntragID$.getValue() !== this.antragTicketId) {
      this.antragTicketService.setAntragID$.next(this.antragTicketId);
    }
      this.antragTicketService.antragObs$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(antrag => {
          this.antragTicket = antrag;
        });

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.antragTicketId.previousValue !== changes.antragTicketId.currentValue
      && !changes.antragTicketId.previousValue
      && changes.antragTicketId.currentValue
      && this.antragTicketService.setAntragID$.getValue() !== changes.antragTicketId.currentValue){
      this.antragTicketService.setAntragID$.next(changes.antragTicketId.currentValue);
    }
  }

  getBadgeClass(attachmentType: AttachmentType): string {
    switch (attachmentType) {
      case AttachmentType.PRAKTIKUMSNACHWEIS:
        return 'badge-dark';
      case AttachmentType.SONDERFALLNACHWEIS:
        return 'badge-success';
      case AttachmentType.BEZUGSNACHWEIS:
        return 'badge-warning';
      case AttachmentType.OTHER:
        return 'badge-error';
      default:
        return 'badge-error';
    }
  }

  removeAttachment(attachmentId: string, antragId: string){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.translateService.instant('MODAL.REMOVE_ATTACHMENT_TITLE');
    modalRef.componentInstance.text = this.translateService.instant('MODAL.REMOVE_ATTACHMENT_TEXT');
    modalRef.componentInstance.onYes = () => {
      this.antragTicketService.removeAttachment(antragId, attachmentId);
    }

  }

  addAttachment(){
    const modalRef = this.modalService.open(TicketAntragAttachmentUploadComponent);
    modalRef.componentInstance.attachmentTypeSelection = this.attachmentTypeFilter;
    modalRef.componentInstance.antragId = this.antragTicket?.antragId;
  }

  downloadAttachment(attachmentId: string, antragId: string){
    this.antragTicketService.downloadAttachment(antragId, attachmentId);
  }

  adjustType(attachmentId: string){
    const modalRef = this.modalService.open(AttachmentTypeAdjustComponent);
    modalRef.componentInstance.attachmentId = attachmentId;
    modalRef.componentInstance.antragId = this.antragTicket?.antragId;
  }
  filter(attachments:AntragAttachmentDto[]){
    return attachments?.filter(attachment => !this.attachmentTypeFilter || attachment.attachmentType === this.attachmentTypeFilter);
  }

  protected readonly faDownload = faDownload;
  protected readonly faAdd = faAdd;
  protected readonly faTrash = faTrash;
  protected readonly faEdit = faEdit;
}
