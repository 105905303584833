<app-default-layout
  [title]="'Ihr Profil'"
  [subtitle]="'Profil bearbeiten'"
>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="frame frame-default">
        <div id="edit-benutzer" class="content scaffold-edit " role="main">
          <form [formGroup]="benutzerForm" (ngSubmit)="this.updateBenutzer()" class="form-horizontal" accept-charset="UTF-8">

            <div class="form-group group-for-name col mb-3"
                 *ngIf="isVisible('name')"
            >
              <div class="d-flex flex-row" >
                <div class="flex-grow-1 me-2">
                  <app-custom-input id="name"
                                    type="text"
                                    [customFormGroup]="benutzerForm"
                                    [label]="getFieldLabelOrTranslate('name','STANDARD.NACHNAME')"
                                    [infoTextBelowInput]="isRequired('name')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                                    data-cy="profil-name"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="form-group group-for-vorname col mb-3"
                 *ngIf="isVisible('vorname')"
            >
              <div class="d-flex flex-row" >
                <div class="flex-grow-1 me-2">
                  <app-custom-input id="vorname"
                                    type="text"
                                    [customFormGroup]="benutzerForm"
                                    [label]="getFieldLabelOrTranslate('vorname','STANDARD.VORNAME')"
                                    [infoTextBelowInput]="isRequired('vorname')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                                    data-cy="profil-vorname"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="form-group group-for-schule col mb-3"
                 *ngIf="isVisible('schule')"
            >
              <div class="d-flex flex-row" >
                <div class="flex-grow-1 me-2">
                  <app-custom-select id="schule"
                                      type="text"
                                      [customFormGroup]="benutzerForm"
                                      [items]="schuleList"
                                      bindLabel="name"
                                      bindValue="id"
                                      [multiple]="false"
                                      [label]="getFieldLabelOrTranslate('schule','STANDARD.SCHULE')"
                                     data-cy="profil-schule"
                  ></app-custom-select>
                </div>
              </div>
            </div>

            <div class="form-group group-for-strasse col mb-3"
                 *ngIf="isVisible('strasse')"
            >
              <div class="d-flex flex-row" >
                <div class="flex-grow-1 me-2">
                  <app-custom-input id="strasse"
                                    type="text"
                                    [customFormGroup]="benutzerForm"
                                    [label]="getFieldLabelOrTranslate('strasse','STANDARD.STRASSE')"
                                    [noteText]="'Bitte geben Sie die Straße und die Hausnummer an.'"
                                    [infoTextBelowInput]="isRequired('strasse')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                                    data-cy="profil-strasse"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="form-group group-for-adresszusatz col mb-3"
                 *ngIf="isVisible('adresszusatz')"
            >
              <div class="d-flex flex-row" >
                <div class="flex-grow-1 me-2">
                  <app-custom-input id="adresszusatz"
                                    type="text"
                                    [customFormGroup]="benutzerForm"
                                    [label]="getFieldLabelOrTranslate('adresszusatz','STANDARD.ADRESSZUSATZ')"
                                    [infoTextBelowInput]="'Hier können Sie Zusatzinformationen zur Anschrift angeben. Beispiel: Zimmernummer.'"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="form-group group-for-plz col mb-3"
                 *ngIf="isVisible('plz')"
            >
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="plz"
                                    [customFormGroup]="benutzerForm"
                                    [label]="getFieldLabelOrTranslate('plz','STANDARD.PLZ')"
                                    (onchange)="setOrt($event, 'ort')"
                                    [maxlength]="getMaxLength('plz')"
                                    [pattern]="getPattern('plz')"
                                    [infoTextBelowInput]="isRequired('plz')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                                    data-cy="profil-plz"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="form-group group-for-ort col mb-3"
                 *ngIf="isVisible('ort')"
            >
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="ort"
                                    [customFormGroup]="benutzerForm"
                                    [label]="getFieldLabelOrTranslate('ort','STANDARD.ORT')"
                                    [infoTextBelowInput]="isRequired('ort')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                                    data-cy="profil-ort"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="form-group group-for-land col mb-3"
                 *ngIf="isVisible('land')"
            >
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-select
                    id="land"
                    class="flex-grow-1"
                    [customFormGroup]="benutzerForm"
                    [items]="landList"
                    [multiple]="false"
                    [clearable]="false"
                    bindLabel="value"
                    bindValue="name"
                    [label]="getFieldLabelOrTranslate('land','STANDARD.LAND')"
                    [infoTextBelowInput]="isRequired('land')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                  ></app-custom-select>
                </div>
              </div>
            </div>

            <div class="form-group group-for-geschlecht col mb-3"
                 *ngIf="isVisible('geschlecht')"
            >
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-select id="geschlecht"
                                     [customFormGroup]="benutzerForm"
                                     placeholder="-"
                                     [items]="optionGeschlecht"
                                     [multiple]="false"
                                     [clearable]="false"
                                     bindLabel="name"
                                     bindValue="id"
                                     [label]="getFieldLabelOrTranslate('geschlecht','STANDARD.GESCHLECHT.TITEL')"
                                     [infoTextBelowInput]="isRequired('geschlecht')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                  ></app-custom-select>
                </div>
              </div>
            </div>

            <div class="form-group group-for-geburtsdatum col mb-3"
                 *ngIf="isVisible('geburtsdatum')"
            >
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input class="datepicker"
                                    id="geburtsdatum"
                                    [type]="'date'"
                                    [customFormGroup]="benutzerForm"
                                    data-content="dd.mm.yyyy"
                                    [max]="this.maxDate | date:'yyyy-MM-dd'"
                                    [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                    [label]="getFieldLabelOrTranslate('geburtsdatum','STANDARD.GEBURTSDATUM')"
                                    [infoTextBelowInput]="(isRequired('geburtsdatum')?translateService.instant('PROFIL.FORM.REQUIRED'):'')
                                      + ' ' + getTextbausteinOrTranslate(KeyString.GEBURTSDATUM_INFO_BELOW,'')"
                                    data-cy="profil-geburtsdatum"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="form-group group-for-telefonnummer col mb-3"
                 *ngIf="isVisible('telefonnummer')"
            >
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="telefonnummer"
                                    [customFormGroup]="benutzerForm"
                                    [label]="getFieldLabelOrTranslate('telefonnummer','STANDARD.TELEFONNUMMER')"
                                    [infoTextBelowInput]="isRequired('telefonnummer')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="form-group group-for-abonnementnummer col mb-3"
                 *ngIf="isVisible('abonnementnummer')"
            >
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="abonnementnummer"
                                    [customFormGroup]="benutzerForm"
                                    [label]="getFieldLabelOrTranslate('abonnementnummer','STANDARD.ABONNEMENTNUMMER')"
                                    [infoTextBelowInput]="isRequired('abonnementnummer')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="form-group group-for-email col mb-3"
                 *ngIf="isVisible('email')"
            >
              <div class="d-flex flex-row">
                <div class="flex-grow-1 mr-2">
                  <app-custom-input id="email"
                                    [customFormGroup]="benutzerForm"
                                    [label]="getFieldLabelOrTranslate('email','STANDARD.EMAIL')"
                                    [infoTextBelowInput]="isRequired('email')?translateService.instant('PROFIL.FORM.REQUIRED'):''"
                                    data-cy="profil-email"
                  ></app-custom-input>
                </div>
              </div>
            </div>

            <div class="row justify-content-between">
              <div class="col-3">
                <button type="submit" class="btn btn-primary save" data-cy="profil-savebtn">
                  <fa-icon [icon]="faSave"></fa-icon>
                  {{'APPLICATION.BUTTON_PROFIL_SPEICHERN' |translate }}
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen] = "true"
  >
  </ngx-spinner>
</app-default-layout>
