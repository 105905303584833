import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InformationComponent} from "./information.component";
import {ImpressumComponent} from "./impressum/impressum.component";
import {DatenschutzComponent} from "./datenschutz/datenschutz.component";

const routes: Routes = [{

  path: '',
  component: InformationComponent,
  children: [
    {
      path: 'impressum',
      component: ImpressumComponent
    },
    {
      path: 'datenschutz',
      component: DatenschutzComponent
    },
  ]

}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InformationRoutingModule { }
