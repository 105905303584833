import {Component, OnInit} from '@angular/core';
import {catchError} from "rxjs/operators";
import {EMPTY, Subscription} from "rxjs";
import {
  ApplicationPropertiesService
} from 'src/app/@core/services/application-properties/application-properties.service';
import {ActivatedRoute} from "@angular/router";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {FieldConfigService} from "../../../@core/services/field-config/field-config.service";
import {Land} from "../../../models/land/Land";
import {TranslateService} from "@ngx-translate/core";
import {TextbausteinService} from "../../../@core/services/textbaustein/textbaustein.service";
import {GeschlechtHelperService} from "../../../@core/utils/forms/geschlecht-helper.service";
import {KeyString} from "../../../models/textbaustein/KeyString";

@Component({
  selector: 'app-ticket-detail-parent',
  templateUrl: './ticket-detail-parent.component.html',
  styleUrls: ['./ticket-detail-parent.component.scss']
})
export class TicketDetailParentComponent implements OnInit {

  title='';
  subtitle='';

  antragTicketId: string;
  hasSachbearbeiterBackend: boolean = false;
  fieldConfig;

  benutzer: BenutzerDTO;
  benutzerRoles: Array<string>;

  subscriptionTranslate: Subscription;

  optionTyp = [];
  optionErmaessigung = [];
  optionGeschlecht = [];
  optionWegtyp = [];
  optionSonderfall = [];
  landList = [];

  constructor(
    public translateService: TranslateService,
    private applicationPropertiesService: ApplicationPropertiesService,
    private route: ActivatedRoute,
    public textbauSteinService: TextbausteinService,
    private fieldConfigService: FieldConfigService,
    private geschlechtHelperService: GeschlechtHelperService
  ) {}

  ngOnInit(): void {

    this.checkForSBBackend()
      .then(() => {
        this.setBenutzer();
        this.title = this.textbauSteinService.printTextbausteinByKey( KeyString.SUBTITLE_TICKET,
          this.translateService.instant('TICKETDETAIL.TITLE_TICKET'));
        this.loadFieldConfig();
        this.antragTicketId = this.route.snapshot.paramMap.get('id');
        this.initHardcodedLists();
        this.initRequiredFields();
      })
      .catch((err) => {console.log(err)});

  }

  ngOnDestroy() {
    this.subscriptionTranslate.unsubscribe();
  }

  initRequiredFields(){
   let allFormFields = this.antragTicketFormFields.concat(
      this.antragTicketSepaFormFields,
      this.antragTicketGeschwister1FormFields, this.antragTicketGeschwister2FormFields,
      this.antragTicketGeschwister3FormFields, this.antragTicketGeschwister4FormFields,
      this.antragTicketGeschwister5FormFields
   );
        allFormFields.forEach((varName) => {
          let fieldFromFieldConfig = this.fieldConfigService.getFieldFromFieldConfig(varName, this.fieldConfig);
          if( fieldFromFieldConfig?.visible  || this.alwaysVisible.includes(varName) ){
            this.visibleFields = [...this.visibleFields,varName];
            if(!this.neverValidate.includes(varName) &&
              (this.alwaysValidateIfVisible.includes(varName) ||
                fieldFromFieldConfig?.required)){
              this.requiredFields = [...this.requiredFields,varName];
              if(fieldFromFieldConfig?.required){
                this.fieldConfigValidators = [...this.fieldConfigValidators,varName];
              }
            }
          }
        });
  }

  initHardcodedLists(): void {
    this.subscriptionTranslate = this.translateService.get('dummyTranslation').subscribe(()=>{
      this.optionTyp = [
        {id: null, name:"-"},
        {id:"NEUANTRAG", name: this.translateService.instant("TICKETDETAIL.TYP.NEUANTRAG")},
        {id:"FOLGEANTRAG", name: this.translateService.instant("TICKETDETAIL.TYP.FOLGEANTRAG")},
        {id:"AENDERUNGSANTRAG_UMZUG", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_UMZUG") },
        {id:"AENDERUNGSANTRAG_NAMENSAENDERUNG", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_NAMENSAENDERUNG") },
        {id:"AENDERUNGSANTRAG_HALTESTELLENAENDERUNG", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_HALTESTELLENAENDERUNG") },
        {id:"AENDERUNGSANTRAG_SCHULWECHSEL", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_SCHULWECHSEL") },
        {id:"AENDERUNGSANTRAG_BANKDATEN", name: this.translateService.instant("TICKETDETAIL.TYP.AENDERUNGSANTRAG_BANKDATEN") },
        {id:"KUENDIGUNG", name: this.translateService.instant("TICKETDETAIL.TYP.KUENDIGUNG") }
      ];
      this.optionErmaessigung = [{id: null, name: this.translateService.instant("TICKETDETAIL.ERMAESSIGUNG.KEINE") },
        {id:"ERMAESSIGT", name: this.translateService.instant("TICKETDETAIL.ERMAESSIGUNG.ERMAESSIGT") },
        {id:"ERLASSEN", name: this.translateService.instant("TICKETDETAIL.ERMAESSIGUNG.ERLASSEN") }
      ];
      this.optionGeschlecht = this.geschlechtHelperService.getGeschlechtOptionList();
      this.optionWegtyp = [{id: null, name: "-" },
        {id:"SCHULWEG", name: this.translateService.instant("TICKETDETAIL.WEGTYP.SCHULWEG") },
        {id:"PRAKTIKUMSWEG", name: this.translateService.instant("TICKETDETAIL.WEGTYP.PRAKTIKUMSWEG") },
        {id:"BEIDES", name: this.translateService.instant("TICKETDETAIL.WEGTYP.BEIDES") }
      ];

      // parent creates all
      // child will then filter according to fieldConfig settings and antrag status
      this.optionSonderfall = [
        {value:null, name: this.translateService.instant("TICKETDETAIL.SONDERFALL.NICHT_AUSGEWAEHLT") },
        {value:"SCHWERBEHINDERUNG", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.SCHWERBEHINDERUNG") },
        {value:"INKLUSION_BESONDERE_FOERDERUNG", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.INKLUSION_BESONDERE_FOERDERUNG") },
        {value:"ERKRANKUNG_VERLETZUNG", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.ERKRANKUNG_VERLETZUNG") },
        {value:"ZUWEISUNG_DES_KOMMUNALEN_INTEGRATIONSZENTRUMS", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.ZUWEISUNG_DES_KOMMUNALEN_INTEGRATIONSZENTRUMS") },
        {value:"SCHULEMPFEHLUNG_DES_KOMMUNALEN_INTEGRATIONSZENTRUMS", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.SCHULEMPFEHLUNG_DES_KOMMUNALEN_INTEGRATIONSZENTRUMS") },
        {value:"GYMNASIUM_MIT_GEWAEHLTEM_BILINGUALEN_BILDUNGSGANG", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.GYMNASIUM_MIT_GEWAEHLTEM_BILINGUALEN_BILDUNGSGANG") },
        {value:"SPORTKLASSE_NRW", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.SPORTKLASSE_NRW") },
        {value:"AUSZUBILDENDE_VON_BEZIRKSFACHKLASSEN", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.AUSZUBILDENDE_VON_BEZIRKSFACHKLASSEN") },
        {value:"INTERNAT", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.INTERNAT") },
        {value:"ARBEITSLOSIGKEIT_UND_BERUFSSCHULPFLICHTIGKEIT", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.ARBEITSLOSIGKEIT_UND_BERUFSSCHULPFLICHTIGKEIT") },
        {value:"SONSTIGES", name: this.translateService.instant("TICKETDETAIL.SONDERFALL.SONSTIGES") },
      ];
    });
    this.landList = Object.keys(Land).map((name) => {
      return { name, value: Land[name as keyof typeof Land],};
    });
  }

  checkForSBBackend() : Promise<string>{
    return new Promise<string>((resolve, reject) =>{
      this.applicationPropertiesService.getApplicationProperties()
        .pipe(catchError((err) => {
          reject(err.toString());
          return EMPTY;
        }))
        .subscribe((data) => {
          this.hasSachbearbeiterBackend = data.hasSachbearbeiterBackend;
          resolve('success');
        })
    });
  }

  setBenutzer(){
    if (sessionStorage.getItem('user')) {
      this.benutzer = JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO;
      this.benutzerRoles = this.benutzer.roles;
    }
  }

  loadFieldConfig(){
    this.fieldConfig = this.fieldConfigService.getFieldConfigFromSessionStorage(['antragTicket', 'antragTicketSepa']);
    if(!this.fieldConfig||this.fieldConfig.length<1){
      console.log('No fieldConfig found: setting default values');
      this.setFieldConfigDefault();
    }
  }

  //for validation
  visibleFields:            string[] = [];  //all fields set to visible via fieldConfig, static: loaded once on init
  fieldConfigValidators :   string[] = [];  //all fields forced to validate via fieldConfig, static: loaded once on init

  alwaysVisible : string[] = ['schule'];
  alwaysValidateIfVisible:  string[] = ['schule'];   //if visible = override fieldConfig and force validation, static: loaded once on init


  requiredFields: string[] = []; // fieldConfigValidators MINUS NEVER validate PLUS ALWAYS validate

  neverValidate : string[] = // never validate these fields regardless of config, for example optional checkmarks
    [
      'sonderfallEnabled','bezugAsylbewerberleistungsgesetz','einverstaendnisErklaerung',
      'adresszusatz','adresszusatzSepa','vormundAdresszusatz','praktikumAdresszusatz',
      "referenzFuerZahlungen", "sonderfall", 'isAntragstellerNotMandatsgeber', 'isBerufskolleg',
      'praktikumHinweis', 'isVormundadresseAbweichend',
      'geschwisterkind1', 'geschwisterkind2','geschwisterkind3','geschwisterkind4','geschwisterkind5'
    ];

  effectiveValidators: string[]  = [];  //the validators that become effective if you try EINREICHEN

  // Felder die optional sichtbar sind, wenn eine der folgenden Optionen gewählt wird:
  //wegtyp
  schulwegOnOff   = [];
  praktikumOnOff  = ['praktikumHinweis','praktikumStelle','praktikumStrasse','praktikumOrt','praktikumPlz','praktikumLand',
    'praktikumStartdatum','praktikumEnddatum','praktikumstageJeWoche','praktikumsvertrag','praktikumAdresszusatz'];
  beidesOnOff     = ['schultageJeWoche'];

  //schultyp
  schuleOnOff = ['schuljahr','klasse',];
  berufskollegOnOff = ['schuljahr','bildungsgang','bildungsgangdatum','fachbereich'];
  schuljahrOnOff = ['schuljahr'];

  //vormund
  vormundOnOff = ['vormundName', 'vormundVorname','vormundGeburtsdatum','vormundTelefonnummer','vormundEmail','vormundGeschlecht', 'isVormundadresseAbweichend'];
  vormundAdresseAbweichendOnOff = ['vormundStrasse','vormundOrt','vormundPlz','vormundLand','vormundAdresszusatz'];


  sonderfallOnOff = ['sonderfallNachweis'];
  sonderfallSonstOnOff = ['sonderfallBegruendung'];

  asylOnOff = ['bezugNachweis'];

  //sepa
  sepaMandatgeberNotAntragst = ['vornameSepa', 'nameSepa', 'strasseSepa', 'plzSepa', 'ortSepa', 'landSepa',
    'emailSepa', 'telefonnummerSepa', 'geburtsdatumSepa', 'geschlechtSepa', 'ibanSepa',
    'bicSepa', 'nameKreditinstitutSepa'];
  sepaMandatgeberYesAntragst = ['iban','bic', 'nameKreditinstitut']

  setFieldConfigDefault() {
    this.fieldConfig = [
      {field: 'name', visible: true, required: true, label: null, module: 'antragTicket'},
      {field: 'vorname', visible: true, required: true, label: null, module: 'antragTicket'},
      {field: 'strasse', visible: true, required: true, label: null, module: 'antragTicket'},
      {field: 'plz', visible: true, required: true, label: null, module: 'antragTicket'},
      {field: 'ort', visible: true, required: true, label: null, module: 'antragTicket'},
      {field: 'geburtsdatum', visible: true, required: true, label: null, module: 'antragTicket'},
      {field: 'email', visible: false, required: true, label: null, module: 'antragTicket'},
      {field: 'emailSchueler', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'telefonnummer', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'land', visible: true, required: true, label: null, module: 'antragTicket'},
      {field: 'einstieghaltestelle', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'ausstieghaltestelleHinweg', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'typ', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'ticket', visible: true, required: true, label: null, module: 'antragTicket'},
      {field: 'wegtyp', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'schule', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'schuljahr', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'klasse', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'aboGueltigkeit', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'geschlecht', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'referenzFuerZahlungen', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'abonnementnummerAntrag', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'einverstaendnisErklaerung', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'marketingfreigabe', visible: true, required: true, label: null, module: 'antragTicket'},
      {field: 'geschwisterkind1', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'geschwisterkind2', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'geschwisterkind3', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'geschwisterkind4', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'geschwisterkind5', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'selbstzahlerExport', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'fahrkostenberechtigt', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'umgezogen', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'alteTicketsLiegenBei', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'alteTicketsWerdenZurueckgesandt', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'bildungsgangdatum', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'eigenanteil', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'zuzahlung', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundName', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundVorname', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundStrasse', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'isVormundadresseAbweichend', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundOrt', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundPlz', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundAdresszusatz', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundLand', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundGeburtsdatum', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundTelefonnummer', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundEmail', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vormundGeschlecht', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'bildungsgang', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'bildungsgangdatum', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'fachbereich', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'schultageJeWoche', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumStelle', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumHinweis', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumStrasse', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumOrt', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumPlz', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumLand', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumAdresszusatz', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumStartdatum', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumEnddatum', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumstageJeWoche', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'sonderfallEnabled', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'sonderfall', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'sonderfallBegruendung', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'bezugAsylbewerberleistungsgesetz', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'adresszusatz', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'zustimmungGesetzlicherVertreter', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'praktikumsvertrag', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'sonderfallNachweis', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'bezugNachweis', visible: true, required: false, label: null, module: 'antragTicket'},
      {field: 'vornameSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'nameSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'strasseSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'plzSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'ortSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'adresszusatzSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'landSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'emailSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'telefonnummerSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'geburtsdatumSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'iban', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'ibanSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'isAntragstellerNotMandatsgeber', visible: true, required: false, label: null,module: 'antragTicketSepa'},
      {field: 'datenschutz', visible: true, required: true, label: null, module: 'antragTicketSepa'},
      {field: 'bicSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'bic', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'nameKreditinstitut', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'nameKreditinstitutSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
      {field: 'geschlechtSepa', visible: true, required: false, label: null, module: 'antragTicketSepa'},
    ];
  }

  antragTicketFormFields  = [
    // Felder die ohne Umwege direkt von antragTicketDTO in antragTicketForm geladen werden können.

    //persönliche Angaben
    'name','vorname','strasse','plz','ort','geburtsdatum','email','emailSchueler','telefonnummer',
    'schwerbehinderung','aboGueltigkeit','geschlecht','referenzFuerZahlungen','abonnementnummerAntrag',
    'bemerkungAntragsteller','einverstaendnisErklaerung',
    'geschwisterkind1','geschwisterkind2','geschwisterkind3','geschwisterkind4','geschwisterkind5',
    'schuelernummer','richtigkeitDerAngaben','typ','schueler','marketingfreigabe','land', 'adresszusatz',
    'zustimmungGesetzlicherVertreter',

    'status',

    //Vormund
    'vormundName', 'vormundVorname', 'isVormundadresseAbweichend', 'vormundStrasse','vormundOrt','vormundPlz','vormundAdresszusatz',
    'vormundLand', 'vormundGeburtsdatum', 'vormundTelefonnummer', 'vormundEmail','vormundGeschlecht',

    //Schule, Praktikum etc.
    'wegtyp', 'bildungsgang', 'bildungsgangdatum','fachbereich', 'schultageJeWoche',
    'schule','isBerufskolleg','schuljahr','klasse','ticket','einstieghaltestelle','ausstieghaltestelleHinweg',
    'praktikumStelle', 'praktikumHinweis','praktikumStrasse', 'praktikumOrt', 'praktikumPlz', 'praktikumLand',
    'praktikumAdresszusatz', 'praktikumStartdatum', 'praktikumEnddatum', 'praktikumstageJeWoche',

    //Sonderfälle etc.
    'sonderfallEnabled', 'sonderfall', 'sonderfallBegruendung', 'bezugAsylbewerberleistungsgesetz',


  ];

  antragTicketSepaFormFields = [
    'vornameSepa', 'nameSepa', 'strasseSepa', 'plzSepa', 'ortSepa', 'adresszusatzSepa', 'landSepa',
    'emailSepa', 'telefonnummerSepa', 'geburtsdatumSepa', 'geschlechtSepa', 'iban', 'ibanSepa', 'isAntragstellerNotMandatsgeber',
    'bicSepa', 'bic', 'nameKreditinstitut', 'nameKreditinstitutSepa', 'datenschutz'
  ];

  antragTicketGeschwister1FormFields = [
    'geschwisterkind1name','geschwisterkind1vorname','geschwisterkind1geburtsdatum','geschwisterkind1strasse',
    'geschwisterkind1plz','geschwisterkind1ort','geschwisterkind1schule',
  ];

  antragTicketGeschwister2FormFields = [
    'geschwisterkind2name','geschwisterkind2vorname','geschwisterkind2geburtsdatum','geschwisterkind2strasse',
    'geschwisterkind2plz','geschwisterkind2ort','geschwisterkind2schule',
  ];

  antragTicketGeschwister3FormFields = [
    'geschwisterkind3name','geschwisterkind3vorname','geschwisterkind3geburtsdatum','geschwisterkind3strasse',
    'geschwisterkind3plz','geschwisterkind3ort','geschwisterkind3schule',
  ];

  antragTicketGeschwister4FormFields = [
    'geschwisterkind4name','geschwisterkind4vorname','geschwisterkind4geburtsdatum','geschwisterkind4strasse',
    'geschwisterkind4plz','geschwisterkind4ort','geschwisterkind4schule',
  ];

  antragTicketGeschwister5FormFields = [
    'geschwisterkind5name','geschwisterkind5vorname','geschwisterkind5geburtsdatum','geschwisterkind5strasse',
    'geschwisterkind5plz','geschwisterkind5ort','geschwisterkind5schule',
  ];

  nonNullableOnASBackend = [
    'alteTicketsLiegenBei', 'alteTicketsWerdenZurueckgesandt','fahrkostenberechtigt','schwerbehinderung','umgezogen'
  ];

}
