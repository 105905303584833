import {NgModule} from '@angular/core';
import {CommonModule, NgIf} from '@angular/common';

import {InformationRoutingModule} from './information-routing.module';
import {ImpressumComponent} from "./impressum/impressum.component";
import {DatenschutzComponent} from "./datenschutz/datenschutz.component";
import {InformationComponent} from "./information.component";
import {PagesModule} from "../pages/pages.module";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    ImpressumComponent,
    DatenschutzComponent,
    InformationComponent
  ],
  imports: [
    CommonModule,
    InformationRoutingModule,
    PagesModule,
    NgIf,
    TranslateModule
  ]
})
export class InformationModule { }
