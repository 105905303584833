<main role="main" class="container-fluid" aria-label="Fahrkostenerstattung">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="frame frame-full">
        <div id="edit-fahrkosten" class="content scaffold-edit" role="main" aria-label="Antrag">
          <div class="row justify-content-between">

            <!--anfang: statusmeldung-->
            <div class="col-md-4 col-lg-3">
              <div class="status-display">

                <div class="pb-3">
                  <div class="id-info text-break">
                    <h4 class="m-2 text-break"
                        *ngIf="this.fahrkostenId && this.fahrkostenId !== '' && this.fahrkostenId !== null; else newFahrkosten">
                      {{ 'STANDARD.FAHRKOSTEN_ANTRAG' | translate }}{{'STANDARD.SNUMMER' | translate}}
                      <div class="text-end default-text-size" data-cy="fahrkosten-id">{{ this.fahrkostenId }}</div>
                    </h4>
                    <ng-template #newFahrkosten>
                      <h4 class="m-2" data-cy="fahrkosten-id">
                        {{ 'STANDARD.FAHRKOSTEN_ANTRAG' | translate }}
                      </h4>
                    </ng-template>
                  </div>
                </div>

                <h4 *ngIf="this.postkorbReference && this.postkorbReference !== '' && this.postkorbReference !== null">{{ "FAHRKOSTEN-DETAIL.POSTKORB_REFERENCE" | translate }} {{ postkorbReference }}</h4>

                <h5 class="text-break">
                  {{ 'FAHRKOSTEN-DETAIL.STATUS' | translate }}:
                  {{ 'FAHRKOSTEN-DETAIL.FAHRKOSTENSTATUS.'+ statusVisibleToUser() | translate }}
                </h5>
                <div class="alert alert-primary mt-4" role="alert">
                  <p class="text-break" data-cy="einreichung">
                    {{ 'FAHRKOSTEN-DETAIL.EINREICHUNG' | translate }}: <br>
                    {{this.fahrkosten?.antragsdatum}}
                  </p>
                  <p class="text-break">
                    {{ 'FAHRKOSTEN-DETAIL.AKTUELL' | translate }}: <br>
                    {{ 'FAHRKOSTEN-DETAIL.FAHRKOSTENSTATUS.'+ statusVisibleToUser() | translate }}
                  </p>
                </div>
                <div *ngIf="this.isFinalized" class="alert alert-warning" role="alert" >
                  <p class="text-break">
                    <fa-icon [icon]="faCheck"></fa-icon>
                    {{ 'FAHRKOSTEN-DETAIL.ABSCHLUSS' | translate }}
                  </p>
                  <p class="text-break">
                    {{ 'FAHRKOSTEN-DETAIL.ERSTATTUNG' | translate }}: <br>
                    {{this.fahrkosten?.gesamtErstattung}}
                  </p>
                </div>
                <div *ngIf="!this.isFinalized"  class="alert alert-danger" role="alert" >
                  <p class="text-break">
                    <fa-icon [icon]="faNope"></fa-icon>
                    {{ 'FAHRKOSTEN-DETAIL.ABSCHLUSS_KEIN' | translate }}
                  </p>
                  <p class="text-break">
                    {{ 'FAHRKOSTEN-DETAIL.ERSTATTUNG' | translate }}: <br>
                    [{{ 'STANDARD.UNBEKANNT' | translate }}]
                  </p>
                </div>
                <div class="changelog" *ngIf="fahrkostenHistorie" >
                  <h4 class="text-break">
                    {{ 'STANDARD.HISTORY' | translate }}
                  </h4>
                  <p>
                  </p>
                  <table class="table table-striped table-hover table-revisions">
                    <caption></caption>
                    <th></th>
                    <tbody>
                    <tr  *ngFor="let historie of fahrkostenHistorie">
                      <td>
                   <span>
                     <fa-icon [icon]="faClock"></fa-icon>
                   </span>
                        <span>
                      {{historie?.bearbeitungszeitpunkt | date}}
                    </span>
                        <span class="label label-info text-break"> {{ 'FAHRKOSTEN-DETAIL.FAHRKOSTENSTATUS.'+historie?.status | translate }}</span>
                      </td>
                    </tr>
                    </tbody></table>
                  <p></p>
                </div>
              </div>
            </div>
            <!--ende: statusmeldung-->

            <!--anfang form-->
            <div class="col-md-8 col-lg-9">
              <div class="well well-lg">
                <form [formGroup]="fahrkostenForm"
                      id="fahrkostenForm" class="form-horizontal form-fahrkosten" accept-charset="UTF-8">

                  <div class="row" *ngIf="benutzer">
                    <fieldset>
                      <legend>
                        {{getTextbausteinOrTranslate(KeyString.BEANTRAGENDEPERSON_TITEL_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.ANTRAGSTELLENDE_PERSON')}}
                      </legend>
                      <div class="col-md-2"></div>
                      <div class="col-md-8">
                        <p>{{benutzer.vorname}} {{benutzer.name}}, {{benutzer.geburtsdatum}}, {{benutzer.strasse}}, {{benutzer.plz}} {{benutzer.ort}}, {{benutzer.schule?.name}}</p>
                      </div>
                    </fieldset>
                  </div>

                  <!--auswahl antragsteller-->
                  <ng-container *ngIf="!this.readonly">
                    <hr>
                    <div class="row align-items-end mb-0">
                      <div class="flex-grow-1 col-md-8 mb-0">
                        <app-custom-select id="schuelerId"
                                           [customFormGroup]="fahrkostenForm"
                                           placeholder="-"
                                           [items]="schuelerList"
                                           bindLabel="fullName"
                                           bindValue="id"
                                           [multiple]="false"
                                           [clearable]="false"
                                           [label]="''+(this.readonly? '':PFLICHTFELDMARKER)+'Ich stelle diesen Antrag für ..'"
                                           [readonly]=readonly
                                           (onchange)="checkIfVorgaengerExists()"
                                           data-cy="schuelerId"
                        ></app-custom-select>
                      </div>
                      <div class="flex-grow-1 col-md-4 ml-1 mb-3" >
                        <input type="button"
                               class="btn btn-secondary btn-add-student"
                               value="{{'SCHUELER-TABLE.ADD_BUTTON'|translate}}"
                               title="{{'SCHUELER-TABLE.ADD_BUTTON'|translate}}"
                               (click)="addSchueler()"
                               data-cy="add-schueler-top"
                        >
                      </div>
                    </div>
                    <div class="row form-text text-muted ms-1 mt-0 mb-3">
                      {{'FAHRKOSTEN-DETAIL.MICH_SELBST_INFO'|translate}}
                    </div>
                  </ng-container>

                  <!--anfang teil angaben-->
                  <hr>
                  <div class="row justify-content-evenly">

                    <!--LINKS-->
                    <div class="col-md-12 col-lg-6">

                      <!--angaben schueler-->
                      <div class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.ANGABEN_SCHUELER_TITEL_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.ANGABEN_SCHUELER')}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('name')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="name"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('name','STANDARD.NACHNAME')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.NAME_INFO_FAHRKOSTEN,'STANDARD.CHANGE_IN_PROFILE')"
                                                [readonly]="true"
                                                data-cy="name"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vorname')"
                             class="form-group group-for-vorname col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2" >
                              <app-custom-input id="vorname"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vorname','STANDARD.VORNAME')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORNAME_INFO_FAHRKOSTEN,'STANDARD.CHANGE_IN_PROFILE')"
                                                [readonly]="true"
                                                data-cy="vorname"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('strasse')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="strasse"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('strasse','STANDARD.STRASSE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.STRASSE_INFO_FAHRKOSTEN,'STANDARD.CHANGE_IN_PROFILE')"
                                                [readonly]="true"
                                                data-cy="strasse"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('plz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="plz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('plz','STANDARD.PLZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PLZ_INFO_FAHRKOSTEN,'STANDARD.CHANGE_IN_PROFILE')"
                                                [readonly]="true"
                                                (onchange)="setOrt($event, 'ort')"
                                                [maxlength]="10"
                                                data-cy="plz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('ort')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="ort"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('ort','STANDARD.WOHNORT')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.WOHNORT_INFO_FAHRKOSTEN,'STANDARD.CHANGE_IN_PROFILE')"
                                                [readonly]="true"
                                                data-cy="ort"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('adresszusatz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="adresszusatz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('adresszusatz','STANDARD.ADRESSZUSATZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.ADRESSZUSATZ_INFO_FAHRKOSTEN,'STANDARD.ADRESSZUSATZ_INFO')"
                                                [readonly]="readonly"
                                                data-cy="adresszusatz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('land')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="land"
                                class="flex-grow-1"
                                [customFormGroup]="fahrkostenForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="getFieldLabelOrTranslate('land','STANDARD.LAND')"
                                [infoText]="getTextbausteinOrTranslate(KeyString.LAND_INFO_FAHRKOSTEN,'STANDARD.CHANGE_IN_PROFILE')"
                                [readonly]="true"
                                data-cy="land"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('geburtsdatum')"
                             class="form-group group-for-geburtsdatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="geburtsdatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('geburtsdatum','STANDARD.GEBURTSDATUM')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.GEBURTSDATUM_INFO_FAHRKOSTEN,'STANDARD.CHANGE_IN_PROFILE')"
                                                [readonly]="true"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                                data-cy="geburtsdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('geschlecht')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschlecht"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [items]="optionGeschlecht"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('geschlecht','STANDARD.GESCHLECHT.TITEL')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.GESCHLECHT_INFO_FAHRKOSTEN,'STANDARD.GESCHLECHT_INFO')"
                                                 [readonly]="readonly"
                                                 data-cy="geschlecht"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('email')"
                             class="form-group group-for-name col mt-0" style="display:none">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="email"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('email','STANDARD.EMAILADRESSE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.EMAIL_INFO_FAHRKOSTEN,'STANDARD.EMAIL_INFO')"
                                                [readonly]="true"
                                                data-cy="email"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('emailSchueler')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="emailSchueler"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('emailSchueler','STANDARD.EMAILADRESSE-SCHUELER')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.EMAIL_SCHUELER_INFO_FAHRKOSTEN,'STANDARD.EMAIL_INFO')"
                                                [readonly]="readonly"
                                                data-cy="emailSchueler"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('telefonnummer')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="telefonnummer"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('telefonnummer','STANDARD.TELEFONNUMMER-SCHUELER')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.TELEFONNR_SCHUELER_INFO_FAHRKOSTEN,'STANDARD.TELEFONNUMMER_INFO')"
                                                [readonly]="readonly"
                                                data-cy="telefonnummer"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende angaben schueler-->

                      <!--anfang vormund-->
                      <div  *ngIf="(getFormItem('isUnderAge')?.value)===true"
                            class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.ANGABEN_VORMUND_TITLE_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.ANGABEN_VORMUND')}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('vormundName')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundName"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vormundName','STANDARD.NACHNAME')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDNAME_INFO_FAHRKOSTEN,'STANDARD.NACHNAME')"
                                                [readonly]="readonly"
                                                data-cy="vormundName"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundVorname') "
                             class="form-group group-for-vorname col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2" >
                              <app-custom-input id="vormundVorname"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vormundVorname','STANDARD.VORNAME')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDVORNAME_INFO_FAHRKOSTEN,'STANDARD.VORNAME')"
                                                [readonly]="readonly"
                                                data-cy="vormundVorname"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('isVormundadresseAbweichend')"
                             class="form-group group-for-name col mt-2">
                          <div class="d-flex flex-row"
                               [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                              <label class="checkbox-container"  style="margin-bottom:3ch;">
                                {{getFieldLabelOrTranslate('isVormundadresseAbweichend','FAHRKOSTEN-DETAIL.VORMUNDADRESSE-ABWEICHEND')}}
                                <input type="checkbox" class="control control-checkbox custom-control-input"
                                       formControlName="isVormundadresseAbweichend"
                                       id="isVormundadresseAbweichend"
                                       name="isVormundadresseAbweichend"
                                >
                                <span class="checkmark" data-cy="isVormundadresseAbweichend"></span>
                              </label>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundStrasse')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundStrasse"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vormundStrasse','STANDARD.STRASSE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDSTRASSE_INFO_FAHRKOSTEN,'STANDARD.STRASSE_INFO')"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                                                data-cy="vormundStrasse"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundPlz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundPlz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vormundPlz','STANDARD.PLZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDPLZ_INFO_FAHRKOSTEN,'STANDARD.PLZ_INFO')"
                                                [readonly]="readonly"
                                                (onchange)="setOrt($event, 'vormundOrt')"
                                                [maxlength]="getMaxLength('vormundPlz')"
                                                [pattern]="getPattern('vormundPlz')"
                                                data-cy="vormundPlz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundOrt')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundOrt"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vormundOrt','STANDARD.WOHNORT')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDORT_INFO_FAHRKOSTEN,'STANDARD.WOHNORT_INFO')"
                                                [readonly]="readonly"
                                                data-cy="vormundOrt"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundAdresszusatz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundAdresszusatz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vormundAdresszusatz','STANDARD.ADRESSZUSATZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDADRESSZUSATZ_INFO_FAHRKOSTEN,'STANDARD.ADRESSZUSATZ_INFO')"
                                                [readonly]="readonly"
                                                data-cy="vormundAdresszusatz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundLand')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="vormundLand"
                                class="flex-grow-1"
                                [customFormGroup]="fahrkostenForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="getFieldLabelOrTranslate('vormundLand','STANDARD.LAND')"
                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDLAND_INFO_FAHRKOSTEN,'STANDARD.LAND_INFO')"
                                [readonly]="readonly"
                                (onchange)="this.setPLZValidatorForControl('vormundPlz')"
                                data-cy="vormundLand"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundGeburtsdatum')"
                             class="form-group group-for-vormundGeburtsdatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundGeburtsdatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vormundGeburtsdatum','STANDARD.GEBURTSDATUM')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDGEBURTSDATUM_INFO_FAHRKOSTEN,'STANDARD.GEBURTSDATUM_VORMUND_INFO')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.maxDateVormundGeburtsdatum | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                                data-cy="vormundGeburtsdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundGeschlecht')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="vormundGeschlecht"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [items]="optionGeschlecht"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('vormundGeschlecht','STANDARD.GESCHLECHT.TITEL')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDGESCHLECHT_INFO_FAHRKOSTEN,'STANDARD.GESCHLECHT_INFO')"
                                                 [readonly]="readonly"
                                                 data-cy="vormundGeschlecht"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundEmail')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundEmail"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vormundEmail','STANDARD.EMAILADRESSE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDEMAIL_INFO_FAHRKOSTEN,'STANDARD.EMAIL_INFO')"
                                                [readonly]="readonly"
                                                data-cy="vormundEmail"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundTelefonnummer')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundTelefonnummer"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('vormundTelefonnummer','STANDARD.TELEFONNUMMER')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDTELEFONNUMMER_INFO_FAHRKOSTEN,'STANDARD.TELEFONNUMMER_INFO')"
                                                [readonly]="readonly"
                                                data-cy="vormundTelefonnummer"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende vormund-->

                      <!--konto-->
                      <div class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.KONTOANGABEN_TITEL_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.ANGABEN_KONTO')}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('iban')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="iban"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('iban','FAHRKOSTEN-DETAIL.IBAN')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.IBAN_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.IBAN_INFO')"
                                                [readonly]="readonly"
                                                data-cy="iban"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('bic')"
                                   class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="bic"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('bic','FAHRKOSTEN-DETAIL.BIC')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.BIC_INFO_FAHRKOSTEN,'STANDARD.BIC_INFO')"
                                                [readonly]="readonly"
                                                data-cy="bic"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('isKontoinhaberAbweichend')"
                           class="form-group group-for-name col mt-2">
                          <div class="d-flex flex-row"
                               [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                              <label  class="checkbox-container"  style="margin-bottom:3ch;">
                                {{getFieldLabelOrTranslate('isKontoinhaberAbweichend','FAHRKOSTEN-DETAIL.KONTOINHABER_ABWEICHEND')}}
                                <input type="checkbox" class="control control-checkbox custom-control-input"
                                       formControlName="isKontoinhaberAbweichend"
                                       id="isKontoinhaberAbweichend"
                                       name="isKontoinhaberAbweichend"
                                >
                                <span class="checkmark" data-cy="isKontoinhaberAbweichend"></span>
                              </label>
                              <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.KONTOINHABER_ABWEICHEND_INFO,'FAHRKOSTEN-DETAIL.KONTOINHABER_ABWEICHEND_INFO')"
                                class="infoIcon"
                                       [icon]="faInfo"
                                       title="{{getTextbausteinOrTranslate(KeyString.KONTOINHABER_ABWEICHEND_INFO,'FAHRKOSTEN-DETAIL.KONTOINHABER_ABWEICHEND_INFO')}}"
                              ></fa-icon>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberName')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberName"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('kontoinhaberName','STANDARD.NACHNAME')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.KONTOINHABERNAME_INFO_FAHRKOSTEN,'STANDARD.NAME_INFO')"
                                                [readonly]="readonly"
                                                data-cy="kontoinhaberName"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberVorname')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberVorname"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('kontoinhaberVorname','STANDARD.VORNAME')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.KONTOINHABERVORNAME_INFO_FAHRKOSTEN,'STANDARD.VORNAME_INFO')"
                                                [readonly]="readonly"
                                                data-cy="kontoinhaberVorname"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberStrasse')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberStrasse"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('kontoinhaberStrasse','STANDARD.STRASSE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.KONTOINHABERSTRASSE_INFO_FAHRKOSTEN,'STANDARD.STRASSE_INFO')"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                                                data-cy="kontoinhaberStrasse"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberPlz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberPlz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('kontoinhaberPlz','STANDARD.PLZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.KONTOINHABERPLZ_INFO_FAHRKOSTEN,'STANDARD.PLZ_INFO')"
                                                [readonly]="readonly"
                                                (onchange)="setOrt($event, 'kontoinhaberOrt')"
                                                [maxlength]="getMaxLength('kontoinhaberPlz')"
                                                [pattern]="getPattern('kontoinhaberPlz')"
                                                data-cy="kontoinhaberPlz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberOrt')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberOrt"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('kontoinhaberOrt','STANDARD.WOHNORT')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.KONTOINHABERORT_INFO_FAHRKOSTEN,'STANDARD.ORT_INFO')"
                                                [readonly]="readonly"
                                                data-cy="kontoinhaberOrt"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberAdresszusatz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberAdresszusatz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('kontoinhaberAdresszusatz','STANDARD.ADRESSZUSATZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.KONTOINAHBERADRESSZUSATZ_INFO_FAHRKOSTEN,'STANDARD.ADRESSZUSATZ_INFO')"
                                                [readonly]="readonly"
                                                data-cy="kontoinhaberAdresszusatz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberLand')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="kontoinhaberLand"
                                class="flex-grow-1"
                                [customFormGroup]="fahrkostenForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="getFieldLabelOrTranslate('kontoinhaberLand','STANDARD.LAND')"
                                [infoText]="getTextbausteinOrTranslate(KeyString.KONTOINHABERLAND_INFO_FAHRKOSTEN,'STANDARD.LAND_INFO')"
                                [readonly]="readonly"
                                (onchange)="this.setPLZValidatorForControl('kontoinhaberPlz')"
                                data-cy="kontoinhaberLand"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kontoinhaberGeburtsdatum')"
                             class="form-group group-for-kontoinhaberGeburtsdatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kontoinhaberGeburtsdatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('kontoinhaberGeburtsdatum','STANDARD.GEBURTSDATUM')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.KONTOINHABERGEBURTSDATUM_INFO_FAHRKOSTEN,'STANDARD.GEBURTSDATUM_KONTO_INFO')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                                data-cy="kontoinhaberGeburtsdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende konto-->

                      </div>
                    <!--ENDE LINKS-->

                    <!--RECHTS-->
                    <div class="col-lg-6">

                      <!--anfang erstattung-->
                      <div class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.ANGABEN_ERSTATTUNG_TITEL_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.ERSTATTUNGSANLASS')}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('typ')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="typ"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 [items]="optionTyp"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('typ','TICKETDETAIL.ANTRAGSART')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.ANTRAGSART_INFO_FAHRKOSTEN,'TICKETDETAIL.ANTRAGSART_INFO')"
                                                 [readonly]="readonly||readonlyTyp"
                                                 data-cy="typ"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('wegtyp')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="wegtyp"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 [items]="optionWegtyp"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('wegtyp','FAHRKOSTEN-DETAIL.WEGTYP.TITEL')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.WEGTYP_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.WEGTYP_INFO')"
                                                 [readonly]="readonly"
                                                 data-cy="wegtyp">
                              </app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('befoerderung')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="befoerderung"
                                                 [customFormGroup]="fahrkostenForm"
                                                 placeholder="-"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 [items]="optionBefoerderung"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('befoerderung','FAHRKOSTEN-DETAIL.BEFOERDERUNG')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.BEFOERDERUNG_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.BEFOERDERUNG_INFO')"
                                                 [readonly]="readonly"
                                                 data-cy="befoerderung"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('mitfahrerBei')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="mitfahrerBei"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('mitfahrerBei','FAHRKOSTEN-DETAIL.MITFAHRER_BEI')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.MITFAHRER_BEI_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.MITFAHRER_BEI_INFO')"
                                                [readonly]="readonly"
                                                data-cy="mitfahrerBei"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kennzeichen')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="kennzeichen"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('kennzeichen','FAHRKOSTEN-DETAIL.KENNZEICHEN')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.KENNZEICHEN_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.KENNZEICHEN_INFO')"
                                                [readonly]="readonly"
                                                data-cy="kennzeichen"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('erstattungszeitraumVon')"
                             class="form-group group-for-erstattungszeitraumVon col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="erstattungszeitraumVon"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('erstattungszeitraumVon','FAHRKOSTEN-DETAIL.ERSTATTUNG_VON')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.ERSTATTUNGSZEITRAUM_VON_INFO_FAHRKOSTEN,'')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDate | date:'yyyy-MM-dd'"
                                                data-cy="erstattungszeitraumVon"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('erstattungszeitraumBis')"
                             class="form-group group-for-erstattungszeitraumBis col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="erstattungszeitraumBis"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('erstattungszeitraumBis','FAHRKOSTEN-DETAIL.ERSTATTUNG_BIS')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.ERSTATTUNGSZEITRAUM_BIS_INFO_FAHRKOSTEN,'')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDateErstattungszeitraumBis | date:'yyyy-MM-dd'"
                                                data-cy="erstattungszeitraumBis"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('krankheitstage')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="krankheitstage"
                                                type="number"
                                                min="0"
                                                max="31"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('krankheitstage','FAHRKOSTEN-DETAIL.KRANKHEITSTAGE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.KRANKHEITSTAGE_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.KRANKHEITSTAGE_INFO')"
                                                [readonly]="readonly"
                                                data-cy="krankheitstage"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('fehltage')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="fehltage"
                                                type="number"
                                                min="0"
                                                max="31"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('fehltage','FAHRKOSTEN-DETAIL.FEHLTAGE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.FEHLTAGE_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.FEHLTAGE_INFO')"
                                                [readonly]="readonly"
                                                data-cy="fehltage"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('entfernung')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input-german-decimal id="entfernung"
                                                               [customFormGroup]="fahrkostenForm"
                                                               [label]="getFieldLabelOrTranslate('entfernung','FAHRKOSTEN-DETAIL.STRECKE')"
                                                               [infoText]="getTextbausteinOrTranslate(KeyString.ENTFERNUNG_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.STRECKE-INFO')"
                                                               [readonly] = "readonly"
                                                               [placeholder]="'0,000'"
                                                               [extra_field_text]="'STANDARD.METRIC'  | translate"
                                                               [numDigits]="3"
                                                               data-cy="entfernung"
                              ></app-custom-input-german-decimal>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('kosten')"
                             class="form-group group-for-kosten col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input-german-decimal id="kosten"
                                                               [customFormGroup]="fahrkostenForm"
                                                               [label]="getFieldLabelOrTranslate('kosten','FAHRKOSTEN-DETAIL.KOSTEN')"
                                                               [infoText]="getTextbausteinOrTranslate(KeyString.KOSTEN_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.KOSTEN_INFO')"
                                                               [readonly] = "readonly"
                                                               [placeholder]="'0,00'"
                                                               [extra_field_text]="'STANDARD.Currency' | translate"
                                                               [numDigits]="2"
                                                               data-cy="kosten"
                              ></app-custom-input-german-decimal>
                            </div>
                          </div>
                        </div>
                        <!--upload-->
                        <div *ngIf="effectivelyVisible.includes('fahrkostenNachweis')"
                             class="form-group group-for-fahrkostenNachweis col mb-4">
                          <div *ngIf="!readonly"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="fahrkostenNachweis"
                                                [customFormGroup]="fahrkostenForm"
                                                name="fahrkostenNachweis"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('fahrkostenNachweis')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [label]="getFieldLabelOrTranslate('fahrkostenNachweis','FAHRKOSTEN-DETAIL.FAHRKOSTEN-NACHWEIS')"
                                                [readonly]="readonly"
                                                data-cy="fahrkostenNachweis">
                              </app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!fahrkostenNachweis)"
                               class="d-flex flex-row mt-2">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="fahrkostenNachweisThumb">
                                {{getFieldLabelOrTranslate('fahrkostenNachweis','FAHRKOSTEN-DETAIL.FAHRKOSTEN-NACHWEIS')}}
                              </label>
                              <div class="img-thumbnail large mr-2" id="fahrkostenNachweisThumb">
                                <img *ngIf="!fahrkostenNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(fahrkostenNachweis)"
                                     [src]="sanitize(fahrkostenNachweis)"
                                     alt="img"
                                     data-cy="fahrkostenNachweisThumb"/>
                                <pdf-viewer *ngIf="fahrkostenNachweisIsPDF"
                                            [src]="fahrkostenNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(fahrkostenNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.fahrkosten"
                                       [icon]=faNope
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('fahrkostenNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--ende erstattung-->

                      <!--anfang schule-->
                      <div class="flex-row sub-paragraph ml-2 mr-2">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.ANGABEN_SCHULE_TITEL_FAHRKOSTEN,'STANDARD.ANGABEN_SCHULE')}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('schule')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="schule"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="fahrkostenForm"
                                                 [items]="schuleList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('schule','STANDARD.SCHULE')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.SCHULE_INFO_FAHRKOSTEN,'')"
                                                 [readonly]="readonly"
                                                 data-cy="schule"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-row"  *ngIf="getFormItem('isBerufskolleg').value==true && arrayContainsAnyFrom(effectivelyVisible,['bildungsgang','bildungsgangdatum','fachbereich'])">
                          <label class="checkbox-container"  style="margin-bottom:3ch;"
                                 [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                            <strong>{{'STANDARD.BERUFSKOLLEG' | translate}}</strong>
                            <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.BERUFSKOLLEG_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.BERUFSKOLLEG_INFO_FAHRKOSTEN')"
                              class="infoIcon"
                                     [icon]="faInfo"
                                     title="{{getTextbausteinOrTranslate(KeyString.BERUFSKOLLEG_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.BERUFSKOLLEG_INFO_FAHRKOSTEN')}}"
                            ></fa-icon>
                            <input type="checkbox" class="control control-checkbox custom-control-input"
                                   formControlName="isBerufskolleg"
                                   id="isBerufskolleg"
                                   name="isBerufskolleg"
                                   checked
                                   onclick="return false;"
                            >
                            <span class="checkmark" data-cy="isBerufskolleg"></span>
                          </label>
                        </div>

                        <div *ngIf="effectivelyVisible.includes('schuljahr')"
                             class="form-group group-for-schuljahr  col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="schuljahr"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="fahrkostenForm"
                                                 [placeholder]="!getFormItem('schule')?.value?'Wählen Sie zuerst die Schule aus.':'-'"
                                                 [items]="filteredSchuljahreList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindValue = "id"
                                                 bindLabel = "name"
                                                 [label]="getFieldLabelOrTranslate('schuljahr','STANDARD.SCHULJAHR')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.SCHULJAHR_INFO_FAHRKOSTEN,'')"
                                                 [readonly]="readonly || !getFormItem('schule')?.value"
                                                 data-cy="schuljahr"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('klasse')"
                             class="form-group group-for-name col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="klasse"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="fahrkostenForm"
                                                 [placeholder]="!getFormItem('schule')?.value?'Wählen Sie zuerst die Schule aus.':'-'"
                                                 [items]="filteredKlassenList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindValue = "id"
                                                 bindLabel = "name"
                                                 [label]="getFieldLabelOrTranslate('klasse','STANDARD.SCHULKLASSE')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.KLASSE_INFO_FAHRKOSTEN,'')"
                                                 [readonly]="readonly || !getFormItem('schule')?.value"
                                                 data-cy="klasse"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <!--bildungsgang-->
                        <div  *ngIf="arrayContainsAnyFrom(effectivelyVisible,['bildungsgang','bildungsgangdatum','fachbereich'])"
                              class="form-group group-for-bildungsgangFields col mt-0  mb-0">
                            <div  *ngIf="effectivelyVisible.includes('bildungsgang')"
                                  class="form-group group-for-bildungsgang col mt-0  mb-0">
                              <div class="d-flex flex-row">
                                <div class="flex-grow-1 mr-2">
                                  <app-custom-select id="bildungsgang"
                                                     [customFormGroup]="fahrkostenForm"
                                                     [placeholder]="!getFormItem('schule')?.value?'Wählen Sie zuerst die Schule aus.':'-'"
                                                     [items]="filteredBildungsgangList"
                                                     [multiple]="false"
                                                     [clearable]="false"
                                                     bindLabel="name"
                                                     bindValue="id"
                                                     [label]="getFieldLabelOrTranslate('bildungsgang','STANDARD.BILDUNGSGANG')"
                                                     [infoText]="getTextbausteinOrTranslate(KeyString.BILDUNGSGANG_INFO_FAHRKOSTEN,'')"
                                                     [infoTextBelowInput]="getBildungsgangInfotextBelowInput()"
                                                     [readonly]="readonly || !getFormItem('schule')?.value"
                                                     data-cy="bildungsgang"
                                  ></app-custom-select>
                                </div>
                            </div>
                          </div>
                          <div *ngIf="effectivelyVisible.includes('bildungsgangdatum')"
                               class="form-group group-for-bildungsgangdatum col mt-0 mb-0">
                            <div class="d-flex flex-row">
                              <div class="flex-grow-1 mr-2">
                                <app-custom-input id="bildungsgangdatum"
                                                  type="date"
                                                  [customFormGroup]="fahrkostenForm"
                                                  [label]="getFieldLabelOrTranslate('bildungsgangdatum','FAHRKOSTEN-DETAIL.BILDUNGSGANG_DATUM')"
                                                  [infoText]="getTextbausteinOrTranslate(KeyString.BILDUNGSGANGDATUM_INFO_FAHRKOSTEN,'')"
                                                  [readonly]="readonly"
                                                  data-content="dd.mm.yyyy"
                                                  [max]="this.today | date:'yyyy-MM-dd'"
                                                  [min]="this.minDate | date:'yyyy-MM-dd'"
                                                  data-cy="bildungsgangdatum"
                                ></app-custom-input>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="effectivelyVisible.includes('fachbereich')"
                               class="form-group group-for-name col mt-0  mb-0">
                            <div class="d-flex flex-row">
                              <div class="flex-grow-1 mr-2">
                                <app-custom-input id="fachbereich"
                                                  [customFormGroup]="fahrkostenForm"
                                                  [label]="getFieldLabelOrTranslate('fachbereich','FAHRKOSTEN-DETAIL.FACHBEREICH')"
                                                  [infoText]="getTextbausteinOrTranslate(KeyString.FACHBEREICH_INFO_FAHRKOSTEN,'')"
                                                  [readonly]="readonly"
                                                  data-cy="fachbereich"
                                ></app-custom-input>
                              </div>
                            </div>
                          </div>

                        </div>
                        <!--ende bildungsgang-->

                        <div *ngIf="effectivelyVisible.includes('schultageJeWoche')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="schultageJeWoche"
                                                type="number"
                                                min="0"
                                                max="6"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('schultageJeWoche','FAHRKOSTEN-DETAIL.SCHULTAGEJEWOCHE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.SCHULTAGEJEWOCHE_INFO_FAHRKOSTEN,'')"
                                                [readonly]="readonly"
                                                data-cy="schultageJeWoche"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('unterrichtszeitVon')"
                             class="form-group group-for-name col mt-0  mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="unterrichtszeitVon"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('unterrichtszeitVon','FAHRKOSTEN-DETAIL.UNTERRICHT_VON')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.UNTERRICHTSZEITENVON_INFO_FAHRKOSTEN,'')"
                                                [readonly]="readonly"
                                                data-cy="unterrichtszeitVon"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('unterrichtszeitBis')"
                             class="form-group group-for-name col mt-0  mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="unterrichtszeitBis"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('unterrichtszeitBis','FAHRKOSTEN-DETAIL.UNTERRICHT_BIS')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.UNTERRICHTSZEITENBIS_INFO_FAHRKOSTEN,'')"
                                                [readonly]="readonly"
                                                data-cy="unterrichtszeitBis"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--ende schule-->

                      <!--anfang praktikum-->
                      <div *ngIf="arrayContainsAnyFrom(effectivelyVisible,praktikumOnOff)===true"
                        class="flex-row sub-paragraph ml-2 mr-2">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.ANGABEN_PRAKTIKUM_TITLE_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.ANGABEN_ZUM_PRAKTIKUM')}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('praktikumHinweis')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <label for="praktikumHinweis">
                                {{getFieldLabelOrTranslate('praktikumHinweis','FAHRKOSTEN-DETAIL.PRAKTIKUM_HINWEIS')}}
                              </label>
                              <div class="alert alert-warning" id="praktikumHinweis">
                                {{getTextbausteinOrTranslate(KeyString.FAHRKOSTEN_PRAKTIKUM_HINWEIS,'STANDARD.PRAKTIKUM_INFO')}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumStelle')"
                             class="form-group group-for-name col mt-0  mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStelle"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('praktikumStelle','FAHRKOSTEN-DETAIL.PRAKTIKUMSTELLE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMSTELLE_INFO_FAHRKOSTEN,'STANDARD.PRAKTIKUMSTELLE_INFO')"
                                                [readonly]="readonly"
                                                data-cy="praktikumStelle"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumStrasse')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStrasse"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('praktikumStrasse','STANDARD.STRASSE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMSTRASSE_INFO_FAHRKOSTEN,'STANDARD.STRASSE_INFO')"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                                                data-cy="praktikumStrasse"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumPlz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumPlz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('praktikumPlz','STANDARD.PLZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUM_PLZ_INFO_FAHRKOSTEN,'STANDARD.PLZ_INFO')"
                                                [readonly]="readonly"
                                                (onchange)="setOrt($event, 'praktikumOrt')"
                                                [maxlength]="getMaxLength('praktikumPlz')"
                                                [pattern]="getPattern('praktikumPlz')"
                                                data-cy="praktikumPlz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumOrt')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumOrt"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('praktikumOrt','STANDARD.ORT')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMORT_INFO_FAHRKOSTEN,'STANDARD.ORT_INFO')"
                                                [readonly]="readonly"
                                                data-cy="praktikumOrt"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumAdresszusatz')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumAdresszusatz"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('praktikumAdresszusatz','STANDARD.ADRESSZUSATZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUM_ADRESSZUSATZ_INFO_FAHRKOSTEN,'STANDARD.ADRESSZUSATZ_INFO')"
                                                [readonly]="readonly"
                                                data-cy="praktikumAdresszusatz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumLand')"
                             class="form-group group-for-name col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="praktikumLand"
                                class="flex-grow-1"
                                [customFormGroup]="fahrkostenForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="getFieldLabelOrTranslate('praktikumLand','STANDARD.LAND')"
                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUM_LAND_INFO_FAHRKOSTEN,'STANDARD.LAND_INFO')"
                                [readonly]="readonly"
                                (onchange)="this.setPLZValidatorForControl('praktikumPlz')"
                                data-cy="praktikumLand"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumStartdatum')"
                             class="form-group group-for-praktikumStartdatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStartdatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('praktikumStartdatum','FAHRKOSTEN-DETAIL.PRAKTIKUM.STARTDATUM')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMSTARTDATUM_INFO_FAHRKOSTEN,'')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDate | date:'yyyy-MM-dd'"
                                                data-cy="praktikumStartdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumEnddatum')"
                             class="form-group group-for-praktikumEnddatum col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumEnddatum"
                                                type="date"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('praktikumEnddatum','FAHRKOSTEN-DETAIL.PRAKTIKUM.ENDDATUM')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMENDDATUM_INFO_FAHRKOSTEN,'')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDatePraktikumEnddatum | date:'yyyy-MM-dd'"
                                                data-cy="praktikumEnddatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('praktikumstageJeWoche')"
                             class="form-group group-for-praktikumstageJeWoche col mt-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumstageJeWoche"
                                                type="number"
                                                min="0"
                                                max="6"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('praktikumstageJeWoche','FAHRKOSTEN-DETAIL.PRAKTIKUMSTAGEJEWOCHE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMSTAGEJEWOCHE_INFO_FAHRKOSTEN,'')"
                                                [readonly]="readonly"
                                                data-cy="praktikumstageJeWoche"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <!--upload-->
                        <div *ngIf="effectivelyVisible.includes('praktikumsvertrag')"
                             class="form-group group-for-upload col mt-0">
                          <div *ngIf="!readonly" class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumsvertrag"
                                                [customFormGroup]="fahrkostenForm"
                                                name="praktikumsvertrag"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('praktikumsvertrag')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [label]="getFieldLabelOrTranslate('praktikumsvertrag','TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSVERTRAG')"
                                                [readonly]="readonly"
                                                data-cy="praktikumsvertrag"
                              ></app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!praktikumsvertrag)"
                               class="d-flex flex-row mt-0">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="praktikumsvertragThumb">
                                {{getFieldLabelOrTranslate('praktikumsvertrag','TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSVERTRAG')}}
                              </label>
                              <div class="img-thumbnail large mr-2"
                                   id="praktikumsvertragThumb">
                                <img *ngIf="!praktikumsvertragIsPDF"
                                     data-ot-ignore
                                     [src]="sanitize(praktikumsvertrag)"
                                     (click)="previewImg(praktikumsvertrag)"
                                     alt="img"
                                     data-cy="praktikumsvertragThumb"/>
                                <pdf-viewer *ngIf="praktikumsvertragIsPDF"
                                            [src]="praktikumsvertrag"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(praktikumsvertrag)"
                                            data-cy="praktikumsvertragThumbPDF"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="this.fahrkosten"
                                       [icon]=faNope
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('praktikumsvertrag')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--ende praktikum-->

                      <!--anfang Sonderfall -->
                      <div class="flex-row sub-paragraph ml-2 mr-2" *ngIf="effectivelyVisible.includes('sonderfall')">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.GRUENDE_UEBERNAHME_TITEL_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.SONDERFALL.TITEL')}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('sonderfall')"
                             class="form-group group-for-name col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-radio id="sonderfall"
                                                [options]="optionSonderfall"
                                                [customFormGroup]="fahrkostenForm"
                                                [label]="getFieldLabelOrTranslate('sonderfall','FAHRKOSTEN-DETAIL.SONDERFALL.SONDERFAELLE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.SONDERFALL_INFO_FAHRKOSTEN,'STANDARD.SONDERFALL_INFO')"
                                                [infoTextBelowInput]="getTextbausteinOrTranslate(KeyString.SONDERFALL_INFO_BELOW_FAHRKOSTEN,'')"
                                                [readonly]="readonly"
                                                [ngClass]="{'form-check-input-disabled' : readonly, 'form-check-input-active' : !readonly}"
                                                data-cy="sonderfall"
                              ></app-custom-radio>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('sonderfallBegruendung')"
                             class="form-group group-for-name col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-textarea
                                id="sonderfallBegruendung"
                                class="flex-grow-1"
                                [rows]="5"
                                [customFormGroup]="fahrkostenForm"
                                [label]="getFieldLabelOrTranslate('sonderfallBegruendung','FAHRKOSTEN-DETAIL.SONDERFALL.BEGRUENDUNG')"
                                [infoText]="getTextbausteinOrTranslate(KeyString.SONDERFALL_BEGRUEDUNG_INFO_FAHRKOSTEN,'')"
                                [readonly]="readonly"
                                data-cy="sonderfallBegruendung">
                              </app-textarea>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="arrayContainsAnyFrom(effectivelyVisible,['sonderfallSchuleAusserhalb','sonderfallSchulungsmoeglichkeit','sonderfallFamilienheimfahrten'])===true"
                             class="form-group group-for-name col mt-0 mb-0">
                          <label for="sonderfallAusserhalbContent">
                            {{getFieldLabelOrTranslate('sonderfallAusserhalbContent','FAHRKOSTEN-DETAIL.SONDERFALL.NOTNRW')}}
                          </label>
                          <div class="d-flex flex-row sub-paragraph ml-2 mr-2" id="sonderfallAusserhalbContent">
                            <div class="flex-grow-1 mr-2">
                              <div class="d-flex flex-row" style="margin-top:2ch;margin-bottom:2ch;" *ngIf="effectivelyVisible.includes('sonderfallSchuleAusserhalb')"
                                   [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                                <label class="checkbox-container">
                                  {{getFieldLabelOrTranslate('sonderfallSchuleAusserhalb','FAHRKOSTEN-DETAIL.SONDERFALL.SCHULE_AUSSERHALB')}}
                                  <input type="checkbox" class="control control-checkbox custom-control-input"
                                         formControlName="sonderfallSchuleAusserhalb"
                                         id="sonderfallSchuleAusserhalb"
                                         name="sonderfallSchuleAusserhalb"
                                         (change)="sonderfallTypChange()"
                                  >
                                  <span class="checkmark" data-cy="sonderfallSchuleAusserhalb"></span>
                                </label>
                                <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.SONDERFALL_SCHULE_AUSSERHALB_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.SONDERFALL.SCHULE_AUSSERHALB_INFO')"
                                  class="infoIcon"
                                         [icon]="faInfo"
                                         title="{{getTextbausteinOrTranslate(KeyString.SONDERFALL_SCHULE_AUSSERHALB_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.SONDERFALL.SCHULE_AUSSERHALB_INFO')}}"
                                ></fa-icon>
                              </div>
                              <div class="d-flex flex-row" style="margin-top:2ch;margin-bottom:2ch;" *ngIf="effectivelyVisible.includes('sonderfallSchulungsmoeglichkeit')"
                                   [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                                <label class="checkbox-container">
                                  {{getFieldLabelOrTranslate('sonderfallSchulungsmoeglichkeit','FAHRKOSTEN-DETAIL.SONDERFALL.SCHULUNGSMOEGLICHKEIT')}}
                                  <input type="checkbox" class="control control-checkbox custom-control-input"
                                         formControlName="sonderfallSchulungsmoeglichkeit"
                                         id="sonderfallSchulungsmoeglichkeit"
                                         name="sonderfallSchulungsmoeglichkeit"
                                         (change)="sonderfallTypChange()"
                                  >
                                  <span class="checkmark" data-cy="sonderfallSchulungsmoeglichkeit"></span>
                                </label>
                                <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.SONDERFALL_SCHULUNGSMOEGLICHKEIT_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.SONDERFALL.SCHULUNGSMOEGLICHKEIT_INFO')"
                                         class="infoIcon"
                                         [icon]="faInfo"
                                         title="{{getTextbausteinOrTranslate(KeyString.SONDERFALL_SCHULUNGSMOEGLICHKEIT_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.SONDERFALL.SCHULUNGSMOEGLICHKEIT_INFO')}}"
                                ></fa-icon>
                              </div>
                              <div class="d-flex flex-row" style="margin-top:2ch;margin-bottom:2ch;" *ngIf="effectivelyVisible.includes('sonderfallFamilienheimfahrten')"
                                   [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                                <label class="checkbox-container">
                                  {{getFieldLabelOrTranslate('sonderfallFamilienheimfahrten','FAHRKOSTEN-DETAIL.SONDERFALL.FAMILIENHEIMFAHRTEN')}}
                                  <input type="checkbox" class="control control-checkbox custom-control-input"
                                         formControlName="sonderfallFamilienheimfahrten"
                                         id="sonderfallFamilienheimfahrten"
                                         name="sonderfallFamilienheimfahrten"
                                         (change)="sonderfallTypChange()"
                                  >
                                  <span class="checkmark" data-cy="sonderfallFamilienheimfahrten"></span>
                                </label>
                                <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.SONDERFALL_FAMILIENHEIMFAHRTEN_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.SONDERFALL.FAMILIENHEIMFAHRTEN_INFO')"
                                         class="infoIcon"
                                         [icon]="faInfo"
                                         title="{{getTextbausteinOrTranslate(KeyString.SONDERFALL_FAMILIENHEIMFAHRTEN_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.SONDERFALL.FAMILIENHEIMFAHRTEN_INFO')}}"
                                ></fa-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--upload-->
                        <div *ngIf="effectivelyVisible.includes('sonderfallNachweis')"
                             class="form-group group-for-sonderfallNachweis col mb-4">
                          <div *ngIf="!readonly"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="sonderfallNachweis"
                                                [customFormGroup]="fahrkostenForm"
                                                name="sonderfallNachweis"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('sonderfallNachweis')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [label]="getFieldLabelOrTranslate('sonderfallNachweis','TICKETDETAIL.SONDERFALL.NACHWEIS')"
                                                [readonly]="readonly"
                                                data-cy="sonderfallNachweis"
                              ></app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!!sonderfallNachweis"
                               class="d-flex flex-row mt-2">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="sonderfallNachweisThumb">
                                {{getFieldLabelOrTranslate('sonderfallNachweis','TICKETDETAIL.SONDERFALL.NACHWEIS')}}
                              </label>
                              <div class="img-thumbnail large mr-2" id="sonderfallNachweisThumb">
                                <img *ngIf="!sonderfallNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(sonderfallNachweis)"
                                     [src]="sanitize(sonderfallNachweis)"
                                     alt="img"
                                     data-cy="sonderfallNachweisThumb"/>
                                <pdf-viewer *ngIf="sonderfallNachweisIsPDF"
                                            [src]="sonderfallNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(sonderfallNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.fahrkosten"
                                       [icon]=faNope
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('sonderfallNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--ende Sonderfall-->

                      <!--anfang Asyl-->
                      <div *ngIf="effectivelyVisible.includes('bezugAsylbewerberleistungsgesetz')" class="flex-row sub-paragraph ml-2 mr-2">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.GRUENDE_ERHOEHTE_TITEL_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.BESONDERE_GRUENDE_KOSTEN')}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('bezugAsylbewerberleistungsgesetz')"
                             class="form-group group-for-name col mt-2 mb-3">
                          <div class="d-flex flex-row"
                               [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                              <label class="checkbox-container">
                                {{getFieldLabelOrTranslate('bezugAsylbewerberleistungsgesetz','TICKETDETAIL.BEZUG_ASYLBEWERBERLEISTUNGSGESETZ')}}
                                <input type="checkbox" class="control control-checkbox custom-control-input"
                                       formControlName="bezugAsylbewerberleistungsgesetz"
                                       id="bezugAsylbewerberleistungsgesetz"
                                       name="bezugAsylbewerberleistungsgesetz"
                                >
                                <span class="checkmark" data-cy="bezugAsylbewerberleistungsgesetz"></span>
                              </label>
                            <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.ASYL_INFO_FAHRKOSTEN,'STANDARD.ASYL_INFO')"
                                      class="infoIcon"
                                     [icon]="faInfo"
                                     title="{{getTextbausteinOrTranslate(KeyString.ASYL_INFO_FAHRKOSTEN,'STANDARD.ASYL_INFO')}}"
                            ></fa-icon>
                            </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('bezugNachweis')"
                             class="form-group group-for-bezugNachweis col mb-4 mt-4">
                          <div *ngIf="!readonly"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="bezugNachweis"
                                                [customFormGroup]="fahrkostenForm"
                                                name="bezugNachweis"
                                                [type]="'file'"
                                                [clearable]="true"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('bezugNachweis')"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [label]="getFieldLabelOrTranslate('bezugNachweis','TICKETDETAIL.BEZUG_NACHWEIS')"
                                                [readonly]="readonly"
                                                data-cy="bezugNachweis"
                              ></app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="bezugNachweis"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="bezugNachweisThumb">
                                {{getFieldLabelOrTranslate('bezugNachweis','TICKETDETAIL.BEZUG_NACHWEIS')}}
                              </label>
                              <div class="img-thumbnail large mr-2" id="bezugNachweisThumb">
                                <img *ngIf="!bezugNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(bezugNachweis)"
                                     [src]="sanitize(bezugNachweis)"
                                     alt="img"
                                     data-cy="bezugNachweisThumb"/>
                                <pdf-viewer *ngIf="bezugNachweisIsPDF"
                                            [src]="bezugNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(bezugNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.fahrkosten"
                                       [icon]=faNope
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('bezugNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>
                        <!--ende bezugnachweis-->

                      </div>
                      <!--ende Asyl-->

                    </div>
                    <!--ENDE RECHTS-->

                  </div>
                  <!--ende Teil mit Angaben-->

                  <!-- anfang belegupload -->
                  <div *ngIf="cardsFkkUniversalOn===true">

                    <hr class="hr-fahrkosten">
                    <legend>
                      {{getTextbausteinOrTranslate(KeyString.KOSTEN_NACHWEISE_TITEL_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.LEGEND-NACHWEISE')}}
                    </legend>
                    <div class="row">
                      <span style="margin: 0 0 20px 15px" *ngIf="!readonly">
                        {{getTextbausteinOrTranslate(KeyString.NACHWEISHINZUFUEGEN_INFO_FAHRKOSTEN,'FAHRKOSTEN-DETAIL.LEGEND-NACHWEISE-INFO')}}
                      </span>
                    </div>
                    <div class="row justify-content-evenly">
                      <div class="row ml-2 cardAdd mb-3" *ngIf="!readonly">
                        <h5 (click)="addFahrkostenKostenUniversalCard()" data-cy="addFahrkostenKostenUniversalCard">
                          <fa-icon  id="nachweisAddBtn"
                                    type="btn btn-secondary-icon" [icon]="faCirclePlus"
                                    class="fa-xl custom-card-btn-nachweisAdd mr-2"
                                    title="{{getTextbausteinOrTranslate(KeyString.NACHWEISHINZUFUEGEN_INFO_FAHRKOSTEN,'')}}"
                          ></fa-icon>
                          <label for="nachweisAddBtn" style="cursor: pointer;">
                            {{ 'FAHRKOSTEN-DETAIL.NACHWEIS-HINZUFUEGEN' | translate }}
                          </label>
                        </h5>
                      </div>
                      <div  class="col-lg-4 col-md-6 col-sm-8 mb-1"
                           *ngFor="let fkKosten of getFormItem('fahrkostenKostenUniversalList').value" style="padding-bottom:1rem">
                        <app-fahrkosten-universal [fahrkostenId]="this.fahrkostenId? this.fahrkostenId : undefined"
                                                  [kostenId] = "fkKosten.kostenId"
                                                  [fkkUniversal]="fkKosten"
                                                  [readonly]="readonly"
                                                  [hasSB]="true"
                                                  [arrayIndex]="getArrayIndexOf(fkKosten)"
                                                  [fieldConfig]="fieldConfigFkkUniversal"
                                                  [visibleFields]="visibleFieldsFkkUniversal"
                                                  [requiredFields]="requiredFieldsFkkUniversal"
                                                  [isSubmitted]="isSubmitted"
                                                  [flipFlop]="flipFlop"
                                                  (removeFromList)="removeFahrkostenKostenUniversalCard($event)"
                                                  data-cy="universalCard"
                        ></app-fahrkosten-universal>
                      </div>
                    </div>
                  </div>
                  <!-- ende belegupload -->

                  <hr class="hr-fahrkosten">

                  <!--ACCEPT-->
                  <div *ngIf="effectivelyVisible.includes('acceptTerms')"
                       class="form-group group-for-acceptTerms col mt-2">
                    <label for="acceptTermsGroup">
                      <strong>{{'FAHRKOSTEN-DETAIL.BEACHTEN' | translate}}</strong>
                    </label>
                    <div class="d-flex flex-row" id="acceptTermsGroup"
                         [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                        <label class="checkbox-container"  style="margin-bottom:3ch;" for="acceptTerms">
                          <p [innerHTML]="fieldConfigService.getFieldLabel('acceptTerms', fieldConfig, 'FAHRKOSTEN-DETAIL.DATENSCHUTZ' | translate)"></p>
                          <input type="checkbox" class="control control-checkbox custom-control-input"
                                 formControlName="acceptTerms"
                                 id="acceptTerms"
                                 name="acceptTerms"
                          >
                          <span class="checkmark" data-cy="acceptTerms"></span>
                        </label>
                        <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.DATENSCHUTZ_INFO_FAHRKOSTEN,'STANDARD.DATENSCHUTZ_INFO')"
                          class="infoIcon"
                                 [icon]="faInfo"
                                 title="{{getTextbausteinOrTranslate(KeyString.DATENSCHUTZ_INFO_FAHRKOSTEN,'STANDARD.DATENSCHUTZ_INFO')}}"
                        ></fa-icon>
                    </div>
                  </div>

                  <!--BUTTONS-->
                  <div class="d-flex flex-row-reverse">
                    <input *ngIf="isDeleteDraftButtonVisible" type="button" value="Entwurf löschen"
                           class="btn btn-secondary save btn-block" (click)="deleteDraft()"
                           data-cy="deleteDraft">
                    <span style="width: 1em"></span>
                    <input *ngIf="isUpdateEntwurfButtonVisible" type="button" value="Entwurf speichern"
                           class="btn btn-secondary save btn-block"
                           (click)="saveOrUpdate('ENTWURF','Entwurf wurde erfolgreich gespeichert.', 'fahrkosten-detail')"
                           data-cy="saveOrUpdate">
                    <span style="width: 1em"></span>
                    <input *ngIf="isEinreichenButtonVisible" type="button" value="Antrag einreichen"
                           class="btn btn-primary save btn-block"
                           (click)="saveOrUpdate('EINGEREICHT','Antrag wurde erfolgreich eingereicht.','fahrkosten')"
                           data-cy="antragEinreichen">
                  </div>

                </form>
              </div>
            </div>
            <!--ende form-->

          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen] = "true"
  >
  </ngx-spinner>
</main>
