import {Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {faClock, faDownload, faHandPointUp, faInfo, faPen, faUser} from "@fortawesome/free-solid-svg-icons";
import {AntragTicketDTO} from "../../../../../models/antrag-ticket/AntragTicketDTO";
import {AntragTicketService} from "../../../../../@core/services/antrag-ticket/antrag-ticket.service";
import {ModuleConfigService} from "../../../../../@core/services/module-config/module-config.service";
import {TranslateService} from "@ngx-translate/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {TicketAntragStatus} from "../../../../../models/antrag-ticket/TicketAntragStatus";

@Component({
  selector: 'app-sidebar-component',
  templateUrl: './ticket-detail-sidebar.component.html',
  styleUrl: './ticket-detail-sidebar.component.scss'
})
export class TicketDetailSidebarComponent implements OnInit, OnChanges {

  @Input()
  antragTicketId: string;

  readonly destroyRef = inject(DestroyRef);

  antragTicket: AntragTicketDTO;
  postkorbReference: string;
  draftStatus = TicketAntragStatus.ENTWURF;

  constructor(
    private antragTicketService: AntragTicketService,
    public moduleConfigService: ModuleConfigService,
    public translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.antragTicketService.setAntragID$.next(this.antragTicketId);
    this.antragTicketService.antragObs$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(antrag => {
          this.antragTicket = antrag;
          this.postkorbReference = antrag?.postkorbReference;
        });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.antragTicketId.previousValue !== changes.antragTicketId.currentValue && !changes.antragTicketId.previousValue){
      this.antragTicketService.setAntragID$.next(changes.antragTicketId.currentValue);
    }
  }


    protected readonly faPen = faPen;
    protected readonly faHand = faHandPointUp;
    protected readonly faUser = faUser;
    protected readonly faClock = faClock;
    protected readonly faDownload = faDownload;
  protected readonly faInfo = faInfo;
}
