import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PagesComponent} from './pages.component';
import {RouterModule} from '@angular/router';
import {PagesRoutingModule} from './pages-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {
  NgbAccordionModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbTypeaheadModule
} from "@ng-bootstrap/ng-bootstrap";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TranslateModule} from "@ngx-translate/core";
import {TicketTableComponent} from './antrag-ticket/ticket-table/ticket-table.component';
import {DefaultLayoutComponent} from "../shared/default-layout/default-layout.component";
import {SchuelerTableComponent} from './schueler/schueler-table/schueler-table.component';
import {SchuelerDetailComponent} from './schueler/schueler-detail/schueler-detail.component';
import {CheckboxComponent} from "../shared/form/checkbox/checkbox.component";
import {CustomRadioComponent} from "../shared/form/custom-radio/custom-radio.component";
import {InputComponent} from "../shared/form/input/input.component";
import {SelectComponent} from "../shared/form/select/select.component";
import {TextareaComponent} from "../shared/form/textarea/textarea.component";
import {NgSelectModule} from '@ng-select/ng-select';
import {PictureFileComponent} from './picture-file/picture-file/picture-file.component';
import {TicketDetailSbTrueComponent} from "./antrag-ticket/ticket-detail-sb-true/ticket-detail-sb-true.component";
import {TicketDetailSbFalseComponent} from "./antrag-ticket/ticket-detail-sb-false/ticket-detail-sb-false.component";
import {TicketDetailParentComponent} from "./antrag-ticket/ticket-detail-parent/ticket-detail-parent.component";
import {FahrkostenTableComponent} from "./fahrkosten/fahrkosten-table/fahrkosten-table.component";
import {
  FahrkostenDetailParentComponent
} from "./fahrkosten/fahrkosten-detail-parent/fahrkosten-detail-parent.component";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {
  FahrkostenDetailSbTrueComponent
} from './fahrkosten/fahrkosten-detail-sb-true/fahrkosten-detail-sb-true.component';
import {
  FahrkostenDetailSbFalseComponent
} from './fahrkosten/fahrkosten-detail-sb-false/fahrkosten-detail-sb-false.component';
import {FahrkostenUniversalComponent} from './fahrkosten/fahrkosten-universal/fahrkosten-universal.component';
import {FahrkostenBelegeComponent} from './fahrkosten/fahrkosten-belege/fahrkosten-belege.component';
import {
  FahrkostenUniversalSbFalseComponent
} from './fahrkosten/fahrkosten-universal-sb-false/fahrkosten-universal-sb-false.component';
import {ConfigComponent} from './admin/config/config.component';
import {TextbausteineComponent} from './admin/textbausteine/textbausteine.component';
import {TextbausteineDetailComponent} from './admin/textbausteine/textbausteine-detail/textbausteine-detail.component';
import {SchulenComponent} from './admin/schulen/schulen.component';
import {SchulenDetailComponent} from './admin/schulen/schulen-detail/schulen-detail.component';
import {GeoMapComponent} from "../shared/geo-map/geo-map.component";
import {SchulekoordinatenComponent} from './admin/schulen/schulekoordinaten/schulekoordinaten.component';
import {SchultypenComponent} from './admin/schultypen/schultypen.component';
import {SchultypenDetailComponent} from './admin/schultypen/schultypen-detail/schultypen-detail.component';
import {
  FahrkostenBelegeSbFalseComponent
} from './fahrkosten/fahrkosten-belege-sb-false/fahrkosten-belege-sb-false.component';
import {HomeComponent} from './home/home.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {GermanDecimalDirective} from "../shared/form/german-decimal/german-decimal.directive";
import {
  GermanDecimalAnydigitsComponent
} from "../shared/form/german-decimal/german-decimal-anydigits/german-decimal-anydigits.component";
import {ProfilComponent} from './profil/profil.component';
import {AccountComponent} from "./account/account.component";
import {
  AttachmentTypeAdjustComponent
} from "./antrag-ticket/ticket-detail-sb-true/sidebar/attachment-type-adjust/attachment-type-adjust.component";
import {
  TicketAntragAttachmentUploadComponent
} from "./antrag-ticket/ticket-detail-sb-true/sidebar/attachment-upload/ticket-antrag-attachment-upload.component";
import {
  TicketDetailSidebarComponent
} from "./antrag-ticket/ticket-detail-sb-true/sidebar/sidebar-component/ticket-detail-sidebar.component";
import {
  TicketAntragSidebarAttachmentsComponent
} from "./antrag-ticket/ticket-detail-sb-true/sidebar/ticket-antrag-sidebar-attachments/ticket-antrag-sidebar-attachments.component";


@NgModule({
	declarations: [
		PagesComponent,
    TicketTableComponent,
    TicketDetailParentComponent,
    TicketDetailSbFalseComponent,
    TicketDetailSbTrueComponent,
    TicketAntragSidebarAttachmentsComponent,
    TicketDetailSidebarComponent,
    TicketAntragAttachmentUploadComponent,
    AttachmentTypeAdjustComponent,
    FahrkostenTableComponent,
    FahrkostenDetailParentComponent,
    DefaultLayoutComponent,
    SchuelerTableComponent,
    SchuelerDetailComponent,
    CheckboxComponent,
    CustomRadioComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    PictureFileComponent,
    FahrkostenDetailSbTrueComponent,
    FahrkostenDetailSbFalseComponent,
    FahrkostenUniversalComponent,
    FahrkostenBelegeComponent,
    FahrkostenUniversalSbFalseComponent,
    ConfigComponent,
    TextbausteineComponent,
    TextbausteineDetailComponent,
    SchulenComponent,
    SchulenDetailComponent,
    GeoMapComponent,
    SchulekoordinatenComponent,
    SchultypenComponent,
    SchultypenDetailComponent,
    FahrkostenBelegeSbFalseComponent,
    HomeComponent,
    GermanDecimalDirective,
    GermanDecimalAnydigitsComponent,
    ProfilComponent,
    AccountComponent
	],
  imports: [
    CommonModule,
    RouterModule,
    PagesRoutingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslateModule,
    NgbDropdownModule,
    NgxDatatableModule,
    NgbTypeaheadModule,
    NgSelectModule,
    NgbDatepickerModule,
    PdfViewerModule,
    NgxSpinnerModule,
    NgbAccordionModule
  ],
  exports: [
    DefaultLayoutComponent,
    InputComponent,
    SelectComponent
  ],
  bootstrap: []
})
export class PagesModule {}
