import {Component, OnInit} from '@angular/core';
import {TextbausteinService} from "../../@core/services/textbaustein/textbaustein.service";
import {environment} from "../../../environments/environment";
import {VersionService} from "../../@core/services/version/version.service";
import {SessionService} from "../../@core/services/session/session.service";
import {KeyString} from "../../models/textbaustein/KeyString";

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  version = environment.version;
  backendVersion: string;
  existsSessionStorageTextbausteine: boolean = false;

  constructor(
    public textbausteinService: TextbausteinService,
    public versionService : VersionService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.versionService.getVersion()
      .subscribe(version => this.backendVersion = version.version);
    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });
  }

  defaultImpressum: string =
    "<h4>Herausgeber</h4>" +
    "*Kontaktdaten Schulträger* <br>" +
    "<p>" +
    "(Sollten Kontaktdaten fehlen, wenden Sie sich bitte für Informationen an Ihre zuständige Schule.) <br>" +
    "*Der Schulträger* ist eine Körperschaft des öffentlichen Rechts. Sie wird vertreten durch *Landrat*. <br>" +
    "</p>" +
    "<h4>Zuständig für den Bereich Schülerfahrkosten</h4>" +
    "<p>" +
    "*Kontaktdaten Schülerfahrkosten* <br>" +
    "(Sollten Kontaktdaten fehlen, wenden Sie sich bitte für Informationen an Ihre zuständige Schule.)" +
    "</p>" +
    "<h4>Haftungsausschluss</h4>" +
    "<h5>Eigene Seiten</h5>" +
    "<p>" +
    "Der Schulträger übernimmt keine Haftung oder Garantie für die Aktualität, " +
    "Richtigkeit und Vollständigkeit der bereitgestellten Informationen. " +
    "Die Inhalte dieser Seiten basieren teilweise auf gesetzlichen Grundlagen und werden regelmäßig überprüft. " +
    "Es kann aber nicht garantiert werden, dass nach einer gesetzlichen Änderung " +
    "eine sofortige Anpassung der Internet-Seiten erfolgt. " +
    "Der Schulträger übernimmt keine Haftung für direkte oder indirekte Schäden, " +
    "die aus der Benutzung dieser Seiten entstehen können. " +
    "Ferner steht das Angebot unter dem Vorbehalt der technischen Verfügbarkeit, " +
    "insbesondere im Hinblick auf erforderliche Wartungsarbeiten und Ausfälle durch technische Störungen. " +
    "Alle Angebote sind freibleibend und unverbindlich. " +
    "Der Schulträger behält es sich ausdrücklich vor, " +
    "Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung " +
    "zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen." +
    "</p>" +
    "<h5>Externe Seiten und Links</h5>" +
    "<p>" +
    "Downloads und Links werden von den hierfür verantwortlichen und zugangsberechtigen Personen eingestellt und verwaltet. " +
    "Soweit ein Text von dritter Seite erstellt ist, wird der jeweilige Verfasser oder die verantwortliche Einrichtung namentlich benannt. " +
    "In diesen Fällen ist der Verfasser bzw. die genannte Einrichtung für den Inhalt des jeweiligen Textes verantwortlich. " +
    "Der Schulträger übernimmt keine Haftung oder Garantie für die Aktualität, Richtigkeit oder Vollständigkeit " +
    "der auf diesen Seiten veröffentlichten Inhalte. " +
    "Gleiches gilt für deren Rechtmäßigkeit oder für die Erfüllung von Urheberrechtsbestimmungen " +
    "im Zusammenhang mit den auf der jeweiligen Website veröffentlichten Inhalten. " +
    "Der Schulträger übernimmt keine Haftung für direkte oder indirekte Schäden, " +
    "die aus der Benutzung dieser Software entstehen können." +
    "</p>" +
    "<h5>Daten des Schulträgers</h5>" +
    "<p>" +
    "Die Nutzung der in diesem Rahmen oder vergleichbarer Angaben veröffentlichten Kontaktdaten " +
    "wie Postanschriften, Telefon- und Faxnummern sowie E-Mail-Adressen durch Dritte " +
    "zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. " +
    "Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails " +
    "bei Verstößen gegen dieses Verbot sind ausdrücklich vorbehalten." +
    "</p>" +
    "<h5>Urheberrecht</h5>" +
    "<p>" +
    "Das Layout, das Design und die Inhalte der Online Anwendung sind urheberrechtlich geschützt. " +
    "Die Vervielfältigung oder Verwendung der Anwendung oder von Teilen der Anwendung, " +
    "insbesondere von Texten, Textteilen, Bildern und Grafiken, " +
    "bedarf der ausdrücklichen Erlaubnis des Schulträgers. " +
    "Sie dürfen Daten für persönliche und sonstige eigene Zwecke " +
    "im Rahmen der Regelungen des Urheberrechts herunterladen." +
    "</p>" +
    "<h5>Rechtswirksamkeit dieses Haftungsausschlusses</h5>" +
    "<p>" +
    "Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, " +
    "von dem aus auf diese Seite verwiesen wurde. " +
    "Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, " +
    "nicht mehr oder nicht vollständig entsprechen sollten, " +
    "bleiben die übrigen Teile des Dokuments in ihrem Inhalt und ihrer Gültigkeit davon unberührt." +
    "</p>"

    protected readonly KeyString = KeyString;
}
