
<app-default-layout
  [title]="'MODAL.ADJUST_ATTACHMENT_TYPE_TITLE' | translate"
>


  <app-custom-select id="type"
                     [customFormGroup]="dateiForm"
                     placeholder="-"
                     [items]="attachmentTypes"
                     bindLabel="translation"
                     bindValue="name"
                     label="{{'ATTACHMENT.ATTACHMENTTYPE' |translate }}"
                     [infoText]="'ATTACHMENT.ATTACHMENTTYPE_INFO' |translate"
                     [multiple]="false"
                     [clearable]="false"
  ></app-custom-select>

  <div class="row pt-0" style="margin-top:-2ch;">
    <div class="frame frame-default mt-0 pt-0">
      <div class="d-flex flex-row-reverse mt-1">
        <button type="button"  (click)="reject()"
                name="reject" class="btn btn-outline-primary"
                style="min-width: 10em"
                data-cy="no">
          {{'APPLICATION.BUTTON_ABBRECHEN'|translate}}
        </button>
        <span style="width: 1em"></span>
        <button type="button" (click)="accept()"
                class="btn btn-primary" style="min-width: 10em"
                data-cy="yes">
          {{'APPLICATION.BUTTON_BESTAETIGEN' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-default-layout>
