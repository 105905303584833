import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {delay, interval, map, Observable, shareReplay} from "rxjs";
import {TimeComponents} from "./timeComponents";

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrl: './countdown.component.scss'
})
export class CountdownComponent implements OnInit, AfterViewInit {
  protected endDateForLogout: Date = null;
  protected loggedoutMessageVisible: boolean = false;

  constructor() {

  }

  @Input()
  public endDateObservable$: Observable<Date>;

  public timeLeft$: Observable<TimeComponents>;

  @Input()
  public loggedoutMessageVisible$ : Observable<boolean>;


  ngOnInit() {

  }



  ngAfterViewInit() {
    this.endDateObservable$
      .pipe(delay(0)) //"delay" here is an alternative to setTimeout()
      .subscribe(value => {
        // console.log('CountdownComponent: endDateObservable$:', value);
        // if(value.getMilliseconds() > new Date().getMilliseconds()) {
          this.endDateForLogout = value;
          this.startTimer(this.endDateForLogout);
        // }

        }
      );
    this.loggedoutMessageVisible$.subscribe(isVisible => {
      // Perform necessary actions when loggedoutMessageVisible$ changes
      this.loggedoutMessageVisible = isVisible;
    });
  }

  calcDateDiff(endDay: Date = new Date()): TimeComponents {
    const dDay = endDay.valueOf();

    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;

    const timeDifference = dDay - Date.now();

    const daysToDday = Math.floor(
      timeDifference /
      (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
    );

    const hoursToDday = Math.floor(
      (timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
      hoursInADay
    );

    const minutesToDday = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
      secondsInAMinute
    );

    const secondsToDday =
      Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;
    // if(secondsToDday <= 0 && minutesToDday <= 0 && hoursToDday <= 0 && daysToDday <= 0){
    //   this.timeLeft$ = new Observable<TimeComponents>();
    // }

    return { secondsToDday, minutesToDday, hoursToDday, daysToDday };
  }

  private startTimer(endDate: Date = null) {
    if(endDate != null ) {
      this.timeLeft$ = interval(1000).pipe(
        map(x => this.calcDateDiff(endDate)),
        shareReplay(1)
      );
    }
  }

}
