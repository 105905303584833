import {Component, Input, OnInit} from '@angular/core';
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";

@Component({
  selector: 'app-picture-file',
  templateUrl: './picture-file.component.html',
  styleUrls: ['./picture-file.component.css']
})
export class PictureFileComponent implements OnInit {

  @Input() image: string;
  @Input() pdf: string;
  @Input() width: number;
  @Input() height: number;

  constructor(  private customToastService: CustomToastService) { }

  ngOnInit(): void {
  }

  protected readonly faDownload = faDownload;

  public download(type:string){
    console.log("triggered download");
    const link = document.createElement("a")
    if(type === 'img') {
      link.href = this.image;
    } else if (type === 'pdf') {
      link.href = this.pdf;
    }
    else{
      this.customToastService.showError(
        'Ungültiges Dateiformat'
      );
      return;
    }
    link.download = new Date().toJSON().slice(0,10) +'_sfk_download'
    link.click()

    link.remove()
  }

  protected readonly faSave = faDownload;
  protected readonly window = window;
  protected readonly getComputedStyle = getComputedStyle;
  protected readonly document = document;
}
