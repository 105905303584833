<app-default-layout
  [title]="existsSessionStorageTextbausteine?
            textbausteinService.printTextbausteinByKey( KeyString.PERSON_SCHUELER_SINGULAR, ('COMPOSITE-MESSAGE.DERDIEDAS.SCHUELER'|translate) ):('COMPOSITE-MESSAGE.DERDIEDAS.SCHUELER'|translate)"
>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="frame frame-default">
        <div id="create-schueler" class="content scaffold-create" role="main">
          <form [formGroup]="schuelerForm" (ngSubmit)="saveOrUpdate()" id="schuelerForm" class="form-horizontal form-schuelerForm" accept-charset="UTF-8">

            <div class="form-group group-for-name col mb-3"
                 *ngIf="isVisible('name')"
            >
              <app-custom-input id="name" type="text"
                                class="flex-grow-1"
                                [label]="getFieldLabelOrTranslate('name','STANDARD.NACHNAME')"
                                [customFormGroup]="schuelerForm"
                                data-cy="name"
              ></app-custom-input>
            </div>

            <div class="form-group group-for-vorname col mb-3"
                 *ngIf="isVisible('vorname')"
            >
              <app-custom-input id="vorname" type="text"
                                class="flex-grow-1"
                                [label]="getFieldLabelOrTranslate('vorname','STANDARD.VORNAME')"
                                [customFormGroup]="schuelerForm"
                                data-cy="vorname"
              ></app-custom-input>
            </div>

            <div class="form-group group-for-strasse col mb-3"
                 *ngIf="isVisible('strasse')"
            >
              <app-custom-input id="strasse" type="text"
                                class="flex-grow-1"
                                [label]="getFieldLabelOrTranslate('strasse','STANDARD.STRASSE')"
                                [customFormGroup]="schuelerForm"
                                [infoTextBelowInput]="translateService.instant('SCHUELER-DETAIL.ADRESSAENDERUNG_INFO')"
                                data-cy="strasse"
              ></app-custom-input>
            </div>

            <div class="form-group group-for-plz col mb-3"
                 *ngIf="isVisible('plz')"
            >
              <app-custom-input id="plz" type="text"
                                class="flex-grow-1"
                                [label]="getFieldLabelOrTranslate('plz','STANDARD.PLZ')"
                                [customFormGroup]="schuelerForm"
                                (onchange)="setOrt($event, 'ort')"
                                [maxlength]="getMaxLength('plz')"
                                [pattern]="getPattern('plz')"
                                [infoTextBelowInput]="translateService.instant('SCHUELER-DETAIL.ADRESSAENDERUNG_INFO')"
                                data-cy="plz"
              ></app-custom-input>
            </div>

            <div class="form-group group-for-ort col mb-3"
                 *ngIf="isVisible('ort')"
            >
              <app-custom-input id="ort" type="text"
                                class="flex-grow-1"
                                [label]="getFieldLabelOrTranslate('ort','STANDARD.ORT')"
                                [customFormGroup]="schuelerForm"
                                [infoTextBelowInput]="translateService.instant('SCHUELER-DETAIL.ADRESSAENDERUNG_INFO')"
                                data-cy="ort"
              ></app-custom-input>
            </div>

            <div class="form-group group-for-adresszusatz col mb-3"
                 *ngIf="isVisible('adresszusatz')"
            >
              <app-custom-input id="adresszusatz" type="text"
                                class="flex-grow-1"
                                [label]="getFieldLabelOrTranslate('adresszusatz','STANDARD.ADRESSZUSATZ')"
                                [customFormGroup]="schuelerForm"
                                [infoTextBelowInput]="translateService.instant('SCHUELER-DETAIL.ADRESSAENDERUNG_INFO')"
                                data-cy="adresszusatz"
              ></app-custom-input>
            </div>

            <div class="form-group group-for-land col mb-3"
                 *ngIf="isVisible('land')"
            >
              <app-custom-select
                id="land"
                class="flex-grow-1"
                [customFormGroup]="schuelerForm"
                [items]="landList"
                [multiple]="false"
                [clearable]="false"
                bindLabel="value"
                bindValue="name"
                [label]="getFieldLabelOrTranslate('land','STANDARD.LAND')"
                [infoTextBelowInput]="translateService.instant('SCHUELER-DETAIL.ADRESSAENDERUNG_INFO')"
                data-cy="land"
              ></app-custom-select>
            </div>

            <div class="form-group group-for-schule col mb-3"
                 *ngIf="isVisible('schule')"
            >
              <app-custom-select
                id="schule"
                class="flex-grow-1"
                [customFormGroup]="schuelerForm"
                [items]="schuleList"
                bindLabel="name"
                bindValue="id"
                placeholder="-"
                [multiple]="false"
                [clearable]="false"
                [label]="getFieldLabelOrTranslate('schule','STANDARD.SCHULE')"
                data-cy="schule"
              ></app-custom-select>
            </div>

            <div class="form-group group-for-geburtsdatum col mb-3"
                 *ngIf="isVisible('geburtsdatum')"
            >
              <app-custom-input id="geburtsdatum" type="date"
                                class="flex-grow-1"
                                [label]="getFieldLabelOrTranslate('geburtsdatum','STANDARD.GEBURTSDATUM')"
                                [customFormGroup]="schuelerForm"
                                data-cy="geburtsdatum"
              ></app-custom-input>
            </div>

            <div class="form-group group-for-schuelernummer col mb-3"
                 *ngIf="isVisible('schuelernummer')"
            >
              <app-custom-input id="schuelernummer" type="text"
                                class="flex-grow-1"
                                [label]="getFieldLabelOrTranslate('schuelernummer','STANDARD.SCHUELERNUMMER')"
                                [customFormGroup]="schuelerForm"
                                data-cy="schuelernummer"
              ></app-custom-input>
            </div>

            <div class="form-group group-for-geschlecht col mb-3"
                 *ngIf="isVisible('geschlecht')"
            >
              <app-custom-select id="geschlecht"
                                 class="flex-grow-1"
                                 [customFormGroup]="schuelerForm"
                                 [items]="geschlechtList"
                                 bindLabel="name"
                                 bindValue="id"
                                 placeholder="-"
                                 [multiple]="false"
                                 [clearable]="false"
                                 [label]="getFieldLabelOrTranslate('geschlecht','STANDARD.GESCHLECHT.TITEL')"
                                 data-cy="geschlecht"
              ></app-custom-select>
            </div>

            <div class="form-group group-for-abonnementnummer col mb-3"
                 *ngIf="isVisible('abonnementnummer')"
            >
              <app-custom-input id="abonnementnummer" type="text"
                                class="flex-grow-1"
                                [label]="getFieldLabelOrTranslate('abonnementnummer','STANDARD.ABONNEMENTNUMMER')"
                                [customFormGroup]="schuelerForm"
                                data-cy="abonnementnummer"
              ></app-custom-input>
            </div>

            <div class="d-flex flex-row">
              <span class="flex-grow-1">
                <input type="submit" name="create"
                       class="btn btn-primary save btn-block"
                       value="Speichern"
                       data-cy="save-schueler"
                >
                <input *ngIf="this.schuelerId && this.schuelerId !== '' && this.schuelerId !== null"
                       type="button" (click)="deleteSchueler()" name="delete" class="btn btn-secondary" value="Löschen">
              </span>
              <input type="button" name="_action_temp" value="Zur Schüler:innenliste" class="btn btn-secondary" [routerLink]="['/pages/schueler-table']">
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
