
  <div class="status-display p-1">

    <div class="pb-3">
      <div class="id-info text-break">
        <h4 class="m-2"
            *ngIf="antragTicketId && antragTicketId !== undefined && antragTicketId !== null; else newAntragTicket">
          {{ "STANDARD.TICKET_ANTRAG" | translate }}{{'STANDARD.SNUMMER' | translate}}
          <div class="text-end default-text-size" data-cy="antragticket-id">{{ this.antragTicketId }}</div>
        </h4>
        <ng-template #newAntragTicket>
          <h4 class="m-2">
            {{ "STANDARD.TICKET_ANTRAG" | translate }}
          </h4>
        </ng-template>
      </div>
    </div>

    <h4 class="text-break" *ngIf="this.postkorbReference && this.postkorbReference !== '' && this.postkorbReference !== null">
      {{ "TICKETDETAIL.POSTKORB_REFERENCE" | translate }} {{ postkorbReference }}
    </h4>

    <h4 class="text-break" *ngIf="antragTicket?.status == draftStatus || !antragTicketId">
      {{"TICKETDETAIL.ANTRAGSTATUS" | translate}}
    </h4>
    <div *ngIf="antragTicket?.status == draftStatus || !antragTicketId" class="alert alert-info antrag-status text-break" role="alert">
      <p><fa-icon [icon]="faInfo"></fa-icon>
        {{"TICKETDETAIL.STATUS.ENTWURF" | translate}}
      </p>
      <span *ngIf="!antragTicketId" class="alert warning"> {{('STANDARD.NOT_SAVED'|translate)}} </span>
    </div>
    <div *ngIf="antragTicket?.antragsdatum !== null" class="alert alert-info antrag-status text-break" role="alert">
      <p><i class="fa fa-info-circle"></i>
        {{"TICKETDETAIL.STATUS.EINGEREICHT" | translate}} {{antragTicket?.antragsdatum | date:'dd.MM.yyyy'}}.
      </p>
    </div>
    <div *ngIf="antragTicket?.bestelldatum !== null" class="alert alert-info antrag-status text-break" role="alert">
      <p><i class="fa fa-info-circle"></i>
        {{"TICKETDETAIL.STATUS.BESTELLT" | translate}} {{antragTicket?.bestelldatum | date:'dd.MM.yyyy'}}.
      </p>
    </div>
    <div *ngIf="antragTicket?.ausgabedatum !== null" class="alert alert-info antrag-status text-break" role="alert">
      <p><i class="fa fa-info-circle"></i>
        {{"TICKETDETAIL.STATUS.AUSGEGEBEN" | translate}} {{antragTicket?.ausgabedatum | date:'dd.MM.yyyy'}}.
      </p>
    </div>
  </div>

  <div ngbAccordion>
    <div ngbAccordionItem>
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{ 'STANDARD.ATTACHMENTS' | translate }}  <span class="badge badge-pill badge-dark ms-2 "  >
                                  {{antragTicket?.attachments?.length || '0'}}
                                  </span>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-ticket-antrag-sidebar-attachments
              [antragTicketId]="antragTicketId"
              [deleteable]="antragTicket?.status === 'ENTWURF'"
              [appendeable]="antragTicket?.status === 'ENTWURF'"
            ></app-ticket-antrag-sidebar-attachments>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
