import {Injectable} from "@angular/core";
import {BehaviorSubject, EMPTY, Observable} from "rxjs";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {BenutzerService} from "./benutzer.service";
import {CustomToastService} from "../../utils/custom-toast.service";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmComponent} from "../../../shared/confirm/confirm.component";
import {TranslateService} from "@ngx-translate/core";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BenutzerSessionService {

  constructor(
    private benutzerService: BenutzerService,
    private customToastService: CustomToastService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  // services

  getCurrentBenutzerId(): number {
    return (JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO).id;
  }

  getCurrentBenutzer(): BenutzerDTO {
    return (JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO);
  }

  initBenutzerSession() {
    if(!sessionStorage.getItem('authToken'))
      return;
    this.benutzerService.getMeExists().subscribe( meExists => {
      if(meExists===true){
        this.subscribeToGetMe();
      } else {
        this.showConfirmationModal();
      }
    });
  }

  initBenutzerSessionJwt() {
    if(!sessionStorage.getItem('authToken'))
      return;
    this.subscribeToGetMeJwt();
  }

  subscribeToGetMe(key?: string){
    this.benutzerService.getMe(key)
      .pipe(catchError((err) => {
        if(err.status === 400 && err.error?.confirmations){
          let key = err.error.confirmations[0];
          this.showAdressMissingModal(key);
        }
        console.log(err);
        return EMPTY;
      }))
      .subscribe(benutzer => {
        this.applyBenutzer(benutzer);
    });
  }

  subscribeToGetMeJwt(){
    let smallBenutzer: BenutzerDTO = JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO ;
    this.benutzerService.getBenutzer(smallBenutzer.id.toString())
      .pipe(
        catchError(err => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe((benutzer: BenutzerDTO) => {
        this.applyBenutzer(benutzer);
        this.broadcastTimeToLiveChange();
        this.broadcastTimestampChange();
      });
  }

  applyBenutzer(benutzer: BenutzerDTO){
    if(this.checkIfRoleMatchesMode(benutzer.roles)) {
      sessionStorage.setItem('role', benutzer.roles[0]);
      sessionStorage.setItem('user', JSON.stringify(benutzer));
      this.setAntragstellerName(benutzer);
      this.broadcastBenutzerChange();
    }
  }

  setAntragstellerName(benutzer) {
    if (document.getElementById('antragsteller-name') !== null) {
      document.getElementById('antragsteller-name').innerHTML = benutzer.vorname + ' ' + benutzer.name;
    } else {
      setTimeout(() => {
        this.setAntragstellerName(benutzer);
      }, 100);
    }
  }

  checkIfRoleMatchesMode(roles: string[]){
    let mode = sessionStorage.getItem('mode');
    if(
      (mode == 'at' && !roles.includes('ROLE_ANTRAGSTELLER'))
      ||  (mode == 'admin' && !roles.includes('ROLE_SUPERUSER'))
    ){
      this.customToastService.showError("Sie haben keine Zugriffsberechtigung");
      return false;
    } else {
      return true;
    }
  }

  // exposed observables

  private sessionStorageBenutzer = new BehaviorSubject<BenutzerDTO>(JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO);
  private sessionStorageExistsBenutzer = new BehaviorSubject<boolean>(!!(JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO));
  private sessionStorageTimeToLive = new BehaviorSubject<number>((JSON.parse(sessionStorage.getItem('tokenTimeToLive')) as number));
  private sessionStorageTimestamp = new BehaviorSubject<Date>(new Date(sessionStorage.getItem('timestamp')));

  watchSessionStorageBenutzer () : Observable<BenutzerDTO> {
    return this.sessionStorageBenutzer.asObservable();
  }
  watchSessionStorageExistsBenutzer () : Observable<boolean>{
    return this.sessionStorageExistsBenutzer.asObservable();
  }

  watchSessionStorageTimeToLive () : Observable<number>{
    return this.sessionStorageTimeToLive.asObservable();
  }

  watchSessionTimestamp () : Observable<Date>{
    return this.sessionStorageTimestamp.asObservable();
  }

  broadcastBenutzerChange () {
    this.sessionStorageBenutzer.next(JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO);
    this.sessionStorageExistsBenutzer.next(!!(JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO));
  }

  broadcastTimeToLiveChange(){
    this.sessionStorageTimeToLive.next((JSON.parse(sessionStorage.getItem('tokenTimeToLive')) as number));
  }

  broadcastTimestampChange(){
    this.sessionStorageTimestamp.next(new Date((sessionStorage.getItem('timestamp'))));
  }

  // modal

  showConfirmationModal(){
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };

    const modalRef = this.modalService.open(ConfirmComponent, modalOptions);
    modalRef.componentInstance.setTitle(this.translateService.instant('PORTAL_WECHSEL.TITLE'));
    modalRef.componentInstance.setText(this.translateService.instant('PORTAL_WECHSEL.TEXT'));
    modalRef.componentInstance.onYes = () => {
      this.subscribeToGetMe();
    };
    modalRef.componentInstance.onNo = () => {this.redirectToPortal();};
  }

  showAdressMissingModal(key: string){
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };

    const modalRef = this.modalService.open(ConfirmComponent, modalOptions);
    modalRef.componentInstance.setTitle(this.translateService.instant('CONFIRMATION.TITLE'));
    modalRef.componentInstance.setText(this.translateService.instant('CONFIRMATION.TEXT.' +key));
    modalRef.componentInstance.onYes = () => {
      this.subscribeToGetMe(key);
    };
    modalRef.componentInstance.onNo = () => {this.redirectToPortal();};
  }

  redirectToPortal(){
    window.location.href = sessionStorage.getItem('portalUrl');
  }

}
