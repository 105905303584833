
<app-default-layout
  [title]="'MODAL.ADD_ATTACHMENT_TITLE' | translate"
>

  <app-custom-input id="datei"
                    [customFormGroup]="dateiForm"
                    [label]="'STANDARD.DATEI' | translate"
                    [type]="'file'"
                    [clearable]="true"
                    [acceptFormats]="allowedNachweisFormats"
                    (onchange)="onFileSelected($event)"
                    (onClear)="onFileDeleted()">
  </app-custom-input>

  <app-custom-input id="name"
                    [customFormGroup]="dateiForm"
                    [label]="'ATTACHMENT.NAME' | translate"
                    [type]="'text'"
                    [clearable]="true"
                    [infoText]="'ATTACHMENT.NAME_INFO' | translate"
                    >
  </app-custom-input>

  <app-custom-select id="type"
                     [customFormGroup]="dateiForm"
                     placeholder="-"
                     [items]="attachmentTypes"
                     bindLabel="translation"
                     bindValue="name"
                     label="{{'ATTACHMENT.ATTACHMENTTYPE' |translate }}"
                     [infoText]="'ATTACHMENT.ATTACHMENTTYPE_INFO' |translate"
                     [multiple]="false"
                     [clearable]="false"
                     [readonly]="!!attachmentTypeSelection"
  ></app-custom-select>

  <div class="row pt-0" style="margin-top:-2ch;">
    <div class="frame frame-default mt-0 pt-0">
      <div class="d-flex flex-row-reverse mt-1">
        <button type="button"  (click)="reject()"
                name="reject" class="btn btn-outline-primary"
                style="min-width: 10em"
                data-cy="no">
          {{'APPLICATION.BUTTON_ABBRECHEN'|translate}}
        </button>
        <span style="width: 1em"></span>
        <button type="button" (click)="accept()"
                class="btn btn-primary" style="min-width: 10em"
                data-cy="yes">
          {{'APPLICATION.BUTTON_BESTAETIGEN' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-default-layout>
